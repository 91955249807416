import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { Userdeleted } from '../models/user-deleted.model';
import { Router } from '@angular/router';
import { Condidate } from '../models/condidate.model';
import { Contact } from '../models/contact.model';
import { CondidateService } from '../services/condidate.service';
import { ContactService } from '../services/contact.service';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { ExcelService } from '../services/excel.service';
import { TokenStorageService } from '../services/token-storage.service';
import { CommunService } from '../services/commun';

@Component({
  selector: 'app-collab-board',
  templateUrl: './collab-board.component.html',
  styleUrls: ['./collab-board.component.scss']
})
export class CollabBoardComponent implements OnInit {
  public searchForm: any;
  public loading = false;
  public users: User[] = [];
  public users2: User[] = [];
  public filtredusers: User[] = [];
  public decfiscmenss: Decfiscmens[] = [];
  public deccomptabilites: Deccomptabilite[] = [];
  public usersdeleted: Userdeleted[] = [];
  public condidates: Condidate[] = [];
  public contacts: Contact[] = [];
  private subscriptions: Subscription[] = [];
  public prenom: string = '';
  public nom: string = '';
  public prenomfisc: string = '';
  public nomfisc: string = '';
  public prenomaffecte: string = '';
  public nomaffecte: string = '';
  public errormsg: string = '';
  public dossencours: any[] = [];
  public sorteddossencours: any[] = [];
  public currentuser: User;
  public usertype: string;
  public id: string;

  constructor(
    private userService: UserService,
    private condService: CondidateService,
    private contactService: ContactService,
    private decfiscmensService: DecfiscmensService,
    private communService: CommunService,
    private deccomptabiliteService: DeccomptabiliteService,
    private router: Router,
    private auth: TokenStorageService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    const user = this.auth.getUser();
    this.currentuser = user;
    this.usertype = user.usertype;
    this.id = user.userId;

    this.subscriptions.push(
      this.contactService.contactreqs$.subscribe(
        (contacts) => (this.contacts = contacts),
        (error) => this.handleError(error)
      ),
      this.condService.condidates$.subscribe(
        (condidates) => (this.condidates = condidates),
        (error) => this.handleError(error)
      ),
      this.userService.users$.subscribe(
        (users) => {
          this.users = users;
          this.users2 = users;
        },
        (error) => this.handleError(error)
      ),
      this.decfiscmensService.decfiscmenss$.subscribe(
        (decfiscmenss) => (this.decfiscmenss = decfiscmenss),
        (error) => this.handleError(error)
      ),
      this.deccomptabiliteService.deccomptabilites$.subscribe(
        (deccomptabilites) => (this.deccomptabilites = deccomptabilites),
        (error) => this.handleError(error)
      ),
      this.userService.usersdeleted$.subscribe(
        (usersdeleted) => (this.usersdeleted = usersdeleted),
        (error) => this.handleError(error)
      )
    );

    this.fetchAllData();
  }

  private fetchAllData() {
    this.userService.getAll();
    this.decfiscmensService.getdecfiscmenss();
    this.deccomptabiliteService.getdeccomptabilites();
    this.condService.getCondidates();
    this.contactService.getContactreqs();
  }

  private handleError(error: any) {
    this.loading = false;
    this.errormsg = error.message;
  }

  debutcompteurdecfiscale(link: string, id: string) {
    this.decfiscmensService.getDecfiscmensreqById(id).then((decfiscmens: Decfiscmens) => {
      this.decfiscmenss.push(decfiscmens);
      this.communService.getcurrenttime().then((data: any) => {
        const lastStatus = decfiscmens.statutcollab?.slice(-1)[0];
        //@ts-ignore
        if (!lastStatus || lastStatus.statutcoll !== 'en cours de traitement') {
          //@ts-ignore
          decfiscmens.statutcollab.push({ statutcoll: 'en cours de traitement', motifcoll: '', datefin: data });
          this.decfiscmensService.modifydecfiscmensreqById(id, decfiscmens).then(
            () => this.router.navigate([`${link}/${id}`]),
            (error) => this.handleError(error)
          );
        } else {
          this.router.navigate([`${link}/${id}`]);
        }
      });
    });
  }

  debutcompteurcomptabilite(link: string, id: string) {
    this.deccomptabiliteService.getDeccomptabilitereqById(id).then((deccomptabilite: Deccomptabilite) => {
      this.deccomptabilites.push(deccomptabilite);
      this.decfiscmensService.geexistenttdecfiscmens(
        deccomptabilite.userId,
        deccomptabilite.annee,
        deccomptabilite.mois,
        deccomptabilite.mois
      ).then((decfiscmens: Decfiscmens[]) => {
        this.updateDecComptabiliteStatus(deccomptabilite, decfiscmens, link, id);
      });
    });
  }

  private updateDecComptabiliteStatus(
    deccomptabilite: Deccomptabilite,
    decfiscmens: Decfiscmens[],
    link: string,
    id: string
  ) {
    this.communService.getcurrenttime().then((data: any) => {
      const lastStatus = deccomptabilite.statutcollab?.slice(-1)[0];
      //@ts-ignore
      if (!lastStatus || lastStatus.statutcoll !== 'en cours de traitement') {
        //@ts-ignore
        deccomptabilite.statutcollab.push({ statutcoll: 'en cours de traitement', motifcoll: '', datefin: data });
        this.deccomptabiliteService.completedeccomptabilitereqById(id, deccomptabilite).then(
          () => this.router.navigate([`${link}/${id}`]),
          (error) => this.handleError(error)
        );
        if (decfiscmens.length > 0) {
          //@ts-ignore
          decfiscmens[0].statutcollab.push({ statutcoll: 'en cours de traitement', motifcoll: '', datefin: data });
          this.decfiscmensService.modifydecfiscmensreqById(decfiscmens[0]._id, decfiscmens[0]);
        }
      } else {
        this.router.navigate([`${link}/${id}`]);
      }
    });
  }

  getdossiersencours() {
    this.fetchAllData();
    this.dossencours = [];

    const processItems = (items: any[]) => {
      items.forEach((item) => {
        const lastStatutAdmin = item.statutadmin?.slice(-1)[0];
        const lastStatutCollab = item.statutcollab?.slice(-1)[0];

        if (
          lastStatutAdmin?.statut === 'affecté' &&
          lastStatutCollab?.statutcoll !== 'traité' &&
          item.affecte === this.id
        ) {
          this.dossencours.push(item);
        }
      });
    };

    processItems(this.deccomptabilites);
    processItems(this.condidates);
    processItems(this.contacts);

    this.sorteddossencours = [...this.dossencours].sort((a, b) => a.created.localeCompare(b.created));
  }

  getNavigation(link: string, id: string) {
    this.router.navigate([`${link}/${id}`]);
  }

  filterUsers(id: string, isFisc: boolean = false) {
    const filtered = this.users.find((user) => user._id === id);
    const name = filtered || { firstname: 'utilisateur supprimé', lastname: 'utilisateur supprimé' };
    if (isFisc) {
      this.prenomfisc = name.firstname;
      this.nomfisc = name.lastname;
    } else {
      this.prenom = name.firstname;
      this.nom = name.lastname;
    }
  }

  exportUsersAsXLSX() {
    this.excelService.exportAsExcelFile(this.users, [], [], [], [], [], [], 'sample');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
