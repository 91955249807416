import { Component, OnInit} from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
import { devismodel } from '../models/facture.model';
import { ActivatedRoute } from '@angular/router';
import { FactureproformaService } from '../services/factureproforma.service';
import { DevisService } from '../services/devis.service';
import { FactureService } from '../services/facture.devis';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {
  invoice: devismodel;
  displayedColumns: string[] = ['id','name', 'description', 'quantity', 'price'];
 // taxRate: number = 0.20; // Example tax rate of 20%
type:string
//tva=0.19
userId:string
id:string
  subtotal: number=0;
  total: number=0;
  currentDate: Date = new Date();

  constructor(private invoiceService: InvoiceService,private route: ActivatedRoute,private factproserv: FactureproformaService
    ,private devserv: DevisService,private factserv: FactureService) {}
    generatePDF() {
      const data = document.getElementById('invoicepdf')!;
    
      // Verify if the element exists
      if (!data) {
        console.error('Element with id "invoicepdf" not found!');
        return;
      }
    
      // Temporarily hide buttons using a CSS class
      data.classList.add('hide-buttons');
    
      // Generate PDF
      html2canvas(data, {
        allowTaint: true,
        useCORS: true,
        scale: 2, // Reduced scale for smaller file size
      })
        .then((canvas) => {
          // Remove the CSS class after generating the canvas
          data.classList.remove('hide-buttons');
    
          // Initialize jsPDF for A4 format
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const pageMargin = 10; // Margin in mm
          const usableWidth = imgWidth - pageMargin * 2; // Adjusted width for margins
          const usableHeight = (canvas.height * usableWidth) / canvas.width;
    
          // Convert canvas to a compressed JPEG image
          const imgData = canvas.toDataURL('image/jpeg', 0.8); // Use JPEG format with 80% quality
          let heightLeft = imgHeight;
          let position = pageMargin; // Start position from the margin
    
          // Add image content to PDF
          while (heightLeft > 0) {
            pdf.addImage(imgData, 'JPEG', pageMargin, position, usableWidth, usableHeight);
    
            // Reduce remaining height
            heightLeft -= pageHeight;
    
            if (heightLeft > 0) {
              pdf.addPage();
              position = 0; // Reset position for new page
            }
          }
    
          // Ensure text font size is readable
          pdf.setFont('Helvetica', 'normal');
          pdf.setFontSize(20); // Adjust font size if needed
    
          // Save PDF with a dynamic name based on invoice type and number
          const invoiceType = this.type || 'invoice';
          const invoiceNumber = this.invoice?.number || 'unknown';
          const fileName = `${invoiceType}_${invoiceNumber}.pdf`;
    
          pdf.save(fileName);
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
    
          // Remove the CSS class in case of an error
          data.classList.remove('hide-buttons');
        });
    }
    
    
    
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type');
      this.userId = params.get('user');
      this.id = params.get('id');

    }
    )
    this.fetchInvoiceDetails();
  }

  fetchInvoiceDetails(): void {
    if(this.type=='facturepro')
    {
      this.factproserv.getFactureproformadataById(this.id).then((data :devismodel)=> {
        this.invoice = data;
        this.calculateTotals();
      });
    }
    else  if(this.type=='facture')
    {
      this.factserv.getFacturedataById(this.id).then((data :devismodel)=> {
        this.invoice = data;
        this.calculateTotals();
      });    }
   else if(this.type=='devis')
    {
      this.devserv.getDevisdataById(this.id).then((data :devismodel)=> {
        this.invoice = data;
        this.calculateTotals();
      });
        }

  }

  calculateTotals(): void {
    let subtotal = 0;
    for (const item of this.invoice.itemsdetails) {
      subtotal += +item.quantity * +item.unitPrice;
    }
    const tva = subtotal * this.invoice.tva;
    const total =  Math.trunc(subtotal + tva+1);

    this.subtotal = subtotal;
    this.total = total;
  }
}
