<div class="container-fluid mt-3" >
  <div class="d-flex align-items-start mb-4 mt-4" id="invoice">
    <div style="flex-direction: column;">
      <div class="document-header mt-3 ml-4">
        <h1 class="text-uppercase fw-bold title-size">
          {{ documentType | titlecase }}
        </h1>
      </div>
      <div class="invoice-logo">
        <input type="file" (change)="onFileSelected($event)" accept="application/pdf,image/png,image/jpeg" />
        <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
        <div *ngIf="pdfSrc">
          <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" style="display: block; width: 200px; height: auto;"></pdf-viewer>
        </div>
        <div *ngIf="imageSrc">
          <img [src]="imageSrc" alt="Selected Image" style="display: block; width: 200px; height: auto;" />
        </div>
      </div>
      
    </div>
    <div class="flex-grow-1 ml-4">
      <form [formGroup]="invoiceForm" (ngSubmit)="onSubmit()" class="row invoice-form" novalidate>
        <!-- Invoice Number -->
        <div class="col-md-4">
          <label for="number" class="form-label">{{ documentType | titlecase }} N°:{{newinvoiceprefixe}}/{{newinvoiceref}} ({{newinvoicenumber}})</label>
        </div>
      
        <!-- User ID -->
        <div class="col-md-4">
          <label for="userId" class="form-label">{{ "USER_ID" | translate }}</label>
          <div appUserDisplay="{{userId}}" [displayProperties]="['firstname','raisonsociale']"></div>      
        </div>
      
        <!-- Date -->
        <div class="col-md-4">
          <label for="validityDate" class="form-label">{{ "DATE" | translate }}</label>
          <input type="datetime-local" formControlName="validityDate" class="form-control" id="validityDate" required [ngClass]="{'is-invalid': invoiceForm.get('validityDate').invalid && (invoiceForm.get('validityDate').dirty || invoiceForm.get('validityDate').touched)}"/>
          <div *ngIf="invoiceForm.get('validityDate').invalid && (invoiceForm.get('validityDate').dirty || invoiceForm.get('validityDate').touched)" class="invalid-feedback">
            {{ "DATE_REQUIRED" | translate }}
          </div>
        </div>
      
        <!-- Vendeur (Seller) Section -->
        <div class="col-12 mt-4">
          <h2 class="text-danger fs-5">{{ "SELLER_INFO" | translate }}</h2>
          <div formArrayName="fournisseurdetails">
            <div *ngFor="let client of fournisseurdetails.controls; let i = index" [formGroupName]="i" class="row">
              <div class="col-md-4">
                <label for="name" class="form-label">{{ "SELLER_NAME" | translate }}</label>
                <input type="text" formControlName="name" class="form-control" id="name" required [ngClass]="{'is-invalid': client.get('name').invalid && (client.get('name').dirty || client.get('name').touched)}"/>
                <div *ngIf="client.get('name').invalid && (client.get('name').dirty || client.get('name').touched)" class="invalid-feedback">
                  {{ "SELLER_NAME_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4">
                <label for="address" class="form-label">{{ "SELLER_ADDRESS" | translate }}</label>
                <input type="text" formControlName="address" class="form-control" id="address" required [ngClass]="{'is-invalid': client.get('address').invalid && (client.get('address').dirty || client.get('address').touched)}"/>
                <div *ngIf="client.get('address').invalid && (client.get('address').dirty || client.get('address').touched)" class="invalid-feedback">
                  {{ "SELLER_ADDRESS_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4">
                <label for="phone" class="form-label">{{ "SELLER_PHONE" | translate }}</label>
                <input type="text" formControlName="phone" class="form-control" id="phone" required [ngClass]="{'is-invalid': client.get('phone').invalid && (client.get('phone').dirty || client.get('phone').touched)}"/>
                <div *ngIf="client.get('phone').invalid && (client.get('phone').dirty || client.get('phone').touched)" class="invalid-feedback">
                  {{ "SELLER_PHONE_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4 mt-3">
                <label for="email" class="form-label">{{ "SELLER_EMAIL" | translate }}</label>
                <input type="email" formControlName="email" class="form-control" id="email" required [ngClass]="{'is-invalid': client.get('email').invalid && (client.get('email').dirty || client.get('email').touched)}"/>
                <div *ngIf="client.get('email').invalid && (client.get('email').dirty || client.get('email').touched)" class="invalid-feedback">
                  {{ "SELLER_EMAIL_REQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <!-- Client Section -->
        <div class="col-12 mt-4">
          <h2 class="text-danger fs-5">{{ "CLIENT_INFO" | translate }}</h2>
          <div formArrayName="clientdetails">
            <div *ngFor="let client of clientdetails.controls; let i = index" [formGroupName]="i" class="row">
              <div class="col-md-4">
                <label for="name" class="form-label">{{ "CLIENT_NAME" | translate }}</label>
                <input type="text" formControlName="name" class="form-control" id="name" required [ngClass]="{'is-invalid': client.get('name').invalid && (client.get('name').dirty || client.get('name').touched)}"/>
                <div *ngIf="client.get('name').invalid && (client.get('name').dirty || client.get('name').touched)" class="invalid-feedback">
                  {{ "CLIENT_NAME_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4">
                <label for="address" class="form-label">{{ "CLIENT_ADDRESS" | translate }}</label>
                <input type="text" formControlName="address" class="form-control" id="address" required [ngClass]="{'is-invalid': client.get('address').invalid && (client.get('address').dirty || client.get('address').touched)}"/>
                <div *ngIf="client.get('address').invalid && (client.get('address').dirty || client.get('address').touched)" class="invalid-feedback">
                  {{ "CLIENT_ADDRESS_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4">
                <label for="phone" class="form-label">{{ "CLIENT_PHONE" | translate }}</label>
                <input type="text" formControlName="phone" class="form-control" id="phone" required [ngClass]="{'is-invalid': client.get('phone').invalid && (client.get('phone').dirty || client.get('phone').touched)}"/>
                <div *ngIf="client.get('phone').invalid && (client.get('phone').dirty || client.get('phone').touched)" class="invalid-feedback">
                  {{ "CLIENT_PHONE_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4 mt-3">
                <label for="email" class="form-label">{{ "CLIENT_EMAIL" | translate }}</label>
                <input type="email" formControlName="email" class="form-control" id="email" required [ngClass]="{'is-invalid': client.get('email').invalid && (client.get('email').dirty || client.get('email').touched)}"/>
                <div *ngIf="client.get('email').invalid && (client.get('email').dirty || client.get('email').touched)" class="invalid-feedback">
                  {{ "CLIENT_EMAIL_REQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <!-- Item Details -->
        <div class="col-12 mt-4">
          <h2 class="text-danger fs-5">{{ "ITEM_DETAILS" | translate }}</h2>
          <div formArrayName="itemsdetails">
            <div *ngFor="let item of itemsdetails.controls; let i = index" [formGroupName]="i" class="row">
              <div class="col-md-2">
                <label for="id" class="form-label">{{ "ITEM_NUMBER" | translate }}</label>
                <input type="text" formControlName="id" class="form-control" id="id" required [ngClass]="{'is-invalid': item.get('id').invalid && (item.get('id').dirty || item.get('id').touched)}"/>
                <div *ngIf="item.get('id').invalid && (item.get('id').dirty || item.get('id').touched)" class="invalid-feedback">
                  {{ "ITEM_ID_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-2">
                <label for="libelle" class="form-label">{{ "ITEM_NAME" | translate }}</label>
                <input type="text" formControlName="libelle" class="form-control" id="libelle" required [ngClass]="{'is-invalid': item.get('libelle').invalid && (item.get('libelle').dirty || item.get('libelle').touched)}"/>
                <div *ngIf="item.get('libelle').invalid && (item.get('libelle').dirty || item.get('libelle').touched)" class="invalid-feedback">
                  {{ "ITEM_LIBELLE_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-4">
                <label for="description" class="form-label">{{ "ITEM_DESCRIPTION" | translate }}</label>
                <input type="text" formControlName="description" class="form-control" id="description" required [ngClass]="{'is-invalid': item.get('description').invalid && (item.get('description').dirty || item.get('description').touched)}"/>
                <div *ngIf="item.get('description').invalid && (item.get('description').dirty || item.get('description').touched)" class="invalid-feedback">
                  {{ "ITEM_DESCRIPTION_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-2">
                <label for="quantity" class="form-label">{{ "ITEM_QUANTITY" | translate }}</label>
                <input type="text" appDecimalFormat formControlName="quantity" class="form-control" id="quantity" (input)="updateTotal()"required [ngClass]="{'is-invalid': item.get('quantity').invalid && (item.get('quantity').dirty || item.get('quantity').touched)}"/>
                <div *ngIf="item.get('quantity').invalid && (item.get('quantity').dirty || item.get('quantity').touched)" class="invalid-feedback">
                  {{ "ITEM_QUANTITY_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-2">
                <label for="unitPrice" class="form-label">{{ "ITEM_UNIT_PRICE" | translate }}</label>
                <input type="text" appDecimalFormat formControlName="unitPrice" class="form-control" id="unitPrice" (input)="updateTotal()"required [ngClass]="{'is-invalid': item.get('unitPrice').invalid && (item.get('unitPrice').dirty || item.get('unitPrice').touched)}"/>
                <div *ngIf="item.get('unitPrice').invalid && (item.get('unitPrice').dirty || item.get('unitPrice').touched)" class="invalid-feedback">
                  {{ "ITEM_UNIT_PRICE_REQUIRED" | translate }}
                </div>
              </div>
      
              <div class="col-md-1 d-flex align-items-end mb-3">
                <button type="button" class="btn btn-danger" (click)="removeItem(i)">
                  {{ "REMOVE_ITEM" | translate }}
                </button>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary" (click)="addItem()">{{ "ADD_ITEM" | translate }}</button>
        </div>
      
        <!-- Other Details -->
        <div class="col-md-4 mt-3">
          <label for="status" class="form-label">{{ "STATUS" | translate }}</label>
          <input type="text" formControlName="status" class="form-control" id="status" required [ngClass]="{'is-invalid': invoiceForm.get('status').invalid && (invoiceForm.get('status').dirty || invoiceForm.get('status').touched)}"/>
          <div *ngIf="invoiceForm.get('status').invalid && (invoiceForm.get('status').dirty || invoiceForm.get('status').touched)" class="invalid-feedback">
            {{ "STATUS_REQUIRED" | translate }}
          </div>
        </div>
      
        <div class="col-md-4 mt-3">
          <label for="currency" class="form-label">{{ "CURRENCY" | translate }}</label>
          <input type="text" formControlName="currency" class="form-control" id="currency" required [ngClass]="{'is-invalid': invoiceForm.get('currency').invalid && (invoiceForm.get('currency').dirty || invoiceForm.get('currency').touched)}"/>
          <div *ngIf="invoiceForm.get('currency').invalid && (invoiceForm.get('currency').dirty || invoiceForm.get('currency').touched)" class="invalid-feedback">
            {{ "CURRENCY_REQUIRED" | translate }}
          </div>
        </div>
      
        <div class="col-md-4 mt-3">
          <label for="terms" class="form-label">{{ "TERMS" | translate }}</label>
          <input type="text" formControlName="terms" class="form-control" id="terms"/>
        </div>
      
        <div class="col-md-4 mt-3">
          <label for="notes" class="form-label">{{ "NOTES" | translate }}</label>
          <input type="text" formControlName="notes" class="form-control" id="notes"/>
        </div>
        <!-- Totals -->
  <div class="col-12 mt-4">
    <h2 class="text-danger fs-5">{{ "TOTAL" | translate }}</h2>
    <div class="row">
      <div class="col-md-4">
        <label class="form-label">{{ "TOTAL_HT" | translate }}</label>
        <input type="text" class="form-control" [value]="totalHT | number:'1.3-3'" readonly />
      </div>
      <div class="col-md-4">
        <label class="form-label">{{ "TVA" | translate }} ( {{ tva * 100 }} %)</label>
        <input type="text" class="form-control" [value]="tva2 | number:'1.3-3'" readonly />
      </div>
      <div class="col-md-4">
        <label class="form-label">{{ "Droit de timbre" | translate }}</label>
        <input type="text" class="form-control" [value]="1 | number:'1.3-3'" readonly />
      </div>
      <div class="col-md-4">
        <label class="form-label">{{ "TOTAL_TTC" | translate }}</label>
        <input type="text" class="form-control" [value]="totalTTC | number:'1.3-3'" readonly />
      </div>
    </div>
  </div>
        <div class="col-12 mt-4">
          <button type="submit" class="btn btn-success">{{ "SUBMIT" | translate }}</button>
        </div>
      </form>
      
      <div class="row mt-4 mb-4">
        <div class="col-12">
          <h3 class="section-title text-primary">Signature:</h3>
          <div class="d-flex justify-content-between">
            <div>
              <label for="clientSignature" class="form-label"
                >Signature du client:</label
              >
              <div class="signature-box" id="clientSignature">
                <!-- Espace pour la signature -->
              </div>
              <div class="signature-date">
                Valide jusqu'au: {{ invoiceForm.get('validityDate').value | date : "dd/MM/yyyy" }}
              </div>
            </div>
            <div>
              <label for="vendorSignature" class="form-label"
                >Signature du vendeur:</label
              >
              <div class="signature-box" id="vendorSignature">
                <!-- Espace pour la signature -->
              </div>
              <div class="signature-date">
                Valide jusqu'au: {{ invoiceForm.get('validityDate').value | date : "dd/MM/yyyy" }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-2 text-start">
      <div class="col-md-5">
        <label for="inputState" class="form-label">Language</label>
        <select
          id="inputState"
          class="form-select"
          (change)="switchLanguage($event.target.value)"
        >
          <option value="fr" selected>Français</option>
          <option value="ar">العربية</option>
        </select>
      </div>

      <div class="col-md-4 mt-3">
        <label for="currency" class="form-label">{{
          "CURRENCY" | translate
        }}</label>
        <select
          id="currency"
          class="form-select"
          [(ngModel)]="currency"
          name="currency"
          (change)="switchcurrency()"
        >
          <option value="DT">Tunisian Dinar (DT)</option>
          <option value="USD">US Dollar (USD)</option>
          <option value="EUR">Euro (EUR)</option>
        </select>
      </div>
      <div class="col-md-4 mt-3">
        <label for="tva" class="form-label">TVA</label>
        <input
          id="tva"
          class="form-control"
          type="number"
          [(ngModel)]="tva"
          name="tva"
          step="0.01"
          (input)="updateTotal()"
        />
      </div>
    </div>
  </div>
</div>
