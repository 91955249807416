<div class="collab-board">
  <h1>Tableau De Board Collaborateur</h1>
  <div class="line"></div>
  <div class="buttons">
    <button class="btn charger-dossier" (click)="getdossiersencours()" [disabled]="loading" style="cursor: pointer;">
      <i class="fa fa-spinner" *ngIf="loading"></i> Charger Dossier
    </button>
    <button class="btn update-cours">
      <a routerLink="settings" routerLinkActive="active">Mise à jour du cours de change</a>
    </button>
  </div>

  <!-- Display dossier table only if usertype is Collaborateur -->
  <div *ngIf="usertype === 'Collaborateur'" class="table-section">
    <h2>Liste des Ordres de Services</h2>
    <table>
      <thead>
        <tr>
          <th scope="col">Prénom</th>
          <th scope="col">Nom</th>
          <th scope="col">Mois</th>
          <th scope="col">Année</th>
          <th scope="col">Type</th>
          <th scope="col">Origine</th>
          <th scope="col">Statut Admin</th>
          <th scope="col">Statut Collaborateur</th>
          <th scope="col">Date de Création</th>
          <th scope="col">Date de Modification</th>
          <th scope="col">Consultation</th>
        </tr>
      </thead>
      <tbody *ngIf="currentuser?.rolesuperviseur === 'Autorisé'">
        <!-- Loop through sorted dossiers for supervisors -->
        <tr *ngFor="let dossier of sorteddossencours">
          <td>
            <span *ngIf="dossier?.nature === 'Déclaration Mensuelle'">
              {{ prenomfisc || 'Utilisateur supprimé' }}
            </span>
            <span *ngIf="dossier?.nature === 'comptabilite' || dossier?.nature === 'déclaration comptable'">
              {{ prenom || 'Utilisateur supprimé' }}
            </span>
            <span *ngIf="!dossier?.nature">{{ dossier?.firstname || 'N/A' }}</span>
          </td>
          <td>
            <span *ngIf="dossier?.nature === 'Déclaration Mensuelle'">
              {{ nomfisc || 'Utilisateur supprimé' }}
            </span>
            <span *ngIf="dossier?.nature === 'comptabilite' || dossier?.nature === 'déclaration comptable'">
              {{ nom || 'Utilisateur supprimé' }}
            </span>
            <span *ngIf="!dossier?.nature">{{ dossier?.lastname || 'N/A' }}</span>
          </td>
          <td>{{ dossier?.mois || 'N/A' }}</td>
          <td>{{ dossier?.annee || 'N/A' }}</td>
          <td>
            <span *ngIf="dossier?.nature">{{ dossier?.nature }}</span>
            <span *ngIf="dossier?.type">{{ dossier?.type }}</span>
          </td>
          <td>{{ dossier?.origine || 'N/A' }}</td>
          <td>
            <ng-container *ngIf="dossier?.statutadmin">
              <span *ngFor="let statut of dossier?.statutadmin">
                {{ statut?.statut || 'N/A' }}
              </span>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="dossier?.statutcollab">
              <span *ngFor="let statut of dossier?.statutcollab">
                {{ statut?.statutcoll || 'N/A' }}
              </span>
            </ng-container>
          </td>
          <td>{{ dossier?.created | customDate  }}</td>
          <td>{{ dossier?.updated | customDate  }}</td>
          <td>
            <!-- Buttons for consultation based on dossier type -->
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.nature === 'Déclaration Mensuelle' && dossier?.origine !== 'généré automatiquement'"
              (click)="debutcompteurdecfiscale('view-decfiscmens', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.nature === 'comptabilite' || dossier?.nature === 'déclaration comptable'"
              (click)="debutcompteurcomptabilite('view-deccomptabilite', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.type === 'candidature'"
              (click)="getNavigation('view-condidate', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.type === 'réclamation'"
              (click)="getNavigation('view-contactreq', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.nature === 'Déclaration Mensuelle'"
              (click)="getadmincollabview('modify-decfiscmens', dossier?._id)"
              target="_blank"
            >
              Consulter Maquette
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="currentuser?.rolesuperviseur !== 'Autorisé'">
        <!-- Logic for non-supervisor users -->
        <tr *ngFor="let dossier of sorteddossencours">
          <td>{{ dossier?.firstname || 'N/A' }}</td>
          <td>{{ dossier?.lastname || 'N/A' }}</td>
          <td>{{ dossier?.mois || 'N/A' }}</td>
          <td>{{ dossier?.annee || 'N/A' }}</td>
          <td>{{ dossier?.nature || dossier?.type || 'N/A' }}</td>
          <td>{{ dossier?.origine || 'N/A' }}</td>
          <td>
            <span *ngFor="let statut of dossier?.statutadmin">
              {{ statut?.statut || 'N/A' }}
            </span>
          </td>
          <td>
            <span *ngFor="let statut of dossier?.statutcollab">
              {{ statut?.statutcoll || 'N/A' }}
            </span>
          </td>
          <td>{{ dossier?.created | customDate  }}</td>
          <td>{{ dossier?.updated | customDate  }}</td>
          <td>
            <!-- Consultation buttons for non-supervisors -->
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.nature === 'Déclaration Mensuelle' && dossier?.origine !== 'généré automatiquement'"
              (click)="debutcompteurdecfiscale('view-decfiscmens', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.nature === 'comptabilite' || dossier?.nature === 'déclaration comptable'"
              (click)="debutcompteurcomptabilite('view-deccomptabilite', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.type === 'candidature'"
              (click)="getNavigation('view-condidate', dossier?._id)"
            >
              Consulter
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="dossier?.type === 'réclamation'"
              (click)="getNavigation('view-contactreq', dossier?._id)"
            >
              Consulter
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
