import { Component,Output, EventEmitter,OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { UserService } from '../../services/user.service';
import { DecfiscmensService } from '../../services/dec-fisc-mens';
import { User } from '../../models/user.model';
import { Decfiscmens } from '../../models/dec-fisc-mens';
import Swal from 'sweetalert2';
import { FormStateService } from '../../services/form-store.service';
@Component({
  selector: 'app-page-garde',
  templateUrl: './page-garde.component.html',
  styleUrls: ['./page-garde.component.scss']
})
export class PageGardeComponent implements OnInit,OnDestroy {
  isLoggedIn=false
  loading=false;
  errormsg:string;
  natureactivite:string;
  activite:string;
  sousactivite:string;
  regimefiscalimpot:string;
  regimefiscaltva:string;
  matriculefiscale:string;
  currentUser: any;
  user:User;
  decfiscmens:Decfiscmens;
  decfiscmens1:Decfiscmens[];
  chosenmois:any
  message: string;
  public annees: any[]=["2021","2022","2023","2024","2025"];
  filtredannees=[]
  autretva: Array<string> = ['location à usage d\'habitation meublé', 'location à usage commercial', 'location à usage industriel', 'location à usage professionnel',
'location à usage artisanal','opérations de lotissement','intérêts perçus'];
  retenues: Array<string> = ['traitements et salaires','loyers, commissions, courtages et vacations',  'honoraires', 'montants supérieurs à 1000 dt', 'Autre'];
  choices: Array<string> = ['servis aux personnes non résidentes',  'servis aux personnes résidentes'];
  selected = "----"
  specialite: string;
  currentdate=new Date()
  date1=new Date('04.01.2023')
  periodicites=['Annuelle','Trimestrielle','Bimensuelle','Semestrielle','Mensuelle'];
  settedperiodicites: string[]=['Annuelle','Trimestrielle','Bimensuelle','Semestrielle','Mensuelle'];
  prepminimumperceptionammount=0;
  gardeform: FormGroup;
  filtredcops=[];
  displaycop="none";
  ajoutdccopform: FormGroup;
  tauxdt: number;
  option0Value: any;
  constructor(private token: TokenStorageService,private router: Router,private route: ActivatedRoute,
    private usersservice: UserService,private DecfiscmensService :DecfiscmensService,private fb: FormBuilder,private formStateService: FormStateService
    )
    {
      this.ajoutdccopform = this.fb.group({
        ammounts10: this.fb.array([ this.createammount10() ])
      });
    }
    get ammountControls10() {
      return this.ajoutdccopform.get('ammounts10')['controls'];
    }
    ajouterdccop()
    {
  
    let ammounts10=this.ajoutdccopform.get('ammounts10') as FormArray
    let modifiedcops= ammounts10.value.filter(e=>e.montantecheance)
    this.user.coproprietaires.forEach(
      element=>{
        modifiedcops.forEach(
          element2=>{
            if (element2.numero==element.numero)
            {
              element.donneescomptable?
              element.donneescomptable.push(
                {
                  periodicite:element2.periodicite,
                  montant:element2.montantecheance,
                  annee:element2.annee
                }
              )
              :
              (
                element.donneescomptable=[],
                element.donneescomptable.push(
                  {
                    periodicite:element2.periodicite,
                    montant:element2.montantecheance,
                    annee:element2.annee
                  }
                )
              )
            }
           
          }
        )
      }
    )
    const newuser= new User
    newuser.coproprietaires=this.user.coproprietaires
    if(this.option0Value==''||!this.option0Value)
    {
      return (
        Swal.fire({
        title: 'veuillez indiquer la périodicité',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      }).then((result) => {this.loading=false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }))
    }
    this.usersservice.modifyUserById(this.user._id, newuser).then(  
      () => {
        this.loading = false;
        Swal.fire("copropriétaires mis à jour avec succès")
        this.token.saved=true
        this.reloadPage()
      },
      (error) => {
        this.loading = false
        
      }
    );
    }
    createammount10() 
    : FormGroup {
      return  this.fb.group({
      numero:'',
      coproprietaire:'',
      annee:this.gardeform?this.gardeform.get('annee').value:'',
      periodicite: '',
      autreperiodicite: '',
      montantecheance: '',
      });
    }
    onChangecop(u:number)
    {
      let ammounts10= this.ajoutdccopform.get('ammounts10') as FormArray
      if(ammounts10.value.at(u).periodicite=='Autre')
          {
            this.periodicites.push(ammounts10.value.at(u).autreperiodicite
            )
  
            this.settedperiodicites= this.periodicites.filter((obj, index) => {
              return index === this.periodicites.findIndex(o => obj === o);
            });
            this.settedperiodicites=this.settedperiodicites.sort()
            ammounts10.at(u).patchValue(
              {
                periodicite:ammounts10.value.at(u).autreperiodicite
          
              }
            )
          }
    }
    async patch()
    {
      let ammounts10= this.ajoutdccopform.get('ammounts10') as FormArray
       for (let j = 0; j < ammounts10.length; j++)
        {
          ammounts10.at(j).patchValue({
            periodicite:this.option0Value
           })
        }
        const { value: numero } = await Swal.fire({
          title: 'est ce que le montant exigible par échéance est le même pour tous les copropriétaires?',
          input: 'text',
          inputLabel: 'Montant exigible par échéance',
          inputValue: '',
          showCancelButton: true,
          
        })
        
        if (numero) 
        {
          Swal.fire(`le montant exigible par échéance est  ${numero}`)
       let montant= parseFloat(numero).toFixed(3)
          for (let j = 0; j < ammounts10.length; j++)
          {
            ammounts10.at(j).patchValue({
              montantecheance:montant
             })
          }
        }
    }
  ngOnDestroy(): void {
      // Save the form state when the component is destroyed
      this.formStateService.setGardeFormState(this.gardeform);
  }
  // Déclaration des événements
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }
  previous() {
    this.previousStep.emit();
  }
  reloadPage(): void {
  
    window.location.reload();
    
  }
  sort(array)
  {
array.sort()
  }
  closecopPopup()
  {
    this.displaycop ="none";
    this.token.saved=true
this.reloadPage()
  }
  private initForm(): void {       
    this.gardeform = this.fb.group({
      dectype:'',
      annee:'',
      mois:'',
      RS: false,
      TFP: false,
      FOPROLOS: false,
      DC: false,
      TVA: false,
      autresTaxesSurCA: false,
      taxesSurLesAssurances: false,
      DT: false,
      TCL: false,
      TH: false,
      DL: false
    });
  }
  ngOnInit() {
    this.isLoggedIn = !!this.token.getToken();
        if (this.isLoggedIn) {
      this.currentUser = this.token.getUser();      
    }
    else return (
      this.token.saved=true,
      this.router.navigate(['login']));  
     // Initialize forms
     const savedFormState = this.formStateService.getGardeFormState();
     if (savedFormState) {
       this.gardeform = savedFormState;
           this.usersservice.getUserById(this.currentUser.userId).then(
            (user: User) => {
              this.loading = false;
              this.user = user;
               user.role=='basic'? this.annees.forEach(
                element => {
                  user.exercicecloture.find(e=>e.annee==`${element}`&&e.cloture==false)||!user.exercicecloture.find(e=>e.annee==`${element}`)?this.filtredannees.push(element):''
                }
              ):''
         if (user.regimefiscalimpot==='Réel')  
         {
          this.prepminimumperceptionammount=10.000
         }  
         else if (user.regimefiscalimpot==='Forfait D\'assiette') 
         {
          this.prepminimumperceptionammount=5.000
         }
    if(user.activite!='Syndic des copropriétaires')
    {
      if (!user.natureactivite || user.natureactivite=='Autre/null' || !user.activite || user.activite=='Autre/null'
      || user.regimefiscalimpot=='Autre/null'|| !user.dateeffet|| !user.ficheUrl
      || !user.regimefiscalimpot&&user.nature=='Personne Physique' || user.matriculefiscale.length<17) 
      return (console.log(this.token.saved=true,this.token.saved),this.router.navigate(['complete-profil/'+user._id]))
    }
    if(user.activite=='Syndic des copropriétaires')
    {
      user.coproprietaires?
  user.coproprietaires.length==0?
  (this.token.saved=true,Swal.fire('veuillez ajouter au moins un copropriétaire') ,this.router.navigate(['complete-profil/'+user._id]))
  :''
  :(this.token.saved=true,Swal.fire('veuillez ajouter au moins un copropriétaire'),this.router.navigate(['complete-profil/'+user._id]))

      if(user.matfiscale)
      {
        if (!user.coproprietaires||!user.natureactivite || user.natureactivite=='Autre/null' || user.regimefiscalimpot=='Autre/null' || !user.dateeffet
        || !user.regimefiscalimpot&&user.nature=='Personne Physique' 
        || !user.ficheUrl||user.matriculefiscale.length<17) return (this.token.saved=true,this.router.navigate(['complete-profil/'+user._id]))
      
      }
      else{
        if (!user.coproprietaires||!user.natureactivite || user.natureactivite=='Autre/null' || user.regimefiscalimpot=='Autre/null' || !user.dateeffet
        || !user.regimefiscalimpot&&user.nature=='Personne Physique' ) return (this.token.saved=true,this.router.navigate(['complete-profil/'+user._id]))
      }
    }
            }
          )
     } else {
       this.initForm();
           this.usersservice.getUserById(this.currentUser.userId).then(
            (user: User) => {
              this.loading = false;
              this.user = user;
          
              if (user.usertype !='Client') 
              return (this.token.saved=true,Swal.fire({
                title: 'fonctionnalité non disponible pour ce type d\'utilisateur',
                icon: 'info',
                confirmButtonColor: '#3085d6',
              }),this.router.navigate(['home']))
              user.role=='basic'? this.annees.forEach(
                element => {
                  user.exercicecloture.find(e=>e.annee==`${element}`&&e.cloture==false)||!user.exercicecloture.find(e=>e.annee==`${element}`)?this.filtredannees.push(element):''
                }
              ):''
         if (user.regimefiscalimpot==='Réel')  
         {
          this.prepminimumperceptionammount=10.000
         }  
         else if (user.regimefiscalimpot==='Forfait D\'assiette') 
         {
          this.prepminimumperceptionammount=5.000
          Swal.fire({
            title: 'Comment voulez vous établir votre déclaration?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Etablir la déclaration fiscale d\'une façon guidée',
            cancelButtonText: 'Etablir la déclaration fiscale manuellement',
          }).then((result) => {
            if (result.value) {
              this.token.saved=true
              this.router.navigate(['declare-comptabilite']);
            }
    else{
    }
          }).catch(() => {
            Swal.fire('opération non aboutie!');
          });
         }
    if(user.activite!='Syndic des copropriétaires')
    {

      if (!user.natureactivite || user.natureactivite=='Autre/null' || !user.activite || user.activite=='Autre/null'
      || user.regimefiscalimpot=='Autre/null'|| !user.dateeffet|| !user.ficheUrl
      || !user.regimefiscalimpot&&user.nature=='Personne Physique' || user.matriculefiscale.length<17) 
      return (console.log(this.token.saved=true,this.token.saved),this.router.navigate(['complete-profil/'+user._id]))
      else if (user.regimefiscalimpot=='Réel')
      {
    
        Swal.fire({
          title: 'Votre régime fiscale en matière d\'impôts directs est le régime réel. Voulez vous établir votre déclaration à travers le module comptabilité?',
          
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'continuer avec ce module',
        }).then((result) => {
          if (result.value) {
            
            this.router.navigate(['declare-comptabilite']);
          }
  
        }).catch(() => {
          Swal.fire('opération non aboutie!');
        });
      }
   
    }
    if(user.activite=='Syndic des copropriétaires')
    {
      if (user.regimefiscalimpot=='Réel')
      {
    
        Swal.fire({
          title: 'Votre régime fiscale en matière d\'impôts directs est le régime réel. Voulez vous établir votre déclaration à travers le module comptabilité?',
          
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'continuer avec ce module',
        }).then((result) => {
          if (result.value) {
            
            this.router.navigate(['declare-comptabilite']);
          }
  
        }).catch(() => {
          Swal.fire('opération non aboutie!');
        });
      }
   
      user.coproprietaires?
  user.coproprietaires.length==0?
  (this.token.saved=true,Swal.fire('veuillez ajouter au moins un copropriétaire') ,this.router.navigate(['complete-profil/'+user._id]))
  :''
  :(this.token.saved=true,Swal.fire('veuillez ajouter au moins un copropriétaire'),this.router.navigate(['complete-profil/'+user._id]))

      if(user.matfiscale)
      {
        if (!user.coproprietaires||!user.natureactivite || user.natureactivite=='Autre/null' || user.regimefiscalimpot=='Autre/null' || !user.dateeffet
        || !user.regimefiscalimpot&&user.nature=='Personne Physique' 
        || !user.ficheUrl||user.matriculefiscale.length<17) return (this.token.saved=true,this.router.navigate(['complete-profil/'+user._id]))
      
      }
      else{
        if (!user.coproprietaires||!user.natureactivite || user.natureactivite=='Autre/null' || user.regimefiscalimpot=='Autre/null' || !user.dateeffet
        || !user.regimefiscalimpot&&user.nature=='Personne Physique' ) return (this.token.saved=true,this.router.navigate(['complete-profil/'+user._id]))
      }
    }
            }
          )
     }

  }
  verify(e)
  {
    this.DecfiscmensService.geexistenttdecfiscmens(this.user._id,this.gardeform.get('annee').value,this.gardeform.get('mois').value,'').then(
      (data:Decfiscmens[]) => {
if (data.length>0)
        {
          Swal.fire({
            title: 'vous avez déjà une déclaration qui existe avec ce mois et cette année, veuillez choisir entre les alternatives suivantes:',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Modifier la déclaration existente',
            cancelButtonText: 'actualiser déclaration',
          }).then((result) => 
          {
            if (result.value) {
            
              this.router.navigate(['modify-decfiscmens/'+data[0]._id])

  
            }
            else{
              this.token.saved=true
             this.reloadPage()

            }
          }).catch(() => {
            Swal.fire('opération non aboutie!')
          })
        }
        else{
              if(this.gardeform.get('annee').value=='2023')
              {
                this.tauxdt=1.000
              }
              else if(this.gardeform.get('annee').value=='2024')
              {
                this.tauxdt=1.000
              }
              else if(this.gardeform.get('annee').value=='2025')
              {
                this.tauxdt=1.000
              }
              if (this.user.regimefiscalimpot==='Réel'&&this.currentdate>=this.date1)  
              {
               this.prepminimumperceptionammount=20.000
              }  
              if (this.user.regimefiscalimpot==='Forfait D\'assiette'&&this.currentdate>=this.date1) 
              {
               this.prepminimumperceptionammount=10.000
          
              }
        }
      }
      
    )
  }
  verifyfutur(e)
  {
    let date=new Date()
    let anneactuel=date.getFullYear()
    let moisactuel=date.getMonth()+1
    let deffet= new Date(this.user.dateeffet)
 //   console.log(this.gardeform.get('annee').value,anneactuel,this.gardeform.get('mois').value,moisactuel)
 if(this.gardeform.get('annee').value&&!this.gardeform.get('mois').value)
 {
  if (this.gardeform.get('annee').value>anneactuel)
  return (
    Swal.fire({
    title: 'vous ne pouvez pas déposer une déclaration non encore échue',
    icon: 'error',
    confirmButtonColor: '#3085d6',
  }).then((result) => {this.gardeform.get('annee').reset(),this.gardeform.get('mois').reset()
  }).catch(() => {
    Swal.fire('opération non aboutie!')
  }))
 }
 else if(this.gardeform.get('annee').value&&this.gardeform.get('mois').value)
 {
if (this.gardeform.get('annee').value==anneactuel&&this.gardeform.get('mois').value>=moisactuel)
     return (
       Swal.fire({
       title: 'vous ne pouvez pas déposer une déclaration non encore échue',
       icon: 'error',
       confirmButtonColor: '#3085d6',
     }).then((result) => {this.gardeform.get('annee').reset(),this.gardeform.get('mois').reset()
     }).catch(() => {
       Swal.fire('opération non aboutie!')
     }))
     else   if (this.gardeform.get('annee').value<deffet.getFullYear()||this.gardeform.get('annee').value==deffet.getFullYear()&&this.gardeform.get('mois').value<deffet.getMonth()+1)
     return (
       Swal.fire({
       title: 'vous ne pouvez pas choisir une date antérieure à votre date d\'effet',
       icon: 'error',
       confirmButtonColor: '#3085d6',
     }).then((result) => {this.gardeform.get('annee').reset(),this.gardeform.get('mois').reset()
     }).catch(() => {
       Swal.fire('opération non aboutie!')
     }), this.loading=false) 
     else if(this.user.activite=='Syndic des copropriétaires'&&this.user.coproprietaires)
     {
       this.verify(event)
       this.user.coproprietaires.forEach((element,index) => {
       element.donneescomptable?
       !element.donneescomptable.find(e=>e.annee==this.gardeform.get('annee').value)?
       (this.filtredcops.push(element),
       this.displaycop='block' 
       )
       :
       '':
       ( this.filtredcops.push(element),
         this.displaycop='block' 
       )
      
     })
     this.ajoutdccopform = new FormGroup({                
       ammounts10: new FormArray(this.filtredcops.map(item => {
         const group = this.createammount10();
         //@ts-ignore
         group.patchValue(
           {
             numero:item.numero,
             coproprietaire:item.coproprietaire
           }); 
           return group;
       }))
     })
     }
     else
     {
       this.verify(event)
     }
 }

  }
}
