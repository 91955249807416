<mat-card class="invoice-card" id="invoicepdf">
  <mat-card-header class="card-header">
    <div class="header-content">
      <img mat-card-avatar [src]="invoice.logo" alt="Logo de l'entreprise">
      <mat-card-title>
        Facture #
        <textarea [(ngModel)]="invoice.prefixe" placeholder="Préfixe" style="resize: both;"></textarea>
        /
        <textarea [(ngModel)]="invoice.ref" placeholder="Référence" style="resize: both;"></textarea>
        ({{invoice.number}})
      </mat-card-title>
      <div class="header-dates">
        <span class="validity-date">
          <label>Valable jusqu'au:</label>
          <input [(ngModel)]="invoice.validityDate" type="date" />
        </span>
        <span class="current-date">
          <label>Tunis, le:</label> {{ currentDate | customDate }}
        </span>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div class="invoice-info">
      <h2>Informations sur le prestataire de service</h2>
      <p><textarea [(ngModel)]="invoice.fournisseurdetails.name" style="resize: both;"></textarea></p>
      <p><strong>Email :</strong> <textarea [(ngModel)]="invoice.fournisseurdetails.email" style="resize: both;"></textarea></p>
      <p><strong>Adresse :</strong> <textarea [(ngModel)]="invoice.fournisseurdetails.address" style="resize: both;"></textarea></p>
      <p><strong>Mobile :</strong> <textarea [(ngModel)]="invoice.fournisseurdetails.phone" style="resize: both;"></textarea></p>
    </div>
    <div class="invoice-info">
      <h2>Informations sur le client</h2>
      <p><strong>Nom :</strong> <textarea [(ngModel)]="invoice.clientdetails.name" style="resize: both;"></textarea></p>
      <p><strong>Email :</strong> <textarea [(ngModel)]="invoice.clientdetails.email" style="resize: both;"></textarea></p>
      <p><strong>Adresse :</strong> <textarea [(ngModel)]="invoice.clientdetails.address" style="resize: both;"></textarea></p>
      <p><strong>Mobile :</strong> <textarea [(ngModel)]="invoice.clientdetails.phone" style="resize: both;"></textarea></p>
    </div>
    <div class="invoice-items">
      <h2>Articles</h2>
      <mat-table [dataSource]="invoice.itemsdetails" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Numéro </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <input [(ngModel)]="item.id" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Libellé </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <textarea [(ngModel)]="item.libelle" style="resize: both;"></textarea>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <textarea [(ngModel)]="item.description" style="resize: both;"></textarea>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef> Quantité </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <input [(ngModel)]="item.quantity" type="number" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef> Prix unitaire </mat-header-cell>
          <mat-cell *matCellDef="let item">
            <input [(ngModel)]="item.unitPrice" type="number" />
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>

    <div class="invoice-section">
      <h2>Totaux</h2>
      <p>
        <strong>Sous-total :</strong>
        <input [(ngModel)]="subtotal" type="number" />
      </p>
      <p>
        <strong>TVA :</strong>
        <input [(ngModel)]="invoice.tva" type="number" step="0.01" />
      </p>
      <p>
        <strong>Total :</strong>
        <input [(ngModel)]="total" type="number" />
      </p>
      <p>
        <strong>Total en lettres :</strong>
        {{ total | numberToWords }} dinars
      </p>
    </div>

    <div class="invoice-section">
      <h2>Informations supplémentaires</h2>
      <p><strong>Statut :</strong> <textarea [(ngModel)]="invoice.status" style="resize: both;"></textarea></p>
      <p><strong>Conditions :</strong> <textarea [(ngModel)]="invoice.terms" style="resize: both;"></textarea></p>
      <p><strong>Remarques :</strong> <textarea [(ngModel)]="invoice.notes" style="resize: both;"></textarea></p>
    </div>

    <div class="invoice-section signatures">
      <div class="signature">
        <h3>Signature du vendeur</h3>
      </div>
      <div class="signature">
        <h3>Signature du client</h3>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="generatePDF()">Télécharger le PDF</button>
  </mat-card-actions>
</mat-card>
