<h2 id="traitementdetail"*ngIf="decfisconly=='oui'">Détails traitement: <span>{{decfiscmens.mois}}</span>-<span>{{decfiscmens.annee}}</span></h2> 
<mat-accordion id="payementinvoice" *ngIf="decfiscmens.origine!='généré automatiquement'">
  <mat-expansion-panel class="custom-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title id="bloc1title">
        Informations générales sur le fichier
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table table-bordered mt-4" id="bloc1content">
      <thead>
        <tr>
          <th scope="col">Date de création</th>
          <th scope="col">Date de modification</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ decfiscmens.created | customDate }}</td>
          <td>{{ decfiscmens.updated | customDate }}</td>
        </tr>
      </tbody>
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel class="custom-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title id="bloc2title">
        Informations spécifiques sur le fichier
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table table-bordered mt-4" id="bloc2content">
      <thead>
        <tr>
          <th scope="col" *ngIf="decfiscmens.affecte && currentUser.role == 'admin' || decfiscmens.affecte && currentUser.role == 'supervisor'">Collaborateur affecté</th>
          <th scope="col" *ngIf="currentUser.role == 'admin' || currentUser.role == 'supervisor'">Durée de traitement</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td *ngIf="decfiscmens.affecte && currentUser.role == 'admin' || decfiscmens.affecte && currentUser.role == 'supervisor'">{{ filterusers(decfiscmens.affecte) }}{{ prenomcollab }}&nbsp;{{ nomcollab }}</td>
          <td *ngIf="currentUser.role == 'admin' || currentUser.role == 'supervisor'">{{ countdown }}</td>
        </tr>
      </tbody>
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel class="custom-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title id="bloc3title">
        Informations Personnelles
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table table-bordered mt-4" id="bloc3content">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col">Prénom</th>
          <th scope="col">Mois</th>
          <th scope="col">Année</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ decfiscmens.lastname }}</td>
          <td>{{ decfiscmens.firstname }}</td>
          <td>{{ decfiscmens.mois }}</td>
          <td>{{ decfiscmens.annee }}</td>
        </tr>
      </tbody>
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel class="custom-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title id="bloc4title">
        Statuts et Motifs
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table table-bordered mt-4" id="bloc4content">
      <thead>
        <tr>
          <th scope="col">Statut</th>
          <th scope="col">Motif</th>
          <th scope="col">Date de traitement</th>
          <th scope="col">Durée de traitement</th>
          <th scope="col">Traité par</th>
        </tr>
      </thead>
      <tbody *ngFor="let statut of sortedallstatuts">
        <tr>
          <td style="text-align: center;">
            <span *ngIf="statut.statutclient">{{ statut.statutclient }}</span>
            <span *ngIf="statut.statut">{{ statut.statut }}:{{ statut.collaborateur }}</span>
            <span *ngIf="statut.statutcoll">{{ statut.statutcoll }}</span>
          </td>
          <td style="text-align: center;">
            <span *ngIf="statut.statut">{{ statut.motif }}</span>
            <span *ngIf="statut.statutcoll">{{ statut.motifcoll }}</span>
          </td>
          <td style="text-align: center;">{{ statut.datefin | customDate }}</td>
          <td style="text-align: center;">{{ statut.duree }} <span *ngIf="statut.duree">secondes</span></td>
          <td style="text-align: center;">
            <span *ngIf="statut.statutclient">Client</span>
            <span *ngIf="statut.statut">Admin</span>
            <span *ngIf="statut.statutcoll">Collaborateur</span>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel class="custom-expansion-panel">
    <mat-expansion-panel-header>
      <mat-panel-title id="bloc5title">
        Totaux
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table table-bordered mt-4" id="bloc5content">
      <thead>
        <tr>
          <th scope="col">Total traitement</th>
          <th scope="col">Coût par minute <span *ngIf="prixminute">(tarif de base)</span> <span *ngIf="prixminutespecial">(tarif préférentiel de groupe)</span> <span *ngIf="prixminuteindiv">(tarif préférentiel individuel)</span></th>
          <th scope="col">Total honoraires</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totaltime }} <span *ngIf="totaltime != 0">minutes</span></td>
          <td><span *ngIf="prixminute"> {{ prixminute | number:'1.3-3' | removeComma }} dinars</span> <span *ngIf="prixminutespecial">{{ prixminutespecial | number:'1.3-3' | removeComma }} dinars</span> <span *ngIf="prixminuteindiv">{{ prixminuteindiv | number:'1.3-3' | removeComma }} dinars</span></td>
          <td>{{ totalhonoraire | number:'1.3-3' | removeComma }} <span>dinars</span></td>
        </tr>
      </tbody>
    </table>
  </mat-expansion-panel>
</mat-accordion>

<button
        type="button"
        style="font-style: Monaco"
        class="btn btn-danger fs-5 mx-auto d-block rounded-pill mt-4 mb-3"
        (click)="payement()"*ngIf="decfiscmens.origine!='généré automatiquement'"
      >
        Generer Récapitulatif traitement
      </button>             
<div *ngIf="currentUser.role=='admin'&&decfiscmens.impottype1">
  <h2> Autre rubriques d'impots du type retenue à la source</h2>
<table>
  <thead>
<tr>
  <th  style="text-align: center;">Titre:</th>
  <th  style="text-align: center;">Montant:</th>
  <th  style="text-align: center;">Description:</th>
</tr>  
</thead>
  <tbody>
    <tr *ngFor="let ammount of decfiscmens.impottype1.autre; let i = index;">
      <th  style="text-align: center;"> {{decfiscmens.impottype1.autre[i].title | lowercase}}</th>
      <th  style="text-align: center;"> {{decfiscmens.impottype1.autre[i].ammount| lowercase}}</th>
      <th  style="text-align: center;"> {{decfiscmens.impottype1.autre[i].description| lowercase}}</th>
    </tr>
  </tbody>
</table>
</div>
<div class="container-fluid"id="deccont"*ngIf="decfiscmens.impottype1">
   
    
<div class="row d-flex justify-content-center">
      <div class="col-md-12">
          <div class="card">
             <div class="table-responsive p-2">
                <table>
                  <tr>
                    <th style="border: none">
                      <img src="../../assets/DGI_logo.png" id="dgi" class="navbar-left" alt="image-DGI">
                    </th>
                    <th style="border: none;">
                      <img src="../../assets/ministere-de-leconomie-et-des-finances.jpg" id="finance"  alt="image-finance">
                    </th>
                  </tr>
                </table>
                <strong style="font-size: 24px;">التصريح الشهري بالأداءات</strong>  
                  <table style="direction: rtl;">
                    <tr><th colspan="2">رمز التصريح </th><th colspan="2"></th><th>الشهر</th><th colspan="2">{{ decfiscmens.mois }}</th><th>السنة</th><th colspan="2">{{ decfiscmens.annee }}</th></tr>
                    <tr><th colspan="2">السجل التجاري</th><th colspan="2">{{ decfiscmens.registrecommerce }}</th><th></th><th colspan="2">المعرف الجبائي </th><th colspan="3"style="direction: ltr;">{{ decfiscmens.matriculefiscale }}</th></tr>
  <tr><th colspan="4">الاسم واللقب أوالاسم الاجتماعي: </th><th colspan="2"><span *ngIf="!decfiscmens.raisonsociale">{{ decfiscmens.firstname }} &nbsp; {{ decfiscmens.lastname }}</span> &nbsp; <span *ngIf="decfiscmens.raisonsociale">{{ decfiscmens.raisonsociale }}</span> </th></tr>
  <tr><th colspan="4">العنوان أو المقر الاجتماعي :</th><th colspan="2">{{ decfiscmens.adresse }}</th></tr>
  <tr><th colspan="4">الترقيم البريدي</th><th colspan="2">{{ decfiscmens.codepostal }}</th></tr>
  <tr><th colspan="2">النشـــــــــاط:</th><th colspan="2"><span *ngIf="decfiscmens.activite!='Médecin'&&natureactivite!='société'">{{ decfiscmens.activite }}</span><span *ngIf="decfiscmens.activite=='Médecin'">{{ decfiscmens.sousactivite }}</span><span *ngIf="natureactivite=='société'">{{ decfiscmens.sousactivite }}</span></th><th colspan="2">تاريخ توقيف النشاط</th><th colspan="2">{{ decfiscmens.datearretactivite }}</th></tr>   
  <tr>
    <th colspan="11" style="font-size: 20px;min-width:50px">نوع الأداء أو المعلوم المدفوع على أساس هذا التصريح : ضع علامة (x) في الخانة المناسبة</th>
  </tr>
  <tr id="row1">
    <th rowspan="2" style="min-width:50px">الخصم من المورد </th>
    <th rowspan="2" style="min-width:50px">الأداء على التكوين المهني</th>
    <th rowspan="2" style="min-width:50px">المساهمة في صندوق النهوض بالمسكن لفائدة الأجراء </th>
    <th rowspan="2" style="min-width:50px">المعلوم على الاستهلاك </th>
    <th rowspan="2" style="min-width:50px">الأداء على القيمة المضافة </th>
    <th rowspan="2" style="min-width:50px">معاليم أخرى على رقم المعاملات </th>
    <th rowspan="2" style="min-width:50px">المعاليم على التأمين</th>
    <th rowspan="2" style="min-width:50px">معلوم الطابع الجبائي </th>
    <th colspan="3" style="min-width:50px;text-align: center">المعاليم الراجعة للجماعات</th>
  </tr>
  <tr id="row1">
    <th style="min-width:50px">المعلوم على المؤسسات </th>
    <th style="min-width:50px">المعلوم على النزل </th>
    <th style="min-width:50px">معلوم الإجازة </th> 
  </tr>
  <tr id="row1" >
  <th style="text-align: center;"> <span *ngIf="type1"> X</span></th>
  <th style="text-align: center;"><span *ngIf="type3">X</span></th>
  <th style="text-align: center;"><span *ngIf="type4">X</span></th>
  <th></th>
  <th style="text-align: center;"><span *ngIf="type2">X</span></th>
  <th style="text-align: center;"><span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
    activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
    ||activite==='Sage-femmes'">X</span></th>
  <th></th>
  <th style="text-align: center;"><span *ngIf="type5">X</span></th>
  <th style="text-align: center;"><span *ngIf="type6">X</span></th>
  <th></th>
  <th></th>
  </tr>
  <tr id="row1"><th colspan="11" style="text-align: center;font-size: 20px;">الخصم من المورد</th></tr>
  <tr id="row1"><th colspan="6">البيانات</th><th colspan="2">أساس الخصم</th><th colspan="1">النسبة</th><th colspan="2">مبلغ الخصم</th></tr>
  <tr id="row1"><th colspan="6">1- المرتبات والأجور والجرايات والإيرادات العمرية التي تحملت خصما من المورد طبقا للقانون العام</th><th colspan="2">{{ decfiscmens.impottype1.traitementetsalaire.salaireimposable | number:'1.3-3'| removeComma}}</th><th></th><th colspan="2">{{ decfiscmens.impottype1.traitementetsalaire.retenuealasource | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">2- المرتبات والأجور واالمكافآت و المنح والامتازات العينية  الأخرى المدفوعة للأجانب</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">3- المساهمة الاجتماعية التضامنية المستوجبة على المرتبات و الأجورو المنح و الجرايات و الإيرادات العمرية</th><th colspan="2"></th><th></th><th colspan="2">{{ decfiscmens.impottype1.traitementetsalaire.contributionsociale | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">4- العمولات وأجور الوساطة والأكرية ومكافآت الأنشطة غير التجارية</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* المدفوعة للمقيمين والمستقرين</th><th colspan="5"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2">{{ decfiscmens.impottype1.location1.montantbrut  | number:'1.3-3'| removeComma}}</th><td>10%</td><th colspan="2">{{ decfiscmens.impottype1.location1.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون</th><th colspan="2">{{ decfiscmens.impottype1.location2.montantbrut  | number:'1.3-3'| removeComma}}</th><td>10%</td><th colspan="2">{{ decfiscmens.impottype1.location2.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">* المدفوعة لغير المقيمين وغير المستقرين</th><th colspan="5"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2">{{ decfiscmens.impottype1.location3.montantbrut  | number:'1.3-3'| removeComma}}</th><td>15%</td><th colspan="2">{{ decfiscmens.impottype1.location3.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون</th><th colspan="2">{{ decfiscmens.impottype1.location4.montantbrut  | number:'1.3-3'| removeComma}}</th><td>15%</td><th colspan="2">{{ decfiscmens.impottype1.location4.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">5- الأتعاب المدفوعة للأشخاص الطبيعيين غير الخاضعين للنظام الحقيقي</th><th colspan="2">{{ decfiscmens.impottype1.honoraire2.montantbrut  | number:'1.3-3'| removeComma}}</th><td>10%</td><th colspan="2">{{ decfiscmens.impottype1.honoraire2.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">6- الأتعاب المدفوعة للأشخاص المعنويين الخاضعين للضريبة على الشركات و التجمعات و شركات الأشخاص و ما شابهها و الأشخاص الطبيعيين الخاضعين للضريبة على الدخل حسب النظام الحقيقي</th><th colspan="2"><span *ngIf="honorairesum>0">{{ honorairesum  | number:'1.3-3'| removeComma}}</span></th><th>3%</th><th colspan="2"><span *ngIf="honoraireretenue>0">{{ honoraireretenue | number:'1.3-3'| removeComma}}</span></th></tr>
  <tr id="row1"><th colspan="6">7- المكافآت المدفوعة إلى الفنانين و المبدعين</th><th colspan="2"></th><td>5%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">8- أكرية النزل المدفوعة إلى الأشخاص المعنويين و الأشخاص الطبيعيين الخاضعين للنظام الحقيقي</th><th colspan="2"></th><td>5%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">9- المكافآت المدفوعة مقابل النجاعة في إسداء الخدمات لفائدة الغير </th><th colspan="2"></th><td>10%</td><th colspan="2"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
  <tr id="row1"><th colspan="6">البيانات</th><th colspan="2">أساس الخصم</th><th colspan="1">النسبة</th><th colspan="2">مبلغ الخصم</th></tr>
  <tr id="row1"><th colspan="6">10- فوائد الإيداعات بالحسابات الخاصة للادخار لدى المؤسسات البنكية و صندوق الادخار التونسي</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">11- مداخيل رؤوس الأموال المنقولة الأخرى </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* الراجعة للمقيمين </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* الراجعة لغير المقيمين </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">12- حصص الأسهم </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* الراجعة إلى الأشخاص الطبيعيين المقيمين </th><th colspan="2"></th><td>10%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* الراجعة إلى الأشخاص المعنويين و الأشخاص الطبيعيين  غير المقيمين </th><th colspan="2"></th><td>10%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">13- المكافآت والمنح المسندة لأعضاء المجالس والهيئات واللجان</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6"> * الراجعة للمقيمين </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">*  الراجعة لغير المقيمين </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون</th><th colspan="2"></th><td>20%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">14- التأجيرات المدفوعة للأجراء وغير الأجراء مقابل عمل وقتي أو ظرفي خارج نشاطهم الأصلي</th><th colspan="2"></th><td>15%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">15- فوائد القروض المدفوعة للمؤسسات البنكية غير المستقرة بالبلاد التونسية </th><th colspan="2"></th><td>10%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">16- سعر التفويت في العقارات و في الحقوق المتعلقة بها المصرح به بالعقد</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* بالنسبة إلى المقيمين و المستقرين</th><th colspan="2"></th><th>2.5%</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* بالنسبة إلى غير المقيمين و غير المستقرين </th><th colspan="2"></th><th>2.5%</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص طبيعيون</th><th colspan="2"></th><td>10%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">● أشخاص معنويون غير مستقرون بالنسبة للعقارات و الحقوق المتعلقة بها و الحقوق الاجتماعية في الشركات المدنية العقارية</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">17- المبالغ المدفوعة بعنوان الاقتناءات من سلع و معدات و تجهيزات و خدمات التي تساوي أو تفوق 1000 بما في ذلك الأداء على القيمة المضافة </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* لدى المؤسسات الخاضعة للضريبة على الشركات بنسبة 15%</th><th colspan="2">{{ decfiscmens.impottype1.montant10001.montantbrut  | number:'1.3-3'| removeComma}}</th><td>1%</td><th colspan="2">{{ decfiscmens.impottype1.montant10001.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">* لدى المؤسسات الخاضعة للضريبة على الشركات بنسبة 10%</th><th colspan="2">{{ decfiscmens.impottype1.montant10002.montantbrut  | number:'1.3-3'| removeComma}}</th><th>0.5%</th><th colspan="2">{{ decfiscmens.impottype1.montant10002.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">* لدى المؤسسات الفردية المنتفعة بطرح ثلثي المداخيل</th><th colspan="2">{{ decfiscmens.impottype1.montant10003.montantbrut  | number:'1.3-3'| removeComma}}</th><th>0.5%</th><th colspan="2">{{ decfiscmens.impottype1.montant10003.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">* لدى المؤسسات الأخرى</th><th colspan="2">{{ decfiscmens.impottype1.montant10004.montantbrut  | number:'1.3-3'| removeComma}}</th><td>1.5%</td><th colspan="2">{{ decfiscmens.impottype1.montant10004.montantretenue | number:'1.3-3'| removeComma}}</th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
 <tr id="row1"><th colspan="6">البيانات</th><th colspan="2">أساس الخصم</th><th colspan="1">النسبة</th><th colspan="2">مبلغ الخصم</th></tr>
 <tr id="row1"><th colspan="6">18- خصم الأداء على القيمة المضافة من الموردعلى المبالغ المدفوعة من قبل المؤسسات والمنشأت العمومية والتي تساوي أو تفوق 1000 دينار بما في ذلك الأداء على القيمة المضافة</th><th colspan="2"></th><td>25%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">19- خصم الأداء على القيمة المضافة من المورد بعنوان العمليات المنجزة مع الأشخاص الذين ليست لهم مؤسسة بالبلاد التونسية</th><th colspan="2"></th><th>100%</th><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">20- المكافآت المدفوعة إلى غير المقيمين الذين ينجزون أشغال بناء أو عمليات تركيب أو  أو خدمات أخرى لا تتجاوز مدتها ستة أشهر</th><th colspan="2"></th><td>5%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">21- المبالغ المدفوعة إلى غير المقيمين الذين ينشطون في إطار منشأت دائمة والذين لا يودعون التصريح في الوجود قبل بدأ النشاط</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">* منشآت دائمة تابعة لمؤسسات مقيمة ببلد أو إقليم ذي نظام جبائي تفاضلي</th><th colspan="2"></th><td>25%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">* منشآت دائمة أخرى</th><th colspan="2"></th><td>15%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">22- التسبقة الموظفة على البيوعات المؤسسات الصناعية و مؤسسات تجارة الجملة إلى الأشخاص الطبيعية الخاضعين لضربة على الداخل حسب النظام التقديري في صنف الأرباح الصناعية والتجارية أو على قاعدة تقديرية في صنف أرباح المهن غير التجارية </th><th colspan="2"></th><td>1%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">23- القيمة الزائدة المتأتية من تفويت الأشخاص غير المقيمين و غير المستقرين في الأسهم أو في المنابات الاجتماعية أو في حصص الصناديق المنصوص عليها بالتشريع المتعلق بها</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">*   أشخاص طبيعيون </th><th colspan="2"></th><td>10%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">*   أشخاص معنويون </th><th colspan="2"></th><td>15%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">24- المكافآت الأخرى المدفوعة إلى غير المقيمين غير المستقرين</th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">*   أشخاص طبيعيون </th><th colspan="2"></th><td>15%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">*   أشخاص معنويون </th><th colspan="2"></th><td>15%</td><th colspan="2"></th></tr>
 <tr id="row1"><th colspan="6">25- المكافآت الراجعة إلى أشخاص مقيمين أو مستقرين ببلد أو إقليم ذي نظام جبائي تفاضلي</th><th colspan="2"></th><td>25%</td><th colspan="2"></th></tr>                
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
<tr id="row1"><th colspan="6">البيانات</th><th colspan="2">أساس الخصم</th><th colspan="1">النسبة</th><th colspan="2">مبلغ الخصم</th></tr>
<tr id="row1"><th colspan="6">26- مبلغ العمولة الراجعة إلى الموزعين المعتمدين لمشغلي شبكة الإتصالات </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">*   أشخاص طبيعيون </th><th colspan="2"></th><td>1.5%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">*   أشخاص معنويون </th><th colspan="2"></th><td>1%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">27- الخصم من المورد بعنوان معالم التسجيل المستوجبة على عقود الصفقات العمومية </th><th colspan="2"></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">28- الخصم من المورد بعنوان المبالغ المتأتية من ألعاب الرهان والحظ واليانصيب</th><th colspan="2"></th><td>25%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">29- مبلغ الضريبة الموظفة على بيوعات مؤسسات الإنتاج الصناعي والمؤسسات الناشطة في قطاع التجارة لفائدة المتدخلين في توزيع السلع والمنتجات والخدمات والتي لاتتجاوز قيمتهاالجملية 20,000 دينار سنويا</th><th colspan="2"></th><th>3%</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">المجموع</th><th colspan="5">{{ totalretenueammount | number:'1.3-3'| removeComma}}</th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr><th colspan="11"style="text-align: center;font-size: 20px;">الأداء على التكوين المهني </th></tr>
  <tr id="row1"><th colspan="7">الانتفاع بالتسبقةعلى الأداء على التكوين المهني</th><th>نعم</th><th></th><th>لا</th><th></th></tr>
  <tr id="row1"><th colspan="6">البيــــــــــانـــــــــــــــات</th><th>أساس الأداء </th><th>النسبة</th><th>الأداء المستوجب</th><th colspan="2">الأداء القابل للطرح</th></tr>
  <tr id="row1"><th colspan="6">* الصناعات المعملية </th><th></th><td>1%</td><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">* الأنشطة الأخرى </th><th>{{ decfiscmens.impottype3.basetfp  | number:'1.3-3'| removeComma}}</th><td>2%</td><th>{{ decfiscmens.impottype3.montanttfpmois | number:'1.3-3'| removeComma}}</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">طرح مبلغ التسبقة </th><th></th><th></th><th></th><th colspan="2">{{ decfiscmens.impottype3.montantavance  | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">طرح مبلغ فائض التسبقة الذي لم يتسن طرحه </th><th></th><th></th><th></th><th colspan="2">{{ decfiscmens.impottype3.reporttfpmoisprecedent | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="6">طرح مبلغ العائدات التي لم يتسن طرحها </th><th></th><th></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">مبالغ إضافية للطرح بعنوان التسبقة أو العائدات </th><th></th><th></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">مبالغ للطرح من فائض العائدات التي لم يتسن طرحها</th><th></th><th></th><th></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">المجمـــــــــــــــــــــوع</th><th></th><th></th><th>{{totaltfpammount| number:'1.3-3'| removeComma}}</th><th colspan="2"><span *ngIf="tfpareporter>0">({{ decfiscmens.impottype3.tfpreporter | number:'1.3-3'| removeComma}})</span></th></tr>
  <tr id="row1"><th colspan="6">الباقي : (ب) مستوجب أو (ف) فائض</th><th colspan="2"> <span *ngIf="tfpapayer>0">P</span></th><th colspan="3"> <span *ngIf="tfpareporter>0">R</span></th></tr>
    <tr> <th colspan="11"></th></tr>
 
  
  <tr id="row1"><th colspan="7">مؤسسة معفاة </th><th>نعم</th><th></th><th>لا</th><th></th></tr>
  <tr id="row1"><th colspan="3">قرار المصادقة في المبالغ النهائية بعنوان طرح التسبقة</th><th>رفض</th><th></th><th >قبول</th><th></th><th colspan="4">تاريخ استلام مقرر المصادقة</th></tr>
  <tr id="row1"><th colspan="7"></th><th>اليوم</th><th>الشهر</th><th colspan="2">السنة</th></tr>
  <tr id="row1"><th colspan="7">مؤسسة معفاة </th><th>نعم</th><th></th><th>لا</th><th></th></tr>
    <tr> <th colspan="11"></th></tr>
  <tr ><th colspan="11"style="text-align: center;font-size: 20px;">المساهمة في صندوق النهوض بالمسكن لفائدة الأجراء </th></tr>
  <tr id="row1"><th colspan="4">أساس المساهمة </th><th colspan="3">النسبة</th><th colspan="4">المساهمة المستوجبة </th></tr>
  <tr id="row1"><th  colspan="4">{{ decfiscmens.impottype4.basefoprolos  | number:'1.3-3'| removeComma}}</th><td colspan="3">1%</td><th  colspan="4">{{ decfiscmens.impottype4.montantfoprolos | number:'1.3-3'| removeComma}}</th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr><th colspan="11"style="text-align: center;font-size: 20px;">المعلوم على الاستهلاك</th></tr>
  <tr id="row1"><th colspan="4">البيــــــــــانـــــــــــــــات</th><th colspan="2">المبلغ</th><th>النسبة </th><th colspan="2">المعلوم المستوجب</th><th colspan="2">المعلوم القابل للطرح</th></tr>
   <tr id="row1"><th colspan="4">1- رقم المعاملات دون اعتبار المعلوم على الاستهلاك والأداء على القيمة المضافة</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">2- الشراءات:</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">شراءات محلية </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">شراءات موردة </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">المجموع</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">الباقي : (ب) مستوجب أو (ف) فائض (I) - (II)</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">الفائض من الشهر السابق </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">المبالغ المسترجعة </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr id="row1"><th colspan="4">الباقي المستوجب أو الفائض (ب) أو (ف)</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
   <tr> <th colspan="11"></th></tr>
   <tr> <th colspan="11"></th></tr>
   <tr> <th colspan="11"></th></tr>
   <tr> <th colspan="11"></th></tr>
   <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
<tr><th colspan="11"style="text-align: center;font-size: 20px;">الأداء على القيمة المضافة (أ.ق.م)</th></tr>
<tr id="row1"><th colspan="4">البيــــــــــانـــــــــــــــات</th><th colspan="2">المبلغ</th><th>النسبة </th><th colspan="2">أ.ق.م. المستوجب</th><th colspan="2">أ.ق. م. القابل للطــرح</th></tr>
<tr id="row1"><th rowspan="3"colspan="4">1- رقم المعاملات الخاضع للأداء على القيمة المضافة دون اعتبـار أ. ق . م </th><th colspan="2"><span *ngIf="tva7">{{ htsum  | number:'1.3-3'| removeComma}}</span></th><td>7%</td><th colspan="2"><span *ngIf="tva7">{{ tvasum | number:'1.3-3'| removeComma}}</span></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="2"><span *ngIf="tva13">{{ ht13sum | number:'1.3-3'| removeComma}}</span></th><td>13%</td><th colspan="2"><span *ngIf="tva13">{{ tva13sum | number:'1.3-3'| removeComma}}</span></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="2"><span *ngIf="tva19">{{ ht19sum  | number:'1.3-3'| removeComma}}</span></th><td>19%</td><th colspan="2"><span *ngIf="tva19">{{ tva19sum | number:'1.3-3'| removeComma }}</span></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">* عمليات التسليم للنفس</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">2- الشراءات الخاضعـــــة لــ أ. ق. م. دون اعتبار أ. ق. م. والتي تخول حق الطرح</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">-  شراء عقارات : </th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableimmobilier.achatlocauxht | number:'1.3-3'| removeComma}}</th><th></th><th colspan="2"></th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableimmobilier.achatlocauxtva | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="4">-  شراء معدات : </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">* محلية</th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableequipement.achatlocauxht | number:'1.3-3'| removeComma }}</th><th></th><th colspan="2"></th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableequipement.achatlocauxtva | number:'1.3-3'| removeComma }}</th></tr>
  <tr id="row1"><th colspan="4">* موردة </th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableequipement.achatimporteht | number:'1.3-3'| removeComma }}</th><th></th><th colspan="2"></th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableequipement.achatimportetva | number:'1.3-3'| removeComma }}</th></tr>
  <tr id="row1"><th colspan="4">   -  شراءات أخرى  : </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">* محلية</th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxht  | number:'1.3-3'| removeComma}}</th><th></th><th colspan="2"></th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxtva | number:'1.3-3'| removeComma }}</th></tr>
  <tr id="row1"><th colspan="4">* موردة </th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableautreachat.achatimporteht  | number:'1.3-3'| removeComma}}</th><th></th><th colspan="2"></th><th colspan="2">{{ decfiscmens.impottype2.tvarecuperableautreachat.achatimportetva | number:'1.3-3'| removeComma }}</th></tr>
  <tr id="row1"><th colspan="4">3- طروحات أخرى : </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">*  أ. ق. م. المستوجب على المبالغ التي تساوي أو تفوق 1000 دينار بما في ذلك أ. ق. م. ومبلغ الخصم من المورد</th><th colspan="2"></th><td>25%</td><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">*   أ. ق. م. المستوجب على العمليات المنجزة مع الأشخاص الذين ليست لهم مؤسسات بالبلاد التونسية </th><th colspan="2"></th><th>100%</th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">*   أ. ق. م. تقديري على وسائل النقل </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">*   عمليات التسليم للنفس</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">4-التعديلات  :</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">   -   طرح إضافي :</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">* بعنوان عمليات الفسخ والإلغاء </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">* بعنوان عمليات أخرى </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="4">   -  إعادة دفع </th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="7">المجمـــــــــــــــــــــوع</th><th colspan="2">{{tvacollecte | number:'1.3-3'| removeComma}}</th><th colspan="2">{{tvarecuperable | number:'1.3-3'| removeComma}}</th></tr>
   
  <tr><th colspan="4">الباقي : (ب) مستوجب أو (ف) فائض (I) - (II)</th><th *ngIf="preptotaltvaammount > 0">P</th><th *ngIf="preptotaltvaammount > 0">{{preptotaltvaammount | number:'1.3-3'| removeComma}}</th><th *ngIf="0 > preptotaltvaammount">R</th><th *ngIf="0 > preptotaltvaammount">{{-preptotaltvaammount | number:'1.3-3'| removeComma}}</th></tr>
  <tr><th colspan="4">الفائض من الشهر السابق :</th><th colspan="2">{{ option64Value | number:'1.3-3'| removeComma}}</th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
  <tr><th colspan="4">المبلغ المسترجع :</th><th colspan="2"></th><th></th><th colspan="2"></th><th colspan="2"></th></tr>
 
  <tr><th colspan="11" style="font-size: 20px;">قسيمة خاصة بالمؤسسات التي طلبت استرجاع فائض الأداء على القيمة المضافة المعني بإيقاف حق الطرح </th></tr>
  
  <tr><th colspan="11" style="border-top: 2px solid #ddd;border-right: 2px solid #ddd;border-left: 2px solid #ddd;">فائض الأداء المطلوب استرجاعه والذي تم إيقاف حق طرحه خلال الشهر </th></tr>
  <tr><th colspan="11" style="border-right: 2px solid #ddd;border-left: 2px solid #ddd;">المبلغ الذي تم استرجاعه من هذا الفائض</th></tr>
  <tr><th colspan="11"style="border-right: 2px solid #ddd;border-left: 2px solid #ddd;">الباقي من فائض الأداء المطلوب استرجاعه والذي تم إيقاف حق طرحه خلال الأشهر السابقة</th></tr>
  <tr><th colspan="11"style="border-right: 2px solid #ddd;border-left: 2px solid #ddd;">مبلغ الفائض المعني باسترجاع حق الطرح (تجاوز 120 يوما من تاريخ العمل بإيقاف حق الطرح</th></tr>
  <tr><th colspan="11" style="border-bottom: 2px solid #ddd;border-right: 2px solid #ddd;border-left: 2px solid #ddd;">الباقي من فائض الأداء المطلوب استرجاعه والذي تم إيقاف حق طرحه</th></tr>


<tr id="row1"><th colspan="5">الباقي : (ب) مستوجب أو (ف) فائض</th><th colspan="2"*ngIf="totaltvaammount > 0">{{totaltvaammount | number:'1.3-3'| removeComma}}</th><th *ngIf="totaltvaammount > 0">P</th><th colspan="2"> <span *ngIf="totalreporttvaammount">{{totalreporttvaammount | number:'1.3-3'| removeComma}}</span></th><th ><span *ngIf="totalreporttvaammount">R</span></th></tr>
<tr id="row1"><th colspan="11" style="font-size: 20px;">نسبة الطرح المائوية بعنوان النشاط الخاضع بصفة جزئية</th></tr>
<tr id="row1"><th colspan="4">السنة المالية الجارية</th><th colspan="4">السنة المالية الفارطة</th><th colspan="3">الفارق</th></tr>
<tr id="row1"><th colspan="4"></th><th colspan="4"></th><th colspan="3"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr> 
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr> <th colspan="11"></th></tr>
<tr><th colspan="11"style="text-align: center;font-size: 20px;">إرشـــــــــادات أخـــــــــــرى </th></tr>
<tr id="row1"><th colspan="5">البيــــــــــانـــــــــــــــات</th><th colspan="2">المبلغ </th><th colspan="2">أ.ق. م. القابل للطــرح</th><th colspan="2">أ.ق.م. المستوجب</th></tr>
<tr id="row1"><th colspan="5">شراءات بتوقيف العمل بالأداء على القيمة المضافة </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">شراءات بتوقيف العمل بالأداء على القيمة المضافة بعنوان المواد و التجهيزات التي تدخل ضمن مكونات إنجاز صفقات بالخارج تساوي أو تفوق 3 مليون دينار </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">شراءات معفاة</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">شراءات لدى غير الخاضعين للأداء على القيمة المضافة</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">شراءات أخــــرى خاضعــة لــ أ.ق.م دون اعـــتبار أ. ق. م. ولا تنتفع بحق الطرح </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">تصدير بضائع وخدمات </th><th colspan="2">{{htexpsum | number:'1.3-3'| removeComma}}</th><th colspan="2"></th><th colspan="2">{{tvaexpsum | number:'1.3-3'| removeComma}}</th></tr>
<tr id="row1"><th colspan="5">بيوعات بتوقيف العمل أ.ق. م </th><th colspan="2"> {{htsussum | number:'1.3-3'| removeComma}}</th><th colspan="2"></th><th colspan="2">{{tvasussum | number:'1.3-3'| removeComma}}</th></tr>
<tr id="row1"><th colspan="5">رقم المعاملات المعفى من أ.ق.م</th><th colspan="2">{{htexosum | number:'1.3-3'| removeComma}}</th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">التفويت على الرصيف</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">المجمـــــــــــــــــــــوع</th><th colspan="2">{{htexpsum + htsussum + htexosum | number:'1.3-3'| removeComma}}</th><th colspan="2"></th><th colspan="2">{{tvaexpsum + tvasussum | number:'1.3-3'| removeComma}}</th></tr>
<tr><th colspan="11"style="text-align: center;font-size: 20px;">معاليم أخرى على رقم المعاملات</th></tr>
<tr id="row1"><th colspan="5">المعلــــــــــــــــوم</th><th colspan="2">الأســـــاس (د) </th><th colspan="2">النسبة </th><th colspan="2">المعلوم المستوجب (د) </th></tr>
<tr id="row1"><th colspan="5">1- استخلاصات لفائدة صندوق تنمية القدرة التنافسية في قطاعات الصناعة و الخدمات و الصناعات التقليدية </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">  المعلوم المهني </th><th colspan="2">{{totalfodecbase | number:'1.3-3'| removeComma}}</th><td colspan="2">1%</td><th colspan="2">{{totalfodec | number:'1.3-3'| removeComma}}</th></tr>
<tr id="row1"><th colspan="5">المعلوم على المصبرات الغذائية </th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">2- استخلاصات لفائدة صندوق تنمية القدرة التنافسية في القطاع الفلاحي والصيد البحري</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">المعلوم الموظف على منتوجات الصيد البحري </th><th colspan="2"></th><td colspan="2">2%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">المعلوم على القطانيا والصوجا </th><th colspan="2"></th><th colspan="2">2.5%</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">المعلوم على الخضر والغلال </th><th colspan="2"></th><td colspan="2">2%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5"> المعلوم على اللحوم </th><th colspan="2"></th><td colspan="2">0,050 د/ كغ</td><th colspan="2"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
<tr id="row1"><th colspan="5">المعلــــــــــــــــوم</th><th colspan="2">الأســـــاس (د) </th><th colspan="2">النسبة </th><th colspan="2">المعلوم المستوجب (د) </th></tr>
<tr id="row1"><th colspan="5">3- استخلاصات لفائدة صندوق تنمية القدرة التنافسية في قطاع السياحة</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">المعلوم الموظف على مستغلي المؤسسات السياحية والمطاعم السياحية المصنفة </th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5"> المعلوم على الرحلات الجوية و البحرية الدولية</th><th colspan="2"></th><th colspan="2">20 د/ مسافر</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">4- الأتاوة لفائدة صندوق تنمية المواصلات وتكنولوجيات المعلومات والاتصال</th><th colspan="2"></th><th colspan="2">5%</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">5- استخلاصات لفائدة الصندوق الوطني للتشغيل </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">    المعلوم على البيوعات المحلية للشاي والقهوة </th><th colspan="2"></th><th colspan="2">0,150 د/ كغ</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">  المعلوم التعويضي على الإسمنت</th><th colspan="2"></th><th colspan="2">2 د / طن</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">  المعلوم على بيع الإسمنت </th><th colspan="2"></th><th colspan="2">1 د / طن</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">6- المعلوم الموظف على الطماطم المعدة للتحويل </th><th colspan="2"></th><th colspan="2">0,005 د/ كغ</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5"> مساهمة منتجي الطماطم </th><th colspan="2"></th><th colspan="2">0,028 د/ كغ</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">    مساهمة مستغلي وحدات التحويل</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">7- معلوم للمحافظــة على البيئـــة لفائدة صندوق مقاومة التلوث </th><th colspan="2">{{totaltpebase | number:'1.3-3'| removeComma}}</th><th colspan="2">7%</th><th colspan="2">{{totaltpe | number:'1.3-3'| removeComma}}</th></tr>
<tr id="row1"><th colspan="5">8- استخلاصات لفائدة صندوق الانتقال الطاقي </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">معلوم على أجهزة تكييف الهواء </th><th colspan="2"></th><th colspan="2"> واط 1000/د10</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">معلوم على المصابيح والأنابيب </th><th colspan="2"></th><th colspan="2">40%</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">معلوم على المنتجات الطاقية المستهلكة</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">9- معلوم على الألعاب التي تتم المشاركة فيها مباشرة بالهاتف أو عن طريق المراسلات القصيرة أو الموزع الصوتي </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">10- معلوم لفائدة صندوق التشجيع على الإبداع الأدبي والفني</th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">11- معلوم لفائدة صندوق تمويل الراحة البيولوجية في قطاع الصيد البحري</th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>

<tr id="row1"><th colspan="5">12- أتاوة الدعم لفائدة الصندوق العام للتعويض </th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">الأتاوة الموظفة على المطاعم المصنفة طبقا للتشريع الجاري به العمل والمقاهي من الصنف الثاني والصنف الثالث وقاعات الشاي </th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">    الأتاوة الموظفة على الملاهي و الملاهي الليلية غير التابعة لمؤسسة سياحية و محلات صنع المرطبات</th><th colspan="2"></th><th colspan="2">3%</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">13- معلوم تضامني لفائدة صندوق تعويض الأضرار الفلاحية الناجمة عن الجوائح الطبيعية موظف على:</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">الخضر و الغلال</th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">الحبوب المجمعة من قبل ديوان الحبوب</th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">الزيتون</th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">منتجات الصيد البحري</th><th colspan="2"></th><td colspan="2">1%</td><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">14- معلوم الإقامة بالنزل السياحية</th><th colspan="2"></th><th colspan="2"></th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">15- معلوم لفائدة صندوق دعم الصحة العمومية </th><th colspan="2"><span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
  activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
  ||activite==='Sage-femmes'">{{ decfiscmens.impottype7.chiffreaffaireht  | number:'1.3-3'| removeComma}}</span></th><td colspan="2">1%</td><th colspan="2"><span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
    activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
    ||activite==='Sage-femmes'">{{ decfiscmens.impottype7.montantcontribution  | number:'1.3-3'| removeComma}}</span></th></tr>
<tr id="row1"><th colspan="5">16- المعلوم على السكر</th><th colspan="2"></th><th colspan="2">0,100 د/كغ</th><th colspan="2"></th></tr>
<tr id="row1"><th colspan="5">المجموع</th><th colspan="2">{{+totalfodecbase + +totaltpebase + +decfiscmens.impottype7.chiffreaffaireht | number:'1.3-3'| removeComma}}</th><th colspan="2"></th><th colspan="2"><span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
  activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
  ||activite==='Sage-femmes'; else onlyfodec">{{ +totalfspammount + +totaltpe + +totalfodec| number:'1.3-3'| removeComma}}</span> <span id="onlyfodec" *ngIf="!totalfspammount">{{+totaltpe + +totalfodec| number:'1.3-3'| removeComma}}</span> </th></tr>
  <tr id="row1"><th colspan="11"style="text-align: center;font-size: 20px">معلوم الطابع الجبائي </th></tr>
<tr id="row1"><th colspan="11"style="font-size: 20px">1- المعلوم المستوجب على المبالغ المفوترة و المبالغ المستخلصة بعنوان خدمات الهاتف و خدمات الأنترنات</th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr> 
<tr id="row1"><th colspan="11"style="font-size: 20px">2- المعلوم المستوجب على تذاكر البيع المسلمة من قبل المغازات التجارية و المستغلين لعلامة تجارية أجنبية</th></tr>
  <tr id="row1"><th colspan="4">رقم الفرع الثانوي</th><th colspan="3">عدد التذاكر</th><th colspan="4">المعلوم المستوجب</th></tr>
  <tr id="row1"><th colspan="4"></th><th colspan="3"></th><th colspan="4"></th></tr>
  <tr id="row1"><th colspan="4"></th><th colspan="3"></th><th colspan="4"></th></tr>
  <tr id="row1"><th colspan="4"></th><th colspan="3"></th><th colspan="4"></th></tr>
  <tr id="row1"><th colspan="4">المجموع</th><th colspan="3"></th><th colspan="4"></th></tr>
  <tr id="row1"><th colspan="11"style="font-size: 20px">3- المعلوم المستوجب على الوثائق الأخرى</th></tr>

  <tr id="row1"><th colspan="3">رقم الفرع الثانوي</th><th colspan="3">الوثيقة الخاضعة للمعلوم </th><th colspan="2">عدد الوثائق </th><th colspan="3">المعلوم المستوجب (د) </th></tr>
  <tr id="row1"><th colspan="3"></th><th>الرمز</th><th colspan="2">نوع الوثيقة</th><th colspan="2"></th><th colspan="3"></th></tr>
  <tr id="row1"><th colspan="3"></th><th></th><th colspan="2"></th><th colspan="2">{{ decfiscmens.impottype5.nombrenotehonoraire}}</th><th colspan="3"></th></tr>
  <tr id="row1"><th colspan="8">المجموع</th><th colspan="3">{{ decfiscmens.impottype5.totaldroittimbre | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="8">المجموع العام</th><th colspan="3"></th></tr>
  
  <tr id="row1"><th colspan="11"style="text-align: center;font-size: 20px">المعلوم على النزل</th></tr>
  <tr id="row1"><th colspan="6" style="border: none;"></th><th colspan="2">أساس المعلوم (د) </th><th>النسبة</th><th colspan="2">المعلوم المستوجب </th></tr>
  <tr id="row1"><th colspan="6">رقم المعاملات الخام </th><th colspan="2"></th><td>2%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="11"style="text-align: center;font-size: 20px">المعلوم على المؤسسات ذات الصبغة الصناعية أو التجارية أو المهنية </th></tr>
  <tr id="row1"><th colspan="6">1- مؤسسات تروج قصرا منتجات خاضعة لنظام المصادقة الإدارية للأسعار </th><th colspan="2">أساس المعلوم (د) </th><th>النسبة</th><th colspan="2">المعلوم المستوجب </th></tr>
  <tr id="row1"><th colspan="6">رقم المعاملات المحلي الخام المحقق</th><th colspan="2"></th><td>0.1%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">رقم المعاملات المتأتي من التصدير </th><th colspan="2"></th><td>0.1%</td><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="6">2- مؤسسات تدفع المعلوم على المؤسسات على أساس 25% من الضريبة عل الدخل أو الضريبة على الشركات</th><th colspan="2">أساس المعلوم (د) </th><th>النسبة</th><th colspan="2">المعلوم المستوجب </th></tr>
  <tr id="row1"><th colspan="6">الضريبة على الدخل أو على الشركات المستوجبة </th><th colspan="2"></th><td>25%</td><th colspan="2"></th></tr>
  
   
  <tr id="row1"><th colspan="6">3- مؤسسات أخرى </th><th colspan="2">أساس المعلوم (د) </th><th>النسبة</th><th colspan="2">المعلوم المستوجب </th></tr>
  <tr id="row1"><th colspan="6">رقم المعاملات المحلي الخام المحقق</th><th colspan="2">{{ cabase | number:'1.3-3'| removeComma}}</th><th>0.2%</th><th colspan="2">{{ camontant | number:'1.3-3'| removeComma}}</th></tr>
  
  <tr id="row1"><th colspan="6">رقم المعاملات المتأتي من التصدير  و من العمليات المنتفعة بنفس النظام الجبائي للتصدير</th><th colspan="2">{{caexportbase | number:'1.3-3'| removeComma}}</th><td>0.1%</td><th colspan="2">{{caexportmontant | number:'1.3-3'| removeComma}}</th></tr>
  <tr id="row1"><th colspan="9">المعلوم على المؤسسات ذات الصبغة الصناعية أو التجارية أو المهنية</th><th colspan="2">{{camontant + caexportmontant | number:'1.3-3'| removeComma}}</tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
  <tr id="row1"><th colspan="11"style="text-align: center;font-size: 20px">4- احتساب المعلوم على المؤسسات</th></tr>
  <tr id="row1"><th colspan="9"></th><th colspan="2">المعلوم المستوجب </th></tr>
  <tr id="row1"><th colspan="9">المعلوم على المؤسسات ذات الصبغة الصناعية أو التجارية أو المهنية</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="9">المعلوم المستوجب بعنوان الأشهر السابقة </th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="9">المجمـــــــــــــــــــــــــــــــوع</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="9">المعلوم المستوجب سنويا</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="9">الحد الأدنى للمعلوم على المؤسسات ذات الصبغة الصناعية أو التجارية أو المهنية</th><th colspan="2"></th></tr>
  <tr id="row1"><th colspan="9">الباقي مستوجب</th><th colspan="2"></th></tr>
    <tr> <th colspan="11"></th></tr>
  <tr id="row1"><th colspan="8">معلوم الإجازة الموظف على محلات بيع المشروبات بالنسبة لسنة :</th><th></th><th></th><th></th></tr>
    <tr> <th colspan="11"></th></tr>
  <tr id="row1"><th>الجماعة المحلية</th><th colspan="3">صنف 1</th><th colspan="3">صنف 2</th><th colspan="3">صنف 3</th><th>المجمــــــــوع العام</th></tr>
  <tr id="row1"><th></th><th>المبلغ</th><th>عدد المحلات</th><th>المجموع</th><th>المبلغ</th><th>عدد المحلات</th><th>المجموع</th><th>المبلغ</th><th>عدد المحلات</th><th>المجموع</th><th></th></tr>
  <tr id="row1"><th></th><th>25</th><th></th><th></th><th>150</th><th></th><th></th><th>300</th><th></th><th></th><th></th></tr>
  <tr id="row1"><th></th><th>25</th><th></th><th></th><th>150</th><th></th><th></th><th>300</th><th></th><th></th><th></th></tr>
  <tr id="row1"><th></th><th>25</th><th></th><th></th><th>150</th><th></th><th></th><th>300</th><th></th><th></th><th></th></tr>
  <tr id="row1"><th></th><th>25</th><th></th><th></th><th>150</th><th></th><th></th><th>300</th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="10">المجمـــــــــــــــــــــــــــــــوع</th><th></th></tr>
  <tr id="row1"><th colspan="11"style="text-align: center;font-size:24px">خلاصة الأداءات والمعاليم الواجب دفعها</th></tr>
  <tr id="row1"><th colspan="6">الضريبة أو المعلوم </th><th>مبلغ أصل الأداء</th><th>الطرح (الأداء المدفوع)</th><th>الأداء المستوجب</th><th>خطايا التأخير </th><th>المجمـــــــــوع</th></tr>
  <tr id="row1"><th colspan="6">الخصم من المورد </th><th><span *ngIf="type1">{{totalretenueammount | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">الأداء على التكوين المهني </th><th><span *ngIf="type3">{{totaltfpammount | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المساهمة في صندوق النهوض بالمسكن  لفائدة الأجراء</th><th><span *ngIf="type4">{{totalfoprolosammount | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المعلوم على الاستهلاك </th><th></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">الأداء على القيمة المضافة </th><th><span *ngIf="type2">{{totaltvaammount | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">معاليم أخرى على رقم المعاملات </th><th><span *ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
    activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
    ||activite==='Sage-femmes' ; else onlyfodecsum">{{ +decfiscmens.impottype7.montantcontribution + +totaltpe + +totalfodec | number:'1.3-3'| removeComma}}</span> <span id="onlyfodecsum"*ngIf="!totalfspammount">{{+totaltpe + +totalfodec | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">معاليم الطابع الجبائي </th><th><span *ngIf="type5||natureactivite=='société'">{{totaltimbreammount | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المعاليم الراجعة للجماعات المحلية :</th><th></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المعلوم على النزل </th><th></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المعلوم على المؤسسات ذات الصبغة  الصناعية أو التجارية أو المهنية</th><th><span *ngIf="type6">{{+camontant + +caexportmontant | number:'1.3-3'| removeComma}}</span></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">معلوم الإجازة</th><th></th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المعلوم الأدنى </th><th>{{minimumperceptionammount| number:'1.3-3'| removeComma}}</th><th></th><th></th><th></th><th></th></tr>
  <tr id="row1"><th colspan="6">المجموع</th><th>{{totaldeclaration | number:'1.3-3'| removeComma}}</th><th></th><th></th><th></th><th></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
    <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr> <th colspan="11"></th></tr>
  <tr id="row1"><th colspan="2">القباضة المالية بـــ </th><th></th><th>رمز القباضة </th><th></th><th>رمز الدفع</th><th></th><th colspan="4">إني الممضي أسفله أشهد بصحة البيانات التي تضمنها هذا التصريح</th></tr>
  <tr id="row1"><th colspan="7">رقم الوصل </th><th colspan="4" style="text-align: center; border-bottom: none;">في</th></tr>
  <tr id="row1"><th colspan="7">تاريخ الوصل </th><th colspan="4" rowspan="2" style="text-align: center;">الإمضــــــــــــــــاء</th></tr>
  <tr id="row1"><th colspan="7">طابع القبــاضة</th></tr>
</table>
<hr>
</div>
<hr id="bottom">      
</div>
</div>
</div>
</div>


<!-- Add a class to your mat-card for custom styles -->
<mat-card class="custom-card">
  <mat-card-header class="custom-card-header">
    <mat-card-title>Déclaration Fiscale</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="!decfiscmens.source && decfiscmens.origine !== 'généré automatiquement' && showbuttons">
      <!-- Tabs for different roles -->
      <mat-tab-group>
        <!-- Admin Tab -->
        <mat-tab label="Admin" *ngIf="currentUser.role === 'admin'">
          <mat-accordion>
            <!-- Affect Collaborator Panel -->
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ decfiscmens.affecte ? 'Réaffecter' : 'Affecter' }} Collaborateur
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field appearance="fill">
                <mat-label>Choisir collaborateur</mat-label>
                <mat-select #cricket [(ngModel)]="optionValue">
                  <mat-option value="default"></mat-option>
                  <mat-option *ngFor="let col of collab" [value]="col._id">
                    {{ col.firstname }} {{ col.lastname }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-raised-button color="primary" (click)="affect()">
                {{ decfiscmens.affecte ? 'Réaffecter' : 'Affecter' }} collaborateur (Admin)
              </button>
            </mat-expansion-panel>

            <!-- Modify Declaration Status Panel -->
            <mat-expansion-panel *ngIf="decfiscmens.affecte" class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Modifier Statut Déclaration Fiscale
                </mat-panel-title>
              </mat-expansion-panel-header>
              <button mat-raised-button color="primary" (click)="decideadmin()">
                Modifier Statut Déclaration Fiscale (Admin)
              </button>
            </mat-expansion-panel>

            <!-- Delete Declaration Panel -->
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Supprimer Déclaration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <button mat-raised-button color="warn" (click)="onDelete()">
                Supprimer Déclaration
              </button>
            </mat-expansion-panel>
            
            <!-- Manage Status Dropdown -->
            <mat-expansion-panel class="custom-expansion-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Gérer les Statuts
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field appearance="fill">
                <mat-label>Gérer les statuts:</mat-label>
                <mat-select #statusSelect (selectionChange)="update($event)">
                  <mat-option value="default"></mat-option>
                  <mat-option (click)="deletestatutcollab()">Supprimer le dernier statut collaborateur</mat-option>
                  <mat-option (click)="deletestatutadmin()">Supprimer le dernier statut admin</mat-option>
                  <mat-option (click)="deletestatutparam()">Supprimer le dernier statut paramêtrage</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>

        <!-- Supervisor Tab -->
        <mat-tab label="Supervisor" *ngIf="currentUser.role === 'supervisor' && decfiscmens.affecte === currentUser.userId || currentUser.rolesuperviseur === 'Autorisé' && decfiscmens.affecte === currentUser.userId">
          <button mat-raised-button color="primary" (click)="traite()">
            Marquer comme traité (Collaborateur)
          </button>
        </mat-tab>

        <!-- Basic User Tab -->
        <mat-tab label="User" *ngIf="currentUser.role === 'basic'">
          <button mat-raised-button color="accent" (click)="getNavigation('modify-decfiscmens', decfiscmens._id)"
          [ngStyle]="{ 'color': (verifyexercicecloture(decfiscmens.annee,decfiscmens.mois)) ? 'black' : 'white', 'background-color': (verifyexercicecloture(decfiscmens.annee,decfiscmens.mois)) ? 'gray' : 'green' }"
           [disabled]="verifyexercicecloture(decfiscmens.annee,decfiscmens.mois)">
            Modifier/Compléter
          </button>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <!-- Generate PDF button -->
    <div class="generate-pdf-button">
      <button mat-raised-button color="primary" (click)="openPDF()" [disabled]="!showgenerate && currentUser.role === 'basic'" [ngClass]="{'mat-primary': showgenerate, 'mat-warn': !showgenerate}">
        <span *ngIf="loading"><mat-icon>autorenew</mat-icon> Génération en cours...</span>
        <span *ngIf="!loading">Générer Déclaration (PDF)</span>
      </button>
    </div>
  </mat-card-content>
</mat-card>


