import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { interval, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { CommunService } from '../services/commun';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { ExcelService } from '../services/excel.service';
import { CarouselService } from '../services/settings';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { AlertService } from '../_helpers/alert.service';
import { Sort } from '../_helpers/sort';
import { UsedressourceService } from '../services/usedressource.service';
import { Usedressourcemodel } from '../models/usedressource.model';
import { ComponentCanDeactivate } from '../services/component-can-deactivate';
@Component({
  selector: 'app-view-deccomptabilite',
  templateUrl: './view-deccomptabilite.component.html',
  styleUrls: ['./view-deccomptabilite.component.scss']
})
export class ViewDeccomptabiliteComponent extends ComponentCanDeactivate implements OnInit,OnDestroy {

  showeditionnotepast=false;
  showeditionnote=false;
  showrecettejour=false
  showrelevemanuel=false;
  showrelevejoint=false
  showpaiemanuel=false
  showachat=false
  showachatcheque=false
  showachatespece=false
  showfacturefournisseur=false

  totalht0=0.000

  totalht=0.000
  totalhtfodec=0.000
  totalhttpe=0.000

  totaltva=0.000
  totaldt=0.000
  totalttc=0.000
  totalht2=0.000
  totaltva2=0.000
  totaldt2=0.000
  totalttc2=0.000
  totalrecette=0.000
  totalrecettefodec=0.000
  totalrecettetpe=0.000

  totalrecetteexo=0.000
  totalrecetteexofodec=0.000
  totalrecetteexotpe=0.000

  totalrecettesus=0.000
  totalrecettesusfodec=0.000
  totalrecettesustpe=0.000

  totalrecetteexp=0.000

  totalht3=0.000
  totalht8=0.000
  totalht9=0.000
  totalht11=0.000

  totalht3devise=0.000
  totaltva3=0.000
  totaldt3=0.000
  totalttc3=0.000
  totaldebit=0.000
  totalcredit=0.000
  totalsoldemois=0.000
  totalsalairebrut=0.000
  totalcnss=0.000
  totalsalaireimposable=0.000
  totalretenueimpot=0.000
  totalavancepret=0.000
  totalsalairenet=0.000
  totalht219=0.000;
  totaltva219=0.000;
  totaldt219=0.000;
  totalttc219=0.000;
  totalrecette19=0.000;
  totalrecette19fodec=0.000;
  totalrecette19tpe=0.000;

  totalht19=0.000;
  totalht19fodec=0.000;
  totalht19tpe=0.000;

  totaltva19=0.000;
  totaldt19=0.000;
  totalttc19=0.000; 
  totalht213=0.000;
  totaltva213=0.000;
  totaldt213=0.000;
  totalttc213=0.000;
  totalrecette13=0.000;
  totalrecette13fodec=0.000;
  totalrecette13tpe=0.000;

  totalht13=0.000;
  totaltva13=0.000;
  totaldt13=0.000;
  totalttc13=0.000; 
  loading: boolean;
  currentUser: any;
  role: string;
  usertype: string;
  deccomptabilite: Deccomptabilite;
  decfiscmens: Decfiscmens;
  statut: string;
  motif: string;
  firstname: string;
  lastname: string;
  nature: string;
  user: User;
  moisreleve: string;
  anneereleve: string;
  debitmoisprecedent: string;
  creditmoisprecedent: string;
  sousactivite: string;
  private usersSub: Subscription;
  users: User[]=[];
  collab: any[]=[];
  errormsg: any;
  optionValue: any;
  allstatuts: any[]=[];
  sortedallstatuts: any[]=[];
  filtredcollab: any[]=[];
  prenomcollab: any;
  nomcollab: any;
  showbuttons=false;
  totaltime: number;
  settingsSub: Subscription;
  settings: any []=[];
  tarifs: any []=[];
  tarifsbase: any []=[];
  tarifsclient: any []=[];
  tarifspecial: any []=[];

  prixminute: any;
  totalhonoraire=0.000;
  prixminuteindiv: any;
  prixminutepref: any;
  integ:any[]=[]
  public countdown=0
  private intervalSub: Subscription;
  selected: any;
  autre0: import("../models/dec-comptabilite").Autre0[];
  autre1: import("../models/dec-comptabilite").Autre[];
  autre2: import("../models/dec-comptabilite").Autre2[];
  autre3: import("../models/dec-comptabilite").Autre3[];
  autre8: import("../models/dec-comptabilite").Autre8[];
  autre9: import("../models/dec-comptabilite").Autre9[];
  autre11: import("../models/dec-comptabilite").Autre11[];

  autre4: import("../models/dec-comptabilite").Autre4[];
  autre5: import("../models/dec-comptabilite").Autre5[];
  autre6: import("../models/dec-comptabilite").Autre6[];
  activite: string;
  option1Value: string;
  totalhtexo=0.000;
  totalhtexofodec=0.000;
  totalhtexotpe=0.000;

  totalhtsus=0.000;
  totalhtsusfodec=0.000;
  totalhtsustpe=0.000;

  totalhtexp=0.000;
  totalttcexo=0.000;
  totalttcsus=0.000;
  totalttcexp=0.000;
  totalht2exo=0.000;
  totaltva2exo=0.000;
  totalht2sus=0.000;
  totaltva2sus=0.000;
  totalht2exp=0.000;
  totaltva2exp=0.000;
  totaltva1= 0.000
  totalttc1= 0.000
  totalhtrecette= 0.000
  totaltva2recette= 0.000
  totalttc2recette= 0.000
  totalttc2exo= 0.000
  totalttc2sus= 0.000
  totalttc2exp= 0.000
  statutsclients=[];
  contientnumeroother:boolean;
  showbuttonotheretatrecette:boolean;
  contientnumero:boolean;
  chosendate:Date
  firsttimer: number;
  prixminutespecial: any;
  natureactivite: string;
  tva19=false;
  tvasuspens=false;
  tvaexonere=false;
  tvaexport=false;
  tva7=false;
  tva13=false;
  tvafodec=false;
  tvatpe=false;
  totalht13fodec: number;
  totalht13tpe: number;

  constructor(
    private usedres: UsedressourceService,
    private userservice: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private deccompt: DeccomptabiliteService,
    private dec: DecfiscmensService,
    private auth: AuthService,
    private commun: CommunService,
    private tokenStorage: TokenStorageService,
    private alertService: AlertService,
    private excelService: ExcelService,
    private settservice: CarouselService

  ) {
    super();

   }
   verifyexercicecloture(annee:string,mois:string):boolean
    {
      let show=false
      let filtredmois=[]
      filtredmois=this.commun.filterMois(annee,this.user)
      filtredmois.find(e=>e.value==mois)?show=false:show=true
      return show
    }
  ngOnInit() {
    this.loading = true;
    this.currentUser = this.tokenStorage.getUser();
    this.tokenStorage.saved=false;
    if(this.currentUser.role==='admin'||this.currentUser.role==='supervisor')
    {
      this.intervalSub=interval(1000).subscribe(value => this.countdown=value), this.countdown=0
      this.firsttimer=Date.now()
      this.userservice.getAll()
      this.usersSub = this.userservice.users$.subscribe(
        (users) => {
          this.users = users;
          this.loading = false;
          let filtred=[]
          filtred=this.deccompt.filterByValue(this.users,'desactive')
          this.collab=filtred.filter((user) => user.usertype === ('Collaborateur'||'collaborateur'))
          },
        (error) => {
          this.loading = false;
          this.errormsg=error.message;
        }
      );
      
    }
    this.userservice.getUserById(this.currentUser.userId).then(
      (user: User) => {
        this.currentUser = user;
        this.role=user.role
        this.route.params.subscribe(
          (params) => {
            this.deccompt.getDeccomptabilitereqById(params.id).then(
              (deccomptabilite: Deccomptabilite) => {
                this.usedres.getUsedressourcedataById(deccomptabilite._id).then(
                  (usedres:Usedressourcemodel)=>
                        {
                if(usedres&&usedres.connecteduser!=this.currentUser._id)
                {
                  return (Swal.fire({
                    title: `fichier en cours d\'utilisation par  ${usedres.firstname} ${usedres.lastname}`,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                  }).then((result) => {
                    this.tokenStorage.saved=true
                    this.router.navigate(['home'])
                    this.loading=false
                  }).catch(() => {
                    Swal.fire('opération non aboutie!')
                  })) 
                }
                else if(!usedres)
                {
                  const newusedres:Usedressourcemodel =new Usedressourcemodel()
                  newusedres.connecteduser=this.currentUser._id
                  newusedres.ressourceid=deccomptabilite._id
                  newusedres.firstname=this.currentUser.firstname
                  newusedres.lastname=this.currentUser.lastname
                  newusedres.raisonsociale=this.currentUser.raisonsociale
                  newusedres.firstnameclient=deccomptabilite.firstname
                  newusedres.lastnameclient=deccomptabilite.lastname
                  newusedres.mois=deccomptabilite.mois
                  newusedres.annee=deccomptabilite.annee
                  newusedres.type='comptabilité'
                  this.usedres.create(newusedres).then(
                    (usedres:Usedressourcemodel)=>
                          {
                
                          }
                  )
                }
                        }
                )
               this.deccomptabilite=deccomptabilite
                if(this.deccomptabilite.affecte)
                {
                  this.optionValue=this.deccomptabilite.affecte
                }
                if(this.deccomptabilite.statutcollab.length>0)
                {
      //@ts-ignore
    this.statutsclients=this.deccomptabilite.statutcollab.filter(p => p.statutclient=='déposé par le client'||p.statutclient=='modifié par le client'||p.statutclient=='saisi pour le compte du client'||p.statutclient=='déposé pour le compte du client')
    if(this.statutsclients.length>0)
    {
    //@ts-ignore
    if(this.statutsclients[this.statutsclients.length -1].statutclient=='déposé par le client'||this.statutsclients[this.statutsclients.length -1].statutclient=='déposé pour le compte du client')
    {
      this.showbuttons=true
    }
    }
    
    
                }
                this.option1Value=deccomptabilite.annee
                this.nature=deccomptabilite.nature
                this.moisreleve=deccomptabilite.moisreleve
                this.anneereleve=deccomptabilite.anneereleve
                this.debitmoisprecedent=deccomptabilite.debitmoisprecedent
                this.creditmoisprecedent=deccomptabilite.creditmoisprecedent
                this.totalht0=deccomptabilite.totalht0
    
                this.totalht=deccomptabilite.totalht
                this.totalhtfodec=deccomptabilite.totalhtfodec
                this.totalhttpe=deccomptabilite.totalhttpe

                this.totalht19=deccomptabilite.totalht19
                this.totalht19fodec=deccomptabilite.totalht19fodec
                this.totalht19tpe=deccomptabilite.totalht19tpe

                this.totalht13=deccomptabilite.totalht13
                this.totalht13fodec=deccomptabilite.totalht13fodec
                this.totalht13tpe=deccomptabilite.totalht13tpe

                this.totalhtexo=deccomptabilite.totalhtexo
                this.totalhtexofodec=deccomptabilite.totalhtexofodec
                this.totalhtexotpe=deccomptabilite.totalhtexotpe

                this.totalhtsus=deccomptabilite.totalhtsus
                this.totalhtsusfodec=deccomptabilite.totalhtsusfodec
                this.totalhtsustpe=deccomptabilite.totalhtsustpe

                this.totalhtexp=deccomptabilite.totalhtexp
    
        this.totaltva=deccomptabilite.totaltva
        this.totaltva19=deccomptabilite.totaltva19
        this.totaltva13=deccomptabilite.totaltva13

        this.totaldt=deccomptabilite.totaldt
        this.totalttc=deccomptabilite.totalttc
        this.totalttc19=deccomptabilite.totalttc19
        this.totalttc13=deccomptabilite.totalttc13

        this.totalttcexo=deccomptabilite.totalttcexo
        this.totalttcsus=deccomptabilite.totalttcsus
        this.totalttcexp=deccomptabilite.totalttcexp
    
        this.totalht2=deccomptabilite.totalht2
        this.totaltva2=deccomptabilite.totaltva2
        this.totalht219=deccomptabilite.totalht219
        this.totaltva219=deccomptabilite.totaltva219
        this.totalht213=deccomptabilite.totalht213
        this.totaltva213=deccomptabilite.totaltva213
        this.totalht2exo=deccomptabilite.totalht2exo
        this.totaltva2exo=deccomptabilite.totaltva2exo
        this.totalht2sus=deccomptabilite.totalht2sus
        this.totaltva2sus=deccomptabilite.totaltva2sus
        this.totalht2exp=deccomptabilite.totalht2exp
        this.totaltva2exp=deccomptabilite.totaltva2exp
        this.totaldt2=deccomptabilite.totaldt2
        this.totalttc2=deccomptabilite.totalttc2
        
    
        this.totalrecette=deccomptabilite.totalrecette
        this.totalrecettefodec=deccomptabilite.totalrecettefodec
        this.totalrecettetpe=deccomptabilite.totalrecettetpe

        this.totalrecette19=deccomptabilite.totalrecette19
        this.totalrecette19fodec=deccomptabilite.totalrecette19fodec
        this.totalrecette19tpe=deccomptabilite.totalrecette19tpe

        this.totalrecette13=deccomptabilite.totalrecette13
        this.totalrecette13fodec=deccomptabilite.totalrecette13fodec
        this.totalrecette13tpe=deccomptabilite.totalrecette13tpe

        this.totalrecetteexo=deccomptabilite.totalrecetteexo
        this.totalrecetteexofodec=deccomptabilite.totalrecetteexofodec
        this.totalrecetteexotpe=deccomptabilite.totalrecetteexotpe

        this.totalrecettesus=deccomptabilite.totalrecettesus
        this.totalrecettesusfodec=deccomptabilite.totalrecettesusfodec
        this.totalrecettesustpe=deccomptabilite.totalrecettesustpe

        this.totalrecetteexp=deccomptabilite.totalrecetteexp
    
        this.totalht3=deccomptabilite.totalht3
        this.totalht8=deccomptabilite.totalht8
        this.totalht9=deccomptabilite.totalht9
        this.totalht11=deccomptabilite.totalht11
    
        this.totalht3devise=deccomptabilite.totalht3devise
        this.totaltva3=deccomptabilite.totaltva3
        this.totaldt3=deccomptabilite.totaldt3
        this.totalttc3=deccomptabilite.totalttc3
        this.totaldebit=deccomptabilite.totaldebit
        this.totalcredit=deccomptabilite.totalcredit
        this.totalsoldemois=deccomptabilite.totalsoldemois
        this.totalsalairebrut=deccomptabilite.totalsalairebrut
        this.totalcnss=deccomptabilite.totalcnss
        this.totalsalaireimposable=deccomptabilite.totalsalaireimposable
        this.totalretenueimpot=deccomptabilite.totalretenueimpot
        this.totalavancepret=deccomptabilite.totalavancepret
        this.totalsalairenet=deccomptabilite.totalsalairenet
        this.totalht219=deccomptabilite.totalht219
      this.totaltva219=deccomptabilite.totaltva219
      this.totaldt219=deccomptabilite.totaldt219
      this.totalttc219=deccomptabilite.totalttc219
      this.totalrecette19=deccomptabilite.totalrecette19
      this.totalht19=deccomptabilite.totalht19
      this.totaltva19=deccomptabilite.totaltva19
      this.totaldt19=deccomptabilite.totaldt19
      this.totalttc19=deccomptabilite.totalttc19
      
      this.totalht213=deccomptabilite.totalht213
      this.totaltva213=deccomptabilite.totaltva213
      this.totaldt213=deccomptabilite.totaldt213
      this.totalttc213=deccomptabilite.totalttc213
      this.totalrecette13=deccomptabilite.totalrecette13
      this.totalht13=deccomptabilite.totalht13
      this.totaltva13=deccomptabilite.totaltva13
      this.totaldt13=deccomptabilite.totaldt13
      this.totalttc13=deccomptabilite.totalttc13
     //deccomptabilite sum
     this.totaltva1=+this.totaltva
     this.totalttc1=+this.totalttc
     this.totalhtrecette=+this.totalht2
     this.totaltva2recette=+this.totaltva2
     this.totalttc2recette=+this.totalttc2
     this.showbuttonotheretatrecette=this.deccomptabilite.showbuttonotheretatrecette
                this.contientnumero=this.deccomptabilite.contientnumero
                this.contientnumeroother=this.deccomptabilite.contientnumeroother
                if(this.deccomptabilite.autre0[0])
                {
                  if(this.deccomptabilite.autre0[0].montantht!=''&&+this.deccomptabilite.autre0[0].montantht!=0||this.deccomptabilite.autre0[0].annule)
                  {
                 this.showeditionnotepast=true
                  }
                }
     
                if(this.deccomptabilite.autre1[0])
                {
                  if(this.deccomptabilite.autre1[0].montantht!=''&&+this.deccomptabilite.autre1[0].montantht!=0||this.deccomptabilite.autre1[0].montantht19!=''&&+this.deccomptabilite.autre1[0].montantht19!=0||
                  this.deccomptabilite.autre1[0].montantht13!=''&&+this.deccomptabilite.autre1[0].montantht13!=0||this.deccomptabilite.autre1[0].annule
                  ||this.deccomptabilite.autre1[0].montanthtexo!=''&&+this.deccomptabilite.autre1[0].montanthtexo!=0
                  ||this.deccomptabilite.autre1[0].montanthtsus!=''&&+this.deccomptabilite.autre1[0].montanthtsus!=0
                  ||this.deccomptabilite.autre1[0].montanthtexp!=''&&+this.deccomptabilite.autre1[0].montanthtexp!=0
                  ||this.deccomptabilite.autre1[0].montanthtexpdevise!=''&&+this.deccomptabilite.autre1[0].montanthtexpdevise!=0)
                  {
                    this.showeditionnote=true
    
                  }
                }
              if (this.deccomptabilite.autre2.length>0)
              {
                this.showrecettejour=true
              }
              if (this.deccomptabilite.autre3.length>0)
              {
               this.showachat=true
              }
              if (this.deccomptabilite.autre8.length>0)
              {
               this.showachatcheque=true
              }
              if (this.deccomptabilite.autre9.length>0)
              {
               this.showfacturefournisseur=true
              }
              if (this.deccomptabilite.autre11.length>0)
              {
               this.showachatespece=true
              }
              if (this.deccomptabilite.autre4.length>0)
              {
                this.showrelevemanuel=true
              }
              if (this.deccomptabilite.autre5.length>0)
              {
                this.showrelevejoint=true
              }
              if (this.deccomptabilite.autre6.length>0)
              {
                this.showpaiemanuel=true
              }
    
              this.userservice.getUserById(deccomptabilite.userId).then(
                (user: User) => {
                  if(user)
                  {
                    this.user = user;
                    this.user.tvaaffiche.find(el=>el.tauxtva=='7'&&el.affiche=='oui')?this.tva7=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='13'&&el.affiche=='oui')?this.tva13=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='19'&&el.affiche=='oui')?this.tva19=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='suspension'&&el.affiche=='oui')?this.tvasuspens=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='exonere'&&el.affiche=='oui')?this.tvaexonere=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='export'&&el.affiche=='oui')?this.tvaexport=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='fodec'&&el.affiche=='oui')?this.tvafodec=true:''
                    this.user.tvaaffiche.find(el=>el.tauxtva=='tpe'&&el.affiche=='oui')?this.tvatpe=true:''
                    this.role=user.role
                    this.usertype=user.usertype
                    this.firstname=user.firstname
                    this.lastname=user.lastname
                    this.sousactivite=user.sousactivite 
                    this.activite=user.activite
                    this.natureactivite=user.natureactivite
                    if(deccomptabilite.annee=='2023'&&this.activite=='Architectes'||deccomptabilite.annee=='2023'&&this.activite=='Syndic des copropriétaires'||deccomptabilite.annee=='2023'&&this.activite=='Ingénieurs-conseil'
                    ||deccomptabilite.annee=='2023'&&this.activite=='Dessinateurs'||deccomptabilite.annee=='2023'&&this.activite=='Géomètres'
                    ||deccomptabilite.annee=='2023'&&this.activite=='Topographes'||deccomptabilite.annee=='2023'&&this.activite=='Notaire'||
                    deccomptabilite.annee=='2023'&&this.activite=='Huissiers notaire'||deccomptabilite.annee=='2023'&&this.activite=='Interprètes'||
                    deccomptabilite.annee=='2023'&&this.activite=='Expert'||deccomptabilite.annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
                    {
                      this.tva19=true
                    }
                    else if(deccomptabilite.annee=='2024'&&this.activite=='Architectes'||deccomptabilite.annee=='2024'&&this.activite=='Syndic des copropriétaires'||deccomptabilite.annee=='2024'&&this.activite=='Ingénieurs-conseil'
                    ||deccomptabilite.annee=='2024'&&this.activite=='Dessinateurs'||deccomptabilite.annee=='2024'&&this.activite=='Géomètres'
                    ||deccomptabilite.annee=='2024'&&this.activite=='Topographes'||deccomptabilite.annee=='2024'&&this.activite=='Notaire'||
                    deccomptabilite.annee=='2024'&&this.activite=='Huissiers notaire'||deccomptabilite.annee=='2024'&&this.activite=='Interprètes'||
                    deccomptabilite.annee=='2024'&&this.activite=='Expert'||deccomptabilite.annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
                    {
                      this.tva19=true
                    }
                    else if(deccomptabilite.annee=='2025'&&this.activite=='Architectes'||deccomptabilite.annee=='2025'&&this.activite=='Syndic des copropriétaires'||deccomptabilite.annee=='2025'&&this.activite=='Ingénieurs-conseil'
                    ||deccomptabilite.annee=='2025'&&this.activite=='Dessinateurs'||deccomptabilite.annee=='2025'&&this.activite=='Géomètres'
                    ||deccomptabilite.annee=='2025'&&this.activite=='Topographes'||deccomptabilite.annee=='2025'&&this.activite=='Notaire'||
                    deccomptabilite.annee=='2025'&&this.activite=='Huissiers notaire'||deccomptabilite.annee=='2025'&&this.activite=='Interprètes'||
                    deccomptabilite.annee=='2025'&&this.activite=='Expert'||deccomptabilite.annee=='2025'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
                    {
                      this.tva19=true
                    }
                    else if
                    (deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Médecin'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Infirmier'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Masseur'||
                    deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Physiothérapeute'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Ergothérapeute'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Psychomotricien'||
                    deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Diététicien'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Orthophoniste'||deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Orthoptiste'||
                    deccomptabilite.annee=='2023'&&deccomptabilite.activite=='Sage-femmes')
                    {
                      this.tva7=true
                      this.tva19=true
                    }
                    else if
                    (deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Médecin'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Infirmier'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Masseur'||
                    deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Physiothérapeute'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Ergothérapeute'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Psychomotricien'||
                    deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Diététicien'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Orthophoniste'||deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Orthoptiste'||
                    deccomptabilite.annee=='2024'&&deccomptabilite.activite=='Sage-femmes')
                    {
                      this.tva7=true
                      this.tva19=true
                    }
                    else if
                    (deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Médecin'||deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Infirmier'||deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Masseur'||
                    deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Physiothérapeute'||deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Ergothérapeute'||deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Psychomotricien'||
                    deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Diététicien'||deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Orthophoniste'||deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Orthoptiste'||
                    deccomptabilite.annee=='2025'&&deccomptabilite.activite=='Sage-femmes')
                    {
                      this.tva7=true
                      this.tva19=true
                    }
                    this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
                      (decfiscmens: Decfiscmens[]) => {
                        console.log(decfiscmens)
                          this.deccompt.getDeccomptabilitereqById(deccomptabilite._id).then(
                            (deccomptab: Deccomptabilite) => {
                              console.log(deccomptab)
                              this.allstatuts=[]
                              const sort = new Sort();
                              this.loading = false;
                              this.allstatuts=this.allstatuts.concat(deccomptab.statutadmin,deccomptab.statutcollab,deccomptab.statutparam)
                              this.totaltime = Math.floor(+this.allstatuts.reduce((acc,curr)=>{
                                acc +=  +(curr.duree || 0);
                                return acc;
                              },0)/60);
                              this.sortedallstatuts=this.allstatuts.sort(sort.startSort('datefin','asc',''));
                              if(this.deccomptabilite.statutadmin.length>0)
                  {
                    this.settservice.getCarouselalldata()
                    this.settingsSub = this.settservice.carousels$.subscribe(
                      (settings) => {
                        this.settings = settings; 
                        this.tarifs=this.settings.filter(p => p.tarifs.length>0)
                       // user.prixspecialminute?this.tarifsclient.push(user.prixspecialminute):''
                        this.tarifs.forEach(async (item, _index) => {
                          item.tarifs[0].type=='Tarif individuel'?
                          this.tarifsclient.push(item.tarifs[0]):
                          item.tarifs[0].type=='Tarif spécial'?
                          this.tarifspecial.push(item.tarifs[0]):
                          this.tarifsbase.push(item.tarifs[0])
                                },
                               (error) => {
                                 this.loading = false;
                                 this.errormsg=error.message;
                               }
                             );
                           /*  this.tarifsclient.length>0?
                             (
                              this.prixminuteindiv=user.prixspecialminute,
                              this.totalhonoraire= Math.round((+this.totaltime * +this.prixminuteindiv) * 1000)/1000,
                              console.log(this.totalhonoraire)
                             ):*/
              //    console.log(this.tarifsclient,tarifspecial,tarifsbase)
              
              //
              
              //
                    this.gettarifindiv()   
                    this.gettarifspecial() 
                    this.gettarifbase() 
                    console.log(this.prixminute,this.prixminuteindiv,this.prixminutespecial)   
                    this.prixminuteindiv?
                    this.totalhonoraire= Math.round((+this.totaltime * +this.prixminuteindiv) * 1000)/1000:
                    this.prixminutespecial?
                    this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutespecial) * 1000)/1000:
                    this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000
              
              
                      }
                    )
        //@ts-ignore
        if(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='clôturé')
        {
        if(user)
        {
       
        }
        else if(!user)
        {
        this.settservice.getCarouselalldata()
        this.settingsSub = this.settservice.carousels$.subscribe(
          (settings) => {
            this.settings = settings; 
            this.tarifs=this.settings.filter(p => p.tarifs.length>0)
            this.tarifs.forEach(async (item, _index) => {
             //@ts-ignore
             if(item.tarifs[0].type=='Tarif spécial'&&new Date(item.tarifs[0].debut)<=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin)
             //@ts-ignore
             &&new Date(item.tarifs[0].fin)>=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin))
             {
             //@ts-ignore
              if(item.tarifs[0].nature==this.deccomptabilite.nature||item.tarifs[0].nature=='')
             {
         
               //@ts-ignore
             if(item.tarifs[0].natureactivite==this.deccomptabilite.natureactivite||item.tarifs[0].natureactivite=='')
             {
         
               //@ts-ignore
             if(item.tarifs[0].activite==this.deccomptabilite.activite||item.tarifs[0].activite=='')
             {
         
               //@ts-ignore
             if(item.tarifs[0].sousactivite==this.deccomptabilite.sousactivite||item.tarifs[0].sousactivite=='')
             {
         
               //@ts-ignore
             if(item.tarifs[0].impot==this.deccomptabilite.regimefiscalimpot||item.tarifs[0].impot=='')
             {
         
               this.prixminutepref=item.tarifs[0].prix
               this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutepref) * 1000)/1000
    
             
             }
             }
             }
             }
              }
             }
             //@ts-ignore
             else if(item.tarifs[0].type=='Tarif de base'&&new Date(item.tarifs[0].debut)<=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin)
             //@ts-ignore
             &&new Date(item.tarifs[0].fin)>=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin))
             {
         
               
              this.prixminute=item.tarifs[0].prix
              this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000
    
              
             }
                    },
                   (error) => {
                     this.loading = false;
                     this.errormsg=error.message;
                   }
                 );     
          }
        )
        }
        
        }
                  }
                            }
                          )
                        this.decfiscmens = decfiscmens[0];
                        
                      }
                    )
                  }
                  else
                  {
                    this.firstname=deccomptabilite.firstname
                    this.lastname=deccomptabilite.lastname
                    this.sousactivite=deccomptabilite.sousactivite
                  }
                  
                }
              )
    
              }
            )
          }
        )
      }
    )

  }
  canDeactivate():boolean {  
    this.ngOnDestroy()
    if(this.tokenStorage.saved)
    {
      return true;
    }
   
      return false;
  
  
  }
  gettarifindiv(){
    console.log(this.tarifsclient)
    this.tarifsclient.forEach(
      async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin)
//@ts-ignore
&&new Date(item.fin)>=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin))
{
console.log(item.user,this.user._id)
//@ts-ignore
if(item.user==this.user._id)
{  
console.log(this.prixminuteindiv)
this.prixminuteindiv=item.prix
console.log(this.totalhonoraire)
}
}
      }
    )
  }
  gettarifspecial(){
    console.log(this.tarifspecial)

    this.tarifspecial.forEach(
      async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin)
//@ts-ignore
&&new Date(item.fin)>=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin))
{
//@ts-ignore
if(item.nature==this.user.nature||item.nature=='')
{

//@ts-ignore
if(item.natureactivite==this.user.natureactivite||item.natureactivite=='')
{

//@ts-ignore
if(item.activite==this.user.activite||item.activite=='')
{

//@ts-ignore
if(item.sousactivite==this.user.sousactivite||item.sousactivite=='')
{

//@ts-ignore
if(item.impot==this.user.regimefiscalimpot||item.impot=='')
{
this.prixminutespecial=item.prix
console.log(this.totalhonoraire)

}
}
}
}
}
}
      }
    )
  }
  gettarifbase(){
    console.log(this.tarifsbase)
    this.tarifsbase.forEach(
      async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin)
//@ts-ignore
&&new Date(item.fin)>=new Date(this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].datefin))
{
this.prixminute=item.prix
console.log(this.totalhonoraire,this.totaltime,this.prixminute)
}
      }
    )   
  }
  getTotaldebit(i: number): number{
    let totalPrice: number
    let debitmois=+this.deccomptabilite.autre4[i].soldedebit
    totalPrice= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
            //@ts-ignore
      acc += +(curr.debit || 0);
      return acc;
    },0);
    totalPrice=totalPrice+debitmois
    return totalPrice;
  }
  getTotaldebitdevise(i: number): number{
    let totalPrice: number
    totalPrice= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
            //@ts-ignore
      acc += +(curr.debitdevise || 0);
      return acc;
    },0);
    return totalPrice;
  }
  getTotalcredit(i: number): number{
    let totalPrice: number
    let creditmois=+this.deccomptabilite.autre4[i].soldecredit
    totalPrice= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
      //@ts-ignore
acc += +(curr.credit || 0);
return acc;
},0);
    totalPrice=totalPrice+creditmois
    return totalPrice;
  }
  getTotalcreditdevise(i: number): number{
    let totalPrice: number
    let creditmois=+this.deccomptabilite.autre4[i].creditdevise
    totalPrice= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
      //@ts-ignore
acc += +(curr.creditdevise || 0);
return acc;
},0);
    totalPrice=totalPrice+creditmois
    return totalPrice;
  }
  getTotal(i: number): number{
    let totalPrice: number
    let totalPricedebit: number
    let totalPricecredit: number
    let debitmois=+this.deccomptabilite.autre4[i].soldedebit
    totalPricedebit= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
      //@ts-ignore
acc += +(curr.debit || 0);
return acc;
},0);
    totalPricedebit=totalPricedebit+debitmois
    let creditmois=+this.deccomptabilite.autre4[i].soldecredit
    totalPricecredit= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
      //@ts-ignore
acc += +(curr.credit || 0);
return acc;
},0);
    totalPricecredit=totalPricecredit+creditmois
    totalPrice=totalPricecredit-totalPricedebit
    return totalPrice;
  }
  getTotaldevise(i: number): number{
    let totalPrice: number
    let totalPricedebit: number
    let totalPricecredit: number
    totalPricedebit= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
      //@ts-ignore
acc += +(curr.debitdevise || 0);
return acc;
},0);
    let creditmois=+this.deccomptabilite.autre4[i].creditdevise
    totalPricecredit= +(this.deccomptabilite.autre4[i].mouvements).reduce((acc,curr)=>{
      //@ts-ignore
acc += +(curr.creditdevise || 0);
return acc;
},0);
    totalPricecredit=totalPricecredit+creditmois
    totalPrice=totalPricecredit-totalPricedebit
    return totalPrice;
  }
  filterusers(id:string)
  {
    this.filtredcollab=this.deccompt.filterByValue(this.collab,id)
    if(this.filtredcollab.length>0)
    {
      this.prenomcollab=this.filtredcollab[0].firstname
      this.nomcollab=this.filtredcollab[0].lastname
    }
    
  }
  public payement(): void {
    const self = this;
    const blocs = [
      document.getElementById('paiementtitle'),
      document.getElementById('bloc1title'),
      document.getElementById('table1'),
      document.getElementById('bloc2title'),
      document.getElementById('bloc2content'),
      document.getElementById('bloc3title'),
      document.getElementById('bloc3content'),
    ];
  
    const container = document.createElement('div');
  
    // Append all blocks to the container
    blocs.forEach(bloc => {
      if (bloc) {
        const clonedBloc = bloc.cloneNode(true); // Clone the node to avoid moving it
        container.appendChild(clonedBloc);
      }
    });
  
    document.body.appendChild(container); // Append the container to the body
  console.log(container)
    // Delay execution to ensure elements are fully rendered
    setTimeout(() => {
      html2canvas(container, { scale: 2 }).then((canvas: any) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
  
        doc.save(`facture_${self.deccomptabilite.mois}_${self.deccomptabilite.annee}`);
      });
    // Remove the container from the DOM after PDF generation
    document.body.removeChild(container);
    }, 500); // Delay execution by 500 milliseconds (adjust as needed)
  }
  generateExcel()
  {
    this.integ=[]
if(this.currentUser.role==='admin'||this.currentUser.role==='supervisor')
                {
      
      this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
        (deccomptab: Deccomptabilite) => {
          this.userservice.getUserById(deccomptab.userId).then(
            (user: User) => {
              if(user.activite!='Syndic des copropriétaires')
              {
                this.autre1=deccomptab.autre1
                this.autre2=deccomptab.autre2
                this.autre3=deccomptab.autre3
                this.autre4=deccomptab.autre4
                this.autre5=deccomptab.autre5
                this.autre6=deccomptab.autre6
                deccomptab.autre2bis=[]
                deccomptab.autre1bis=[]
                deccomptab.autre3bis=[]
                deccomptab.autre4bis=[]
                deccomptab.autre5bis=[]
                deccomptab.autre6bis=[]
      
                if (this.autre3.length>0) 
                {
                  for (let i = 0; i < this.autre3.length; i+=1)
                  {
                    const item = this.autre3[i];
                    if(item.typepiece=='facture')
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre3bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Facture N° ${item.numerofacture} ${item.fournisseur}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                        }
                        if(+item.montanthtdevise!=0)
                        {
                          deccomptab.autre3bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Facture N° ${item.numerofacture} ${item.fournisseur} ${item.montanthtdevise} ${item.devise}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cptehtdevise!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cptehtdevise:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montanthtachat,  
                          })
                        } 
               if(+item.montanttva!=0)
               {
                deccomptab.autre3bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Facture N° ${item.numerofacture} ${item.fournisseur}`,
                  reflettrage:`${item.numerofacture}`,
              numeroexterne:`${item.numerofacture}`,
              //@ts-ignore
              compte:user.paramcomptable[0]&&user.paramcomptable[0].comptetvaachat!=''?user.paramcomptable[0].comptetvaachat:'46100000',
                            sens:'D',
                  montant: +item.montanttva,                
                })
               }
              if(+item.montantdt!=0)
              {
                deccomptab.autre3bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                  date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Facture N° ${item.numerofacture} ${item.fournisseur}`,
              reflettrage:`${item.numerofacture}`,
              numeroexterne:`${item.numerofacture}`,
             //@ts-ignore
             compte:user.paramcomptable[0]&&user.paramcomptable[0].cptetimbrefiscalachat!=''?user.paramcomptable[0].cptetimbrefiscalachat:'46100000',
             sens:'D',
                  montant: +item.montantdt,
                })
              }
                if(+item.montantttc!=0)
                {
                  deccomptab.autre3bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Facture N° ${item.numerofacture} ${item.fournisseur}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0]&&user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantttc,
                
                  }) 
                }
                      }
                      else if(item.typepiece=='avoir')  
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre3bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montantht,  
                          })
                        }
                        if(+item.montanthtdevise!=0)
                        {
                          deccomptab.autre3bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur} ${item.montanthtdevise} ${item.devise}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cptehtdevise!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cptehtdevise:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montanthtachat,  
                          })
                        } 
               if(+item.montanttva!=0)
               {
                deccomptab.autre3bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur}`,
                  reflettrage:`${item.numerofacture}`,
              numeroexterne:`${item.numerofacture}`,
              //@ts-ignore
              compte:user.paramcomptable[0]&&user.paramcomptable[0].comptetvaachat!=''?user.paramcomptable[0].comptetvaachat:'46100000',
                            sens:'C',
                  montant: +item.montanttva,                
                })
               }
              if(+item.montantdt!=0)
              {
                deccomptab.autre3bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                  date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur}`,
              reflettrage:`${item.numerofacture}`,
              numeroexterne:`${item.numerofacture}`,
             //@ts-ignore
             compte:user.paramcomptable[0]&&user.paramcomptable[0].cptetimbrefiscalachat!=''?user.paramcomptable[0].cptetimbrefiscalachat:'46100000',
             sens:'C',
                  montant: +item.montantdt,
                })
              }
                if(+item.montantttc!=0)
                {
                  deccomptab.autre3bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0]&&user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc:'46100000':'46100000',
                    sens:'D',
                    montant: +item.montantttc,
                
                  }) 
                }
                      }
                  
                  } 
                }
      
                if(this.autre1.length>0)
                  {
                    for (let i = 0; i < this.autre1.length; i++)
                    { 
                      const item = this.autre1[i];
                      if(item.typepiece=='facture')
                      {
                        if((+item.montantht)>0)
                        {
                          deccomptab.autre1bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Facture N° ${item.numeronote} ${item.client}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva- +item.montanttva19)/(+item.montantht))*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cpteht!=''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cpteht:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montantht,  
                          })
                        }
                        //set montanthtfodec
                        if((+item.montanthtfodec)>0)
                        {
                          deccomptab.autre1bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Facture N° ${item.numeronote} ${item.client}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva- +item.montanttva19)/(+item.montantht))*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehtfodec!=''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehtfodec:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montanthtfodec,  
                          })
                        }
                                //set montanthttpe
                                if((+item.montanthttpe)>0)
                                {
                                  deccomptab.autre1bis.push({
                                    code:user.clientcode,
                                    //@ts-ignore
                                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                                    date: item.date,
                                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                                    numeropiece:item.numeropiece,
                                    libelle:`Facture N° ${item.numeronote} ${item.client}`,
                                    reflettrage:`${item.numeronote}`,
                                    numeroexterne:`${item.numeronote}`,
                                    //@ts-ignore
                compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva- +item.montanttva19)/(+item.montantht))*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehttpe!=''?
                                    //@ts-ignore
                user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehttpe:'46100000':'46100000',
                                    sens:'C',
                                    montant: +item.montanthttpe,  
                                  })
                                }
                         //set montantht19
                if(+item.montantht19>0)
                {
                  deccomptab.autre1bis.push({
                    code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                    date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Facture N° ${item.numeronote} ${item.client}`,
                    reflettrage:`${item.numeronote}`,
                    numeroexterne:`${item.numeronote}`,
                    //@ts-ignore
      compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cpteht>''?
                    //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cpteht:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht19,  
                  })
                }
                       //set montantht19fodec
                       if(+item.montantht19fodec>0)
                       {
                         deccomptab.autre1bis.push({
                           code:user.clientcode,
                           //@ts-ignore
                           journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                           date: item.date,
                           prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                           numeropiece:item.numeropiece,
                           libelle:`Facture N° ${item.numeronote} ${item.client}`,
                           reflettrage:`${item.numeronote}`,
                           numeroexterne:`${item.numeronote}`,
                           //@ts-ignore
             compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehtfodec>''?
                           //@ts-ignore
             user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehtfodec:'46100000':'46100000',
                           sens:'C',
                           montant: +item.montantht19fodec,  
                         })
                       }
                              //set montantht19tpe
                if(+item.montantht19tpe>0)
                {
                  deccomptab.autre1bis.push({
                    code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                    date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Facture N° ${item.numeronote} ${item.client}`,
                    reflettrage:`${item.numeronote}`,
                    numeroexterne:`${item.numeronote}`,
                    //@ts-ignore
      compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehttpe>''?
                    //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehttpe:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht19tpe,  
                  })
                }
                            //set montantht13
                            if(+item.montantht13>0)
                            {
                              deccomptab.autre1bis.push({
                                code:user.clientcode,
                                //@ts-ignore
                                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                                date: item.date,
                                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                                numeropiece:item.numeropiece,
                                libelle:`Facture N° ${item.numeronote} ${item.client}`,
                                reflettrage:`${item.numeronote}`,
                                numeroexterne:`${item.numeronote}`,
                                //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cpteht>''?
                                //@ts-ignore
                  user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cpteht:'46100000':'46100000',
                                sens:'C',
                                montant: +item.montantht13,  
                              })
                            } 
                            //set montantht13fodec   
                            if(+item.montantht13fodec>0)
                            {
                              deccomptab.autre1bis.push({
                                code:user.clientcode,
                                //@ts-ignore
                                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                                date: item.date,
                                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                                numeropiece:item.numeropiece,
                                libelle:`Facture N° ${item.numeronote} ${item.client}`,
                                reflettrage:`${item.numeronote}`,
                                numeroexterne:`${item.numeronote}`,
                                //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehtfodec>''?
                                //@ts-ignore
                  user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehtfodec:'46100000':'46100000',
                                sens:'C',
                                montant: +item.montantht13fodec,  
                              })
                            }   
                            if(+item.montantht13tpe>0)
                            {
                              deccomptab.autre1bis.push({
                                code:user.clientcode,
                                //@ts-ignore
                                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                                date: item.date,
                                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                                numeropiece:item.numeropiece,
                                libelle:`Facture N° ${item.numeronote} ${item.client}`,
                                reflettrage:`${item.numeronote}`,
                                numeroexterne:`${item.numeronote}`,
                                //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehttpe>''?
                                //@ts-ignore
                  user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehttpe:'46100000':'46100000',
                                sens:'C',
                                montant: +item.montantht13tpe,  
                              })
                            }       
                    //set montanthtexo
                    if(+item.montanthtexo>0)
                    {
                      deccomptab.autre1bis.push({
                        code:user.clientcode,
                        //@ts-ignore
                        journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                        date: item.date,
                        prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                        numeropiece:item.numeropiece,
                        libelle:`Facture N° ${item.numeronote} ${item.client}`,
                        reflettrage:`${item.numeronote}`,
                        numeroexterne:`${item.numeronote}`,
                        //@ts-ignore
        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cpteht>''?
                        //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cpteht:'46100000':'46100000',
                        sens:'C',
                        montant: +item.montanthtexo,  
                      })
                    }
           //set montanthtexofodec
           if(+item.montanthtexofodec>0)
           {
             deccomptab.autre1bis.push({
               code:user.clientcode,
               //@ts-ignore
               journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
               date: item.date,
               prefixe:`${deccomptab.annee}${deccomptab.mois}`,
               numeropiece:item.numeropiece,
               libelle:`Facture N° ${item.numeronote} ${item.client}`,
               reflettrage:`${item.numeronote}`,
               numeroexterne:`${item.numeronote}`,
               //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehtfodec>''?
               //@ts-ignore
user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehtfodec:'46100000':'46100000',
               sens:'C',
               montant: +item.montanthtexofodec,  
             })
           }
            //set montanthtexotpe
            if(+item.montanthtexotpe>0)
            {
              deccomptab.autre1bis.push({
                code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`Facture N° ${item.numeronote} ${item.client}`,
                reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
                //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehttpe>''?
                //@ts-ignore
user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehttpe:'46100000':'46100000',
                sens:'C',
                montant: +item.montanthtexotpe,  
              })
            }
          
           
                //set montanthtsus
                if(+item.montanthtsus>0)
                        {
                          deccomptab.autre1bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Facture N° ${item.numeronote} ${item.client}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cpteht>''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cpteht:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montanthtsus,  
                          })
                        }
                 //set montanthtsusfodec
                 if(+item.montanthtsusfodec>0)
                 {
                   deccomptab.autre1bis.push({
                     code:user.clientcode,
                     //@ts-ignore
                     journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                     date: item.date,
                     prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                     numeropiece:item.numeropiece,
                     libelle:`Facture N° ${item.numeronote} ${item.client}`,
                     reflettrage:`${item.numeronote}`,
                     numeroexterne:`${item.numeronote}`,
                     //@ts-ignore
 compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehtfodec>''?
                     //@ts-ignore
 user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehtfodec:'46100000':'46100000',
                     sens:'C',
                     montant: +item.montanthtsusfodec,  
                   })
                 }
                    //set montanthtsustpe
                if(+item.montanthtsustpe>0)
                {
                  deccomptab.autre1bis.push({
                    code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                    date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Facture N° ${item.numeronote} ${item.client}`,
                    reflettrage:`${item.numeronote}`,
                    numeroexterne:`${item.numeronote}`,
                    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehttpe>''?
                    //@ts-ignore
user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehttpe:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montanthtsustpe,  
                  })
                }
              
            
                    //set montanthtexp
                    if(+item.montanthtexp>0)
                    {
                      deccomptab.autre1bis.push({
                        code:user.clientcode,
                        //@ts-ignore
                        journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                        date: item.date,
                        prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                        numeropiece:item.numeropiece,
                        libelle: +item.montanthtexpdevise==0?`Facture N° ${item.numeronote} ${item.client}`:`Facture N° ${item.numeronote} ${item.client} ${item.montanthtexpdevise} ${item.devise}`,
                        reflettrage:`${item.numeronote}`,
                        numeroexterne:`${item.numeronote}`,
                        //@ts-ignore
        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `export`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `export`)[0].cpteht>''?
                        //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `export`)[0].cpteht:'46100000':'46100000',
                        sens:'C',
                        montant: +item.montanthtexp,  
                      })
                    }
               if((+item.montanttva - +item.montanttva19- +item.montanttva13)>0)
               {
                deccomptab.autre1bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Facture N° ${item.numeronote} ${item.client}`,
                  reflettrage:`${item.numeronote}`,
                  numeroexterne:`${item.numeronote}`,
                  //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19- +item.montanttva13))/(+item.montantht + +item.montanthtfodec+ +item.montanthttpe))*100)}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19- +item.montanttva13))/(+item.montantht + +item.montanthtfodec+ +item.montanthttpe))*100)}`)[0].cptetva!=''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19- +item.montanttva13))/(+item.montantht + +item.montanthtfodec+ +item.montanthttpe))*100)}`)[0].cptetva:'46100000':'46100000',
                  sens:'C',
                  montant: (+item.montanttva - +item.montanttva19- +item.montanttva13),                
                })
               }
                      
               if(+item.montanttva19>0)
               {
                deccomptab.autre1bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Facture N° ${item.numeronote} ${item.client}`,
                  reflettrage:`${item.numeronote}`,
                  numeroexterne:`${item.numeronote}`,
                  //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva19/(+item.montantht19 + +item.montantht19fodec+ +item.montantht19tpe))*100)}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva19/(+item.montantht19 + +item.montantht19fodec+ +item.montantht19tpe))*100)}`)[0].cptetva>''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva19/(+item.montantht19 + +item.montantht19fodec+ +item.montantht19tpe))*100)}`)[0].cptetva:'46100000':'46100000',
                  sens:'C',
                  montant: +item.montanttva19,                
                })
               }

               if(+item.montanttva13>0)
               {
                deccomptab.autre1bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Facture N° ${item.numeronote} ${item.client}`,
                  reflettrage:`${item.numeronote}`,
                  numeroexterne:`${item.numeronote}`,
                  //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva13/(+item.montantht13 + +item.montantht13fodec+ +item.montantht13tpe))*100)}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva13/(+item.montantht13 + +item.montantht13fodec+ +item.montantht13tpe))*100)}`)[0].cptetva>''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva13/(+item.montantht13 + +item.montantht13fodec+ +item.montantht13tpe))*100)}`)[0].cptetva:'46100000':'46100000',
                  sens:'C',
                  montant: +item.montanttva13,                
                })
               }
              if(+item.montantdt>0)
              {
                deccomptab.autre1bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                  date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Facture N° ${item.numeronote} ${item.client}`,
                  reflettrage:`${item.numeronote}`,
                  numeroexterne:`${item.numeronote}`,
              //@ts-ignore
              compte:user.paramcomptable[0]&&user.paramcomptable[0].cptetimbrefiscalnote>''?user.paramcomptable[0].cptetimbrefiscalnote:'46100000',
                sens:'C',
                  montant: +item.montantdt,
                })
              }
                if((+item.montantttc)>0)
                {
                  deccomptab.autre1bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle: +item.montanthtexpdevise==0?`Facture N° ${item.numeronote} ${item.client}`:`Facture N° ${item.numeronote} ${item.client} ${item.montanthtexpdevise} ${item.devise}`,
                    reflettrage:`${item.numeronote}`,
                  numeroexterne:`${item.numeronote}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${item.client}`)[0]&&user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${item.client}`)[0].cptettc>''?
                            //@ts-ignore
        user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${item.client}`)[0].cptettc:'46100000':'46100000',
                    sens:'D',
                    montant: (+item.montantttc),
                
                  }) 
                } 
                      }
                       else if(item.typepiece=='avoir')  
                       {
                        if((+item.montantht)>0)
                      {
                        deccomptab.autre1bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                          reflettrage:`${item.numeronote}`,
                          numeroexterne:`${item.numeronote}`,
                          //@ts-ignore
      compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva- +item.montanttva19)/(+item.montantht))*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cpteht!=''?
                          //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cpteht:'46100000':'46100000',
                          sens:'D',
                          montant: +item.montantht,  
                        })
                      }
                      //set montanthtfodec
                      if((+item.montanthtfodec)>0)
                      {
                        deccomptab.autre1bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                          reflettrage:`${item.numeronote}`,
                          numeroexterne:`${item.numeronote}`,
                          //@ts-ignore
      compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva- +item.montanttva19)/(+item.montantht))*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehtfodec!=''?
                          //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehtfodec:'46100000':'46100000',
                          sens:'C',
                          montant: +item.montanthtfodec,  
                        })
                      }
                              //set montanthttpe
                              if((+item.montanthttpe)>0)
                              {
                                deccomptab.autre1bis.push({
                                  code:user.clientcode,
                                  //@ts-ignore
                                  journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                                  date: item.date,
                                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                                  numeropiece:item.numeropiece,
                                  libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                                  reflettrage:`${item.numeronote}`,
                                  numeroexterne:`${item.numeronote}`,
                                  //@ts-ignore
              compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva- +item.montanttva19)/(+item.montantht))*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehttpe!=''?
                                  //@ts-ignore
              user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19)/(+item.montantht))*100))}`)[0].cptehttpe:'46100000':'46100000',
                                  sens:'C',
                                  montant: +item.montanthttpe,  
                                })
                              }
                         //set montantht19
              if(+item.montantht19>0)
              {
                deccomptab.autre1bis.push({
                  code:user.clientcode,
                  //@ts-ignore
                  journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                  date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                  reflettrage:`${item.numeronote}`,
                  numeroexterne:`${item.numeronote}`,
                  //@ts-ignore
      compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cpteht>''?
                  //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cpteht:'46100000':'46100000',
                  sens:'D',
                  montant: +item.montantht19,  
                })
              }
                           //set montantht19fodec
                           if(+item.montantht19fodec>0)
                           {
                             deccomptab.autre1bis.push({
                               code:user.clientcode,
                               //@ts-ignore
                               journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                               date: item.date,
                               prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                               numeropiece:item.numeropiece,
                               libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                               reflettrage:`${item.numeronote}`,
                               numeroexterne:`${item.numeronote}`,
                               //@ts-ignore
                 compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehtfodec>''?
                               //@ts-ignore
                 user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehtfodec:'46100000':'46100000',
                               sens:'C',
                               montant: +item.montantht19fodec,  
                             })
                           }
                                  //set montantht19tpe
                    if(+item.montantht19tpe>0)
                    {
                      deccomptab.autre1bis.push({
                        code:user.clientcode,
                        //@ts-ignore
                        journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                        date: item.date,
                        prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                        numeropiece:item.numeropiece,
                        libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                        reflettrage:`${item.numeronote}`,
                        numeroexterne:`${item.numeronote}`,
                        //@ts-ignore
          compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehttpe>''?
                        //@ts-ignore
          user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva19/item.montantht19)*100))}`)[0].cptehttpe:'46100000':'46100000',
                        sens:'C',
                        montant: +item.montantht19tpe,  
                      })
                    }
                     //set montantht13
                     if(+item.montantht13>0)
                     {
                       deccomptab.autre1bis.push({
                         code:user.clientcode,
                         //@ts-ignore
                         journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                         date: item.date,
                         prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                         numeropiece:item.numeropiece,
                         libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                         reflettrage:`${item.numeronote}`,
                         numeroexterne:`${item.numeronote}`,
                         //@ts-ignore
             compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cpteht>''?
                         //@ts-ignore
             user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cpteht:'46100000':'46100000',
                         sens:'D',
                         montant: +item.montantht13,  
                       })
                     }
                      //set montantht13fodec   
                      if(+item.montantht13fodec>0)
                      {
                        deccomptab.autre1bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                          reflettrage:`${item.numeronote}`,
                          numeroexterne:`${item.numeronote}`,
                          //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehtfodec>''?
                          //@ts-ignore
            user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehtfodec:'46100000':'46100000',
                          sens:'C',
                          montant: +item.montantht13fodec,  
                        })
                      }   
                      if(+item.montantht13tpe>0)
                      {
                        deccomptab.autre1bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                          reflettrage:`${item.numeronote}`,
                          numeroexterne:`${item.numeronote}`,
                          //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehttpe>''?
                          //@ts-ignore
            user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round(((item.montanttva13/item.montantht13)*100))}`)[0].cptehttpe:'46100000':'46100000',
                          sens:'C',
                          montant: +item.montantht13tpe,  
                        })
                      }      
                          //set montanthtexo
                          if(+item.montanthtexo>0)
                          {
                            deccomptab.autre1bis.push({
                              code:user.clientcode,
                              //@ts-ignore
                              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                              date: item.date,
                              prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                              numeropiece:item.numeropiece,
                              libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                              reflettrage:`${item.numeronote}`,
                              numeroexterne:`${item.numeronote}`,
                              //@ts-ignore
              compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cpteht>''?
                              //@ts-ignore
              user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cpteht:'46100000':'46100000',
                              sens:'D',
                              montant: +item.montanthtexo,  
                            })
                          }
                                    //set montanthtexofodec
           if(+item.montanthtexofodec>0)
           {
             deccomptab.autre1bis.push({
               code:user.clientcode,
               //@ts-ignore
               journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
               date: item.date,
               prefixe:`${deccomptab.annee}${deccomptab.mois}`,
               numeropiece:item.numeropiece,
               libelle:`Avoir N° ${item.numeronote} ${item.client}`,
               reflettrage:`${item.numeronote}`,
               numeroexterne:`${item.numeronote}`,
               //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehtfodec>''?
               //@ts-ignore
user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehtfodec:'46100000':'46100000',
               sens:'C',
               montant: +item.montanthtexofodec,  
             })
           }
            //set montanthtexotpe
            if(+item.montanthtexotpe>0)
            {
              deccomptab.autre1bis.push({
                code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
                //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehttpe>''?
                //@ts-ignore
user.paramcomptable[0].comptestvanote.filter(e => e.taux == `exonere`)[0].cptehttpe:'46100000':'46100000',
                sens:'C',
                montant: +item.montanthtexotpe,  
              })
            }
                      //set montanthtsus
                      if(+item.montanthtsus>0)
                              {
                                deccomptab.autre1bis.push({
                                  code:user.clientcode,
                                  //@ts-ignore
                                  journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                                  date: item.date,
                                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                                  numeropiece:item.numeropiece,
                                  libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                                  reflettrage:`${item.numeronote}`,
                                  numeroexterne:`${item.numeronote}`,
                                  //@ts-ignore
              compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cpteht>''?
                                  //@ts-ignore
              user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cpteht:'46100000':'46100000',
                                  sens:'D',
                                  montant: +item.montanthtsus,  
                                })
                              }
                                         //set montanthtsusfodec
                 if(+item.montanthtsusfodec>0)
                 {
                   deccomptab.autre1bis.push({
                     code:user.clientcode,
                     //@ts-ignore
                     journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                     date: item.date,
                     prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                     numeropiece:item.numeropiece,
                     libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                     reflettrage:`${item.numeronote}`,
                     numeroexterne:`${item.numeronote}`,
                     //@ts-ignore
 compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehtfodec>''?
                     //@ts-ignore
 user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehtfodec:'46100000':'46100000',
                     sens:'C',
                     montant: +item.montanthtsusfodec,  
                   })
                 }
                    //set montanthtsustpe
                if(+item.montanthtsustpe>0)
                {
                  deccomptab.autre1bis.push({
                    code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                    date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                    reflettrage:`${item.numeronote}`,
                    numeroexterne:`${item.numeronote}`,
                    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehttpe>''?
                    //@ts-ignore
user.paramcomptable[0].comptestvanote.filter(e => e.taux == `suspension`)[0].cptehttpe:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montanthtsustpe,  
                  })
                }
                          //set montanthtexp
                          if(+item.montanthtexp>0)
                          {
                            deccomptab.autre1bis.push({
                              code:user.clientcode,
                              //@ts-ignore
                              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                              date: item.date,
                              prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                              numeropiece:item.numeropiece,
                              libelle: +item.montanthtexpdevise==0?`Avoir N° ${item.numeronote} ${item.client}`:`Avoir N° ${item.numeronote} ${item.client} ${item.montanthtexpdevise} ${item.devise}`,
                              reflettrage:`${item.numeronote}`,
                              numeroexterne:`${item.numeronote}`,
                              //@ts-ignore
              compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `export`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `export`)[0].cpteht>''?
                              //@ts-ignore
              user.paramcomptable[0].comptestvanote.filter(e => e.taux == `export`)[0].cpteht:'46100000':'46100000',
                              sens:'D',
                              montant: +item.montanthtexp,  
                            })
                          }
             if((+item.montanttva - +item.montanttva19- +item.montanttva13)>0)
             {
              deccomptab.autre1bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
            date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
                //@ts-ignore
                compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19- +item.montanttva13))/(+item.montantht + +item.montanthtfodec+ +item.montanthttpe))*100)}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19- +item.montanttva13))/(+item.montantht + +item.montanthtfodec+ +item.montanthttpe))*100)}`)[0].cptetva!=''?
                          //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((((+item.montanttva - +item.montanttva19- +item.montanttva13))/(+item.montantht + +item.montanthtfodec+ +item.montanthttpe))*100)}`)[0].cptetva:'46100000':'46100000',
                sens:'D',
                montant: (+item.montanttva - +item.montanttva19 - +item.montanttva13),                
              })
             }
                  
             if(+item.montanttva19>0)
             {
              deccomptab.autre1bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
            date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
                //@ts-ignore
                compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva19/(+item.montantht19 + +item.montantht19fodec+ +item.montantht19tpe))*100)}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva19/(+item.montantht19 + +item.montantht19fodec+ +item.montantht19tpe))*100)}`)[0].cptetva>''?
                          //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva19/(+item.montantht19 + +item.montantht19fodec+ +item.montantht19tpe))*100)}`)[0].cptetva:'46100000':'46100000',
                sens:'D',
                montant: +item.montanttva19,                
              })
             }

             if(+item.montanttva13>0)
             {
              deccomptab.autre1bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
            date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
                //@ts-ignore
                compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva13/(+item.montantht13 + +item.montantht13fodec+ +item.montantht13tpe))*100)}`)[0]&&user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva13/(+item.montantht13 + +item.montantht13fodec+ +item.montantht13tpe))*100)}`)[0].cptetva>''?
                          //@ts-ignore
      user.paramcomptable[0].comptestvanote.filter(e => e.taux == `${Math.round((item.montanttva13/(+item.montantht13 + +item.montantht13fodec+ +item.montantht13tpe))*100)}`)[0].cptetva:'46100000':'46100000',
                sens:'D',
                montant: +item.montanttva13,                
              })
             }
            if(+item.montantdt>0)
            {
              deccomptab.autre1bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote>''?user.paramcomptable[0].journalnote:'',
                date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`Avoir N° ${item.numeronote} ${item.client}`,
                reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
            //@ts-ignore
            compte:user.paramcomptable[0]&&user.paramcomptable[0].cptetimbrefiscalnote>''?user.paramcomptable[0].cptetimbrefiscalnote:'46100000',
              sens:'D',
                montant: +item.montantdt,
              })
            }
              if((+item.montantttc)>0)
              {
                deccomptab.autre1bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle: +item.montanthtexpdevise==0?`Avoir N° ${item.numeronote} ${item.client}`:`Avoir N° ${item.numeronote} ${item.client} ${item.montanthtexpdevise} ${item.devise}`,
                  reflettrage:`${item.numeronote}`,
                numeroexterne:`${item.numeronote}`,
                  //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${item.client}`)[0]&&user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${item.client}`)[0].cptettc!=''?
                          //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${item.client}`)[0].cptettc:'46100000':'46100000',
                  sens:'C',
                  montant: (+item.montantttc),
              
                }) 
              }   
      
      
                       }   
                    } 
                  }
                if(this.autre2.length>0)
                  {
                    for (let i = 0; i < this.autre2.length; i+=1)
                    {
                      const item = this.autre2[i];
                      if((+item.montantht - +item.montantht19  - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus)>0)
                      {
                        deccomptab.autre2bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                          reflettrage:'',
                          numeroexterne:'',
                           //@ts-ignore
                           compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/+(item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/+(item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0].cpteht!=''?
                           //@ts-ignore
       user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0].cpteht:'46100000':'46100000',
                           sens:'C',
                          montant: +item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus,  
                        })
                      }
                      if((+item.montantht13)>0)
                      {
                        deccomptab.autre2bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                          reflettrage:'',
                          numeroexterne:'',
                           //@ts-ignore
                           compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0].cpteht!=''?
                           //@ts-ignore
       user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0].cpteht:'46100000':'46100000',
                           sens:'C',
                          montant: +item.montantht13,  
                        })
                      }
             if((+item.montanttva- +item.montanttva19)>0)
             {
              deccomptab.autre2bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
            date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
            reflettrage:'',
            numeroexterne:'',
            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0].cptetva!=''?
            //@ts-ignore
      user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0].cptetva:'46100000':'46100000',
            sens:'C',
                montant: (+item.montanttva - +item.montanttva19),                
              })
             }
             if((+item.montanttva13)>0)
             {
              deccomptab.autre2bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
            date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
            reflettrage:'',
            numeroexterne:'',
            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0].cptetva!=''?
            //@ts-ignore
      user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0].cptetva:'46100000':'46100000',
            sens:'C',
                montant: (+item.montanttva13),                
              })
             }
             if(+item.montantdt!=0)
             {
              deccomptab.autre2bis.push({
                code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                date: item.date,
                prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                numeropiece:item.numeropiece,
                libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
            reflettrage:'',
            numeroexterne:'',
             //@ts-ignore
             compte:user.paramcomptable[0]&&user.paramcomptable[0].cptetimbrefiscalrecette!=''?user.paramcomptable[0].cptetimbrefiscalrecette:'46100000',
             sens:'D',
                montant: +item.montantdt,
              })
             }
              if((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)>0)
              {
                deccomptab.autre2bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                  reflettrage:'',
                  numeroexterne:'',
                  //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus) /(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0].cptettc!=''?
                  //@ts-ignore
            user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round((((+item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus)/(+item.montantht - +item.montantht19 - +item.montanthtexo- +item.montanthtexp- +item.montanthtsus))-1)*100)}`)[0].cptettc:'46100000':'46100000',
                        sens:'D',
                  montant: +item.montantttc - +item.montantttc19 - +item.montantttcexo- +item.montantttcexp- +item.montantttcsus,
              
                }) 
              }
              if((+item.montantttc13)>0)
              {
                deccomptab.autre2bis.push({
                  code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
              date: item.date,
                  prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                  numeropiece:item.numeropiece,
                  libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                  reflettrage:'',
                  numeroexterne:'',
                  //@ts-ignore
                  compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0].cptettc!=''?
                  //@ts-ignore
            user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `13`)[0].cptettc:'46100000':'46100000',
                        sens:'D',
                  montant: +item.montantttc13,
              
                }) 
              }
                  //set montantht19
                  if(+item.montantht19!=0)
                  {
                    deccomptab.autre2bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                          date: item.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          numeropiece:item.numeropiece,
                          libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                          reflettrage:'',
                          numeroexterne:'',
                           //@ts-ignore
                           compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0].cpteht!=''?
                           //@ts-ignore
       user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0].cpteht:'46100000':'46100000',
                           sens:'C',
                          montant: +item.montantht19,  
                    })
                  }
                  if(+item.montanttva19!=0)
                  {
                   deccomptab.autre2bis.push({
                     code:user.clientcode,
                 //@ts-ignore
                 journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                 date: item.date,
                     prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                     numeropiece:item.numeropiece,
                     libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                 reflettrage:'',
                 numeroexterne:'',
                 //@ts-ignore
                 compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0].cptetva!=''?
                 //@ts-ignore
           user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0].cptetva:'46100000':'46100000',
                 sens:'C',
                     montant: +item.montanttva19,                
                   })
                  }
              
                   if(+item.montantttc19!=0)
                   {
                     deccomptab.autre2bis.push({
                       code:user.clientcode,
                   //@ts-ignore
                   journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                   date: item.date,
                       prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                       numeropiece:item.numeropiece,
                       libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                       reflettrage:'',
                       numeroexterne:'',
                       //@ts-ignore
                       compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0].cptettc!=''?
                       //@ts-ignore
                 user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `${Math.round(((item.montantttc19/item.montantht19)-1)*100)}`)[0].cptettc:'46100000':'46100000',
                             sens:'D',
                       montant: +item.montantttc19,
                   
                     }) 
                   }
                   //set montanthtexo
                   if(+item.montanthtexo!=0)
                   {
                     deccomptab.autre2bis.push({
                           code:user.clientcode,
                           //@ts-ignore
                           journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                           date: item.date,
                           prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                           numeropiece:item.numeropiece,
                           libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                           reflettrage:'',
                           numeroexterne:'',
                            //@ts-ignore
                            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `exonere`)[0].cpteht!=''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `exonere`)[0].cpteht:'46100000':'46100000',
                            sens:'C',
                           montant: +item.montanthtexo,  
                     })
                   }
                    if(+item.montantttcexo!=0)
                    {
                      deccomptab.autre2bis.push({
                        code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                    date: item.date,
                        prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                        numeropiece:item.numeropiece,
                        libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                        reflettrage:'',
                        numeroexterne:'',
                        //@ts-ignore
                        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `exonere`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `exonere`)[0].cptettc!=''?
                        //@ts-ignore
                  user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `exonere`)[0].cptettc:'46100000':'46100000',
                              sens:'D',
                        montant: +item.montantttcexo,
                    
                      }) 
                    }   
                              //set montanthtsus
                   if(+item.montanthtsus!=0)
                   {
                     deccomptab.autre2bis.push({
                           code:user.clientcode,
                           //@ts-ignore
                           journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                           date: item.date,
                           prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                           numeropiece:item.numeropiece,
                           libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                           reflettrage:'',
                           numeroexterne:'',
                            //@ts-ignore
                            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `suspension`)[0].cpteht!=''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `suspension`)[0].cpteht:'46100000':'46100000',
                            sens:'C',
                           montant: +item.montanthtsus,  
                     })
                   }
                    if(+item.montantttcsus!=0)
                    {
                      deccomptab.autre2bis.push({
                        code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                    date: item.date,
                        prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                        numeropiece:item.numeropiece,
                        libelle:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`,
                        reflettrage:'',
                        numeroexterne:'',
                        //@ts-ignore
                        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `suspension`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `suspension`)[0].cptettc!=''?
                        //@ts-ignore
                  user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `suspension`)[0].cptettc:'46100000':'46100000',
                              sens:'D',
                        montant: +item.montantttcsus,
                    
                      }) 
                    } 
                              //set montanthtexp
                   if(+item.montanthtexp!=0)
                   {
                     deccomptab.autre2bis.push({
                           code:user.clientcode,
                           //@ts-ignore
                           journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                           date: item.date,
                           prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                           numeropiece:item.numeropiece,
                           libelle:+item.recetteexpdevise==0?`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee} ${item.recetteexpdevise} ${item.devise}`,
                           reflettrage:'',
                           numeroexterne:'',
                            //@ts-ignore
                            compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `export`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `export`)[0].cpteht!=''?
                            //@ts-ignore
        user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `export`)[0].cpteht:'46100000':'46100000',
                            sens:'C',
                           montant: +item.montanthtexp,  
                     })
                   }
                    if(+item.montantttcexp!=0)
                    {
                      deccomptab.autre2bis.push({
                        code:user.clientcode,
                    //@ts-ignore
                    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalrecette!=''?user.paramcomptable[0].journalrecette:'',
                    date: item.date,
                        prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                        numeropiece:item.numeropiece,
                        libelle:+item.recetteexpdevise==0?`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee}`:`recette du ${item.date.split('/')[0]}/${deccomptab.mois}/${deccomptab.annee} ${item.recetteexpdevise} ${item.devise}`,
                        reflettrage:'',
                        numeroexterne:'',
                        //@ts-ignore
                        compte:user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `export`)[0]&&user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `export`)[0].cptettc!=''?
                        //@ts-ignore
                  user.paramcomptable[0].comptestvarecette.filter(e => e.taux == `export`)[0].cptettc:'46100000':'46100000',
                              sens:'D',
                        montant: +item.montantttcexp,
                    
                      }) 
                    } 
                    } 
                  }
                if (this.autre4.length>0) 
                {
                  for (let i = 0; i < this.autre4.length; i+=1)
                  {
                    const item = this.autre4[i];
                    item.mouvements.forEach((element, _index) => {
                      //@ts-ignore
                      if(element.debit!=null)
                      {
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
                          libelle:item.devise=='tnd'?
                                                  //@ts-ignore
  
                          element.objetdebit!='impayé client'?
                                                  //@ts-ignore
  `${element.designation} ${element.fournisseur}`:`${element.designation} ${element.client}`:element.objetdebit!='impayé client'?`${element.designation} ${element.fournisseur} ${element.debitdevise} ${item.devise}`:
                              //@ts-ignore                   
  `${element.designation} ${element.client} ${element.debitdevise} ${item.devise}`
                          ,
  
                          reflettrage:'',
                          numeroexterne:'',
      //@ts-ignore
      compte:user.paramcomptable[0]?
                          //@ts-ignore
      element.contientauxiliaire!=true?
                          //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetdebit}`||
                          //@ts-ignore
      e.objet == `${element.objetcredit}`)[0]&&user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet 
                            //@ts-ignore
      == `${element.objetdebit}`||e.objet == `${element.objetcredit}`)[0].cpte!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetcredit}`||
                          //@ts-ignore
      e.objet == `${element.objetdebit}`)[0].cpte
      :'46100000'
     //@ts-ignore
      :element.objetdebit!="impayé client"?
                          //@ts-ignore
      user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${element.fournisseur}`)[0]&&
                          //@ts-ignore
      user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${element.fournisseur}`)[0].cptettc!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${element.fournisseur}`)[0].cptettc
      :'46100000'
      : 
      //@ts-ignore
     user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0]&&
                           //@ts-ignore
       user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc
      :'46100000'
      :'46100000',
                                sens:'D',
                          //@ts-ignore
                          montant: +element.debit,              
                        })
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
  libelle:item.devise=='tnd'?element.objetdebit!='impayé client'?`${element.designation} ${element.fournisseur}`:`${element.designation} ${element.client}`:element.objetdebit!='impayé client'?
                          //@ts-ignore                        
  `${element.designation} ${element.fournisseur} ${element.debitdevise} ${item.devise}`:`${element.designation} ${element.client} ${element.debitdevise} ${item.devise}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                          compte:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte:'46100000':'46100000',
                                sens:'C',
                          //@ts-ignore
                          montant: +element.debit,              
                        })  
                      }
                          //@ts-ignore
                      else if(element.credit!=null)
                      {
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
                          libelle:item.devise=='tnd'?`${element.designation} ${element.client}`:`${element.designation} ${element.client} ${element.creditdevise} ${item.devise}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
      compte:
      user.paramcomptable[0]?
                          //@ts-ignore
      element.contientauxiliaire!=true?
                          //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetdebit}`||
                          //@ts-ignore
      e.objet == `${element.objetcredit}`)[0]&&user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet 
                            //@ts-ignore
      == `${element.objetdebit}`||e.objet == `${element.objetcredit}`)[0].cpte!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetcredit}`||
                          //@ts-ignore
      e.objet == `${element.objetdebit}`)[0].cpte
      :'46100000'
                          //@ts-ignore
      :user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0]&&
                          //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc
      :'46100000'
      
      :'46100000',
                                sens:'C',
                          //@ts-ignore
                          montant: +element.credit,              
                        })
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                      //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
                          libelle:item.devise=='tnd'?`${element.designation} ${element.client}`:`${element.designation} ${element.client} ${element.creditdevise} ${item.devise}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                          compte:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte:'46100000':'46100000',
                                sens:'D',
                          //@ts-ignore
                          montant: +element.credit,              
                        })  
                      }
                    }
                    )              
                  }
                }
                if (this.autre6.length>0) 
                {
                
                }
                deccomptab.autre6bis.push({
                  code:'####',
                  //@ts-ignore
                  journal:'',
                  date: '',
                  prefixe:'',
                  numeropiece:'',
                  libelle:'',
                  reflettrage:'',
                  numeroexterne:'',
                   //@ts-ignore
                   compte:'',
                   sens:'',
                                    //@ts-ignore
                  montant: '',  
                })
                  this.deccompt.completedeccomptabilitereqById(deccomptab._id,deccomptab).then(
                      (_result:any) => {
                        this.loading = false;
                        const integ=this.integ.concat(deccomptab.autre1bis,deccomptab.autre2bis,deccomptab.autre3bis,deccomptab.autre4bis,deccomptab.autre5bis,deccomptab.autre6bis)
                        this.excelService.exportAsExcelFile(integ,this.deccomptabilite.autre1,this.deccomptabilite.autre2,this.deccomptabilite.autre3,
                  this.deccomptabilite.autre4,this.deccomptabilite.autre5,this.deccomptabilite.autre6,`Maquette comptable_${this.deccomptabilite.mois}_${this.deccomptabilite.annee}`)
                      },
                      (_error) => {
                        this.loading = false;
                      }
                  )  
              }
              else if(user.activite=='Syndic des copropriétaires')
              {
                this.autre0=deccomptab.autre0
                this.autre1=deccomptab.autre1
                this.autre2=deccomptab.autre2
                this.autre3=deccomptab.autre3
                this.autre4=deccomptab.autre4
                this.autre5=deccomptab.autre5
                this.autre6=deccomptab.autre6
                this.autre8=deccomptab.autre8
                this.autre9=deccomptab.autre9
                this.autre11=deccomptab.autre11

                deccomptab.autrecopbis=[]
                deccomptab.autre0bis=[]
                deccomptab.autre2bis=[]
                deccomptab.autre1bis=[]
                deccomptab.autre3bis=[]
                deccomptab.autre4bis=[]
                deccomptab.autre5bis=[]
                deccomptab.autre6bis=[]
                deccomptab.autre8bis=[]
                deccomptab.autre9bis=[]
                deccomptab.autre11bis=[]

                if (user.coproprietaires.length>0) 
                {
                  let totalmontant=[]
                  let date=new Date(`${deccomptab.mois}.03.${deccomptab.annee}`)
                  let date1=new Date(`01.02.${deccomptab.annee}`)
let date2=new Date(`02.01.${deccomptab.annee}`)
let date3=new Date(`03.01.${deccomptab.annee}`)
let date4=new Date(`04.01.${deccomptab.annee}`)
let date5=new Date(`05.01.${deccomptab.annee}`)
let date6=new Date(`06.01.${deccomptab.annee}`)
let date7=new Date(`07.01.${deccomptab.annee}`)
let date8=new Date(`08.01.${deccomptab.annee}`)
let date9=new Date(`09.01.${deccomptab.annee}`)
let date10=new Date(`10.01.${deccomptab.annee}`)
let date11=new Date(`11.01.${deccomptab.annee}`)
let date12=new Date(`12.01.${deccomptab.annee}`)
/*!this.chosendate?(moisactuel=date.getMonth()+1,day=date.getDate(),year=date.getFullYear()):
(moisactuel=new Date(this.chosendate).getMonth()+1,day= new Date(this.chosendate).getDate(),year= new Date(this.chosendate).getFullYear())*/
                  
                  for (let i = 0; i < user.coproprietaires.length; i+=1)
                  {
                    const item = user.coproprietaires[i];
                    if(item.donneescomptable[0].annee)
                      {
  let desireddonnescomptable=item.donneescomptable.find(e=>e.annee==`${deccomptab.annee}`)

    totalmontant.push(desireddonnescomptable.montant)
              
                        if(desireddonnescomptable.periodicite=='Mensuelle'&&desireddonnescomptable)
                        {
                                  
  deccomptab.autrecopbis.push({
    code:user.clientcode,
    //@ts-ignore
    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
    date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
    prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
    numeropiece:'1',
    libelle:`cotisation du mois ${deccomptab.mois} de l\'année ${desireddonnescomptable.annee} ${item.numero}`,
    reflettrage:'',
    numeroexterne:'',
    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte!=''?
      //@ts-ignore                
user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte
:'46100000'
:'46100000',
    sens:'D',
    montant: +desireddonnescomptable.montant,  
  })
  if(i==user.coproprietaires.length-1)
  {
    deccomptab.autrecopbis.push({
      code:user.clientcode,
      //@ts-ignore
      journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
      date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
      prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
      numeropiece:'1',
      libelle:`total des cotisations du mois ${deccomptab.mois} de l\'année ${desireddonnescomptable.annee} `,
      reflettrage:'',
      numeroexterne:'',
      //@ts-ignore
compte:user.paramcomptable[0]&&user.paramcomptable[0].totalnote!=''?user.paramcomptable[0].totalnote:'',
      sens:'C',
      montant: +(totalmontant).reduce((acc,curr)=>{
        //@ts-ignore
  acc += +(curr || 0);
  return acc;
},0),   
    })
  }
                      }       
                      else  if(desireddonnescomptable.periodicite=='Bimensuelle'&&desireddonnescomptable)
                      {
        if(deccomptab.mois=='01'||deccomptab.mois=='03'||deccomptab.mois=='05'||deccomptab.mois=='07'||deccomptab.mois=='09'||deccomptab.mois=='11')              
                     
        {
          deccomptab.autrecopbis.push({
            code:user.clientcode,
            //@ts-ignore
            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
            date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
            prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
            numeropiece:'1',
            libelle:`cotisation des mois ${+deccomptab.mois}/${+deccomptab.mois+1} de l\'année ${desireddonnescomptable.annee} ${item.numero}`,
            reflettrage:'',
            numeroexterne:'',
            //@ts-ignore
        compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte!=''?
              //@ts-ignore                
        user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte
        :'46100000'
        :'46100000',
            sens:'D',
            montant: +desireddonnescomptable.montant,  
          })
          if(i==user.coproprietaires.length-1)
          {
            deccomptab.autrecopbis.push({
              code:user.clientcode,
              //@ts-ignore
              journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
              date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
              prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
              numeropiece:'1',
              libelle:`total des cotisations des mois ${+deccomptab.mois}/${+deccomptab.mois+1} de l\'année ${desireddonnescomptable.annee} `,
              reflettrage:'',
              numeroexterne:'',
              //@ts-ignore
        compte:user.paramcomptable[0]&&user.paramcomptable[0].totalnote!=''?user.paramcomptable[0].totalnote:'',
              sens:'C',
              montant: +(totalmontant).reduce((acc,curr)=>{
                //@ts-ignore
          acc += +(curr || 0);
          return acc;
        },0),   
            })
          }
        } 
       
                    }   
                    else  if(desireddonnescomptable.periodicite=='Trimestrielle'&&desireddonnescomptable)
                    {
                  
                      if(deccomptab.mois=='01'||deccomptab.mois=='04'||deccomptab.mois=='07'||deccomptab.mois=='10')              
                     
                      {
                        deccomptab.autrecopbis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
                          date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
                          prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
                          numeropiece:'1',
                          libelle:`cotisation des mois ${+deccomptab.mois}/${+deccomptab.mois+1}/${+deccomptab.mois+2} de l\'année ${desireddonnescomptable.annee} ${item.numero}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                      compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte!=''?
                            //@ts-ignore                
                      user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte
                      :'46100000'
                      :'46100000',
                          sens:'D',
                          montant: +desireddonnescomptable.montant,  
                        })
                        if(i==user.coproprietaires.length-1)
                        {
                          deccomptab.autrecopbis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
                            date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
                            prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
                            numeropiece:'1',
                            libelle:`total des cotisations des mois ${+deccomptab.mois}/${+deccomptab.mois+1}/${+deccomptab.mois+2} de l\'année ${desireddonnescomptable.annee} `,
                            reflettrage:'',
                            numeroexterne:'',
                            //@ts-ignore
                      compte:user.paramcomptable[0]&&user.paramcomptable[0].totalnote!=''?user.paramcomptable[0].totalnote:'',
                            sens:'C',
                            montant: +(totalmontant).reduce((acc,curr)=>{
                              //@ts-ignore
                        acc += +(curr || 0);
                        return acc;
                      },0),   
                          })
                        }
                      }        
                  }   
                  else  if(desireddonnescomptable.periodicite=='Semestrielle'&&desireddonnescomptable)
                    {
                     
                      if(deccomptab.mois=='01'||deccomptab.mois=='07')              
                     
                      {
                        deccomptab.autrecopbis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
                          date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
                          prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
                          numeropiece:'1',
                          libelle:`cotisation des mois ${+deccomptab.mois}/${+deccomptab.mois+1}/${+deccomptab.mois+2}/${+deccomptab.mois+3}/${+deccomptab.mois+4}/${+deccomptab.mois+5} de l\'année ${desireddonnescomptable.annee} ${item.numero}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                      compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte!=''?
                            //@ts-ignore                
                      user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte
                      :'46100000'
                      :'46100000',
                          sens:'D',
                          montant: +desireddonnescomptable.montant,  
                        })
                        if(i==user.coproprietaires.length-1)
                        {
                          deccomptab.autrecopbis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
                            date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
                            prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
                            numeropiece:'1',
                            libelle:`total des cotisations des mois  ${+deccomptab.mois}/${+deccomptab.mois+1}/${+deccomptab.mois+2}/${+deccomptab.mois+3}/${+deccomptab.mois+4}/${+deccomptab.mois+5}  de l\'année ${desireddonnescomptable.annee} `,
                            reflettrage:'',
                            numeroexterne:'',
                            //@ts-ignore
                      compte:user.paramcomptable[0]&&user.paramcomptable[0].totalnote!=''?user.paramcomptable[0].totalnote:'',
                            sens:'C',
                            montant: +(totalmontant).reduce((acc,curr)=>{
                              //@ts-ignore
                        acc += +(curr || 0);
                        return acc;
                      },0),   
                          })
                        }
                      }               
                  }   
                  else  if(desireddonnescomptable.periodicite=='Annuelle'&&desireddonnescomptable)
                  {
                  
                    if(deccomptab.mois=='01')              
                     
                    {
                      deccomptab.autrecopbis.push({
                        code:user.clientcode,
                        //@ts-ignore
                        journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
                        date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
                        prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
                        numeropiece:'1',
                        libelle:`cotisation  de l\'année ${desireddonnescomptable.annee} ${item.numero}`,
                        reflettrage:'',
                        numeroexterne:'',
                        //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte!=''?
                          //@ts-ignore                
                    user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.numero}`)[0].cpte
                    :'46100000'
                    :'46100000',
                        sens:'D',
                        montant: +desireddonnescomptable.montant,  
                      })
                      if(i==user.coproprietaires.length-1)
                      {
                        deccomptab.autrecopbis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]&&user.paramcomptable[0].journalcopnote!=''?user.paramcomptable[0].journalcopnote:'',
                          date: deccomptab.mois=='01'? '02'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee:'01'+'/'+deccomptab.mois+'/'+desireddonnescomptable.annee,
                          prefixe:`${desireddonnescomptable.annee}${deccomptab.mois}`,
                          numeropiece:'1',
                          libelle:`total des cotisations   de l\'année ${desireddonnescomptable.annee} `,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                    compte:user.paramcomptable[0]&&user.paramcomptable[0].totalnote!=''?user.paramcomptable[0].totalnote:'',
                          sens:'C',
                          montant: +(totalmontant).reduce((acc,curr)=>{
                            //@ts-ignore
                      acc += +(curr || 0);
                      return acc;
                    },0),   
                        })
                      }
                    }  
                }   
                  } 
              
                }
                

               if (this.autre9.length>0) 
                {
                  for (let i = 0; i < this.autre9.length; i+=1)
                  {
                    const item = this.autre9[i];
                    if(item.typepiece=='facture')
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre9bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Facture N° ${item.numerofacture} ${item.fournisseur}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          
                  deccomptab.autre9bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Facture N° ${item.numerofacture} ${item.fournisseur}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0]&&user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht,
                
                  }) 
                        }
             
                
                      }
                      else if(item.typepiece=='avoir')  
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre9bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          
                  deccomptab.autre9bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]&&user.paramcomptable[0].journalachat!=''?user.paramcomptable[0].journalachat:'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`Avoir N° ${item.numerofacture} ${item.fournisseur}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0]&&user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht,
                
                  }) 
                        }
                      }
                  
                  } 
                }
      
                if (this.autre8.length>0) 
                {
                  for (let i = 0; i < this.autre8.length; i+=1)
                  {
                    const item = this.autre8[i];
                    if(item.typepiece=='facture')
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre8bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal!=''?
                            //@ts-ignore
                            user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal:'':'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`${item.reglement} ${item.numerofacture} ${item.fournisseur} ${item.numerocheque}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                           //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0]&&user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          
                  deccomptab.autre8bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal!=''?
                //@ts-ignore
                user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal:'':'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`${item.reglement} ${item.numerofacture} ${item.fournisseur} ${item.numerocheque}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].cpte:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht,
                
                  }) 
                        }
             
                
                      }
                      else if(item.typepiece=='recu')  
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre8bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal!=''?
                //@ts-ignore
                user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal:'':'',
                date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`${item.reglement} ${item.natureachat}`,
                            reflettrage:'',
                            numeroexterne:'',
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          
                  deccomptab.autre8bis.push({
                    code:user.clientcode,
                           //@ts-ignore
                    journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal!=''?
                           //@ts-ignore
                    user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].journal:'':'',
                    date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`${item.reglement} ${item.natureachat}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Chèque')[0].cpte:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht,
                
                  }) 
                        }
                      }
                  
                  } 
                }
                if (this.autre11.length>0) 
                {
                  for (let i = 0; i < this.autre11.length; i+=1)
                  {
                    const item = this.autre11[i];
                    if(item.typepiece=='facture')
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre11bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal!=''?
                            //@ts-ignore
                            user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal:'':'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`${item.reglement} ${item.numerofacture} ${item.fournisseur}`,
                            reflettrage:`${item.numerofacture}`,
                            numeroexterne:`${item.numerofacture}`,
                           //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0]&&user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${item.fournisseur}`)[0].cptettc:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          
                  deccomptab.autre11bis.push({
                    code:user.clientcode,
                //@ts-ignore
                journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal!=''?
                //@ts-ignore
                user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal:'':'',
                date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`${item.reglement} ${item.numerofacture} ${item.fournisseur}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].cpte:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht,
                
                  }) 
                        }
             
                
                      }
                      else if(item.typepiece=='recu')  
                      {
                        if(+item.montantht!=0)
                        {
                          deccomptab.autre11bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal!=''?
                //@ts-ignore
                user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal:'':'',
                date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle:`${item.reglement} ${item.natureachat}`,
                            reflettrage:'',
                            numeroexterne:'',
                            //@ts-ignore
            compte:user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0]&&user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht!=''?
                              //@ts-ignore                
            user.paramcomptable[0].compteshtachat.filter(e => e.natureachat == `${item.natureachat}`)[0].cpteht:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          
                  deccomptab.autre11bis.push({
                    code:user.clientcode,
                            //@ts-ignore
                    journal:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal!=''?
                            //@ts-ignore
                    user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].journal:'':'',
                    date: item.date,
                    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                    numeropiece:item.numeropiece,
                    libelle:`${item.reglement} ${item.natureachat}`,
                    reflettrage:`${item.numerofacture}`,
                    numeroexterne:`${item.numerofacture}`,
                    //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0]&&user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesreglementachat.filter(e => e.reglement == 'Espèce')[0].cpte:'46100000':'46100000',
                    sens:'C',
                    montant: +item.montantht,
                
                  }) 
                        }
                      }
                  
                  } 
                }
                if(this.autre1.length>0)
                  {
                    for (let i = 0; i < this.autre1.length; i++)
                    { 
                      const item = this.autre1[i];
                        if((+item.montantht)>0)
                        {
                          deccomptab.autre1bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle: deccomptab.contientnumero==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0]&&user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0].cpte:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          deccomptab.autre1bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle: deccomptab.contientnumero==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.client}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.client}`)[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.client}`)[0].cpte:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montantht,  
                          })
                        }
/*else   if((+item.montantht)>0&&item.objet!='frais syndic')
{
  deccomptab.autre1bis.push({
    code:user.clientcode,
    //@ts-ignore
    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
    date: item.date,
    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
    numeropiece:item.numeropiece,
    libelle: deccomptab.contientnumero==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
    reflettrage:`${item.numeronote}`,
    numeroexterne:`${item.numeronote}`,
    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0]&&user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0].cpte!=''?
//@ts-ignore
user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0].cpte:'46100000':'46100000',
    sens:'C',
    montant: +item.montantht,  
  })
  deccomptab.autre1bis.push({
    code:user.clientcode,
    //@ts-ignore
    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
    date: item.date,
    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
    numeropiece:item.numeropiece,
    libelle: deccomptab.contientnumero==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
    reflettrage:`${item.numeronote}`,
    numeroexterne:`${item.numeronote}`,
    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptesobjetnote.filter(e => e.objet == `${item.objet}`)[0]&&user.paramcomptable[0].comptesobjetnote.filter(e => e.objet == `${item.objet}`)[0].cpte!=''?
//@ts-ignore
user.paramcomptable[0].comptesobjetnote.filter(e => e.objet == `${item.objet}`)[0].cpte:'46100000':'46100000',
    sens:'D',
    montant: +item.montantht,  
  })
}*/
                      
  
                    } 
                  }
                  if(this.autre0.length>0)
                  {
                    for (let i = 0; i < this.autre0.length; i++)
                    { 
                      const item = this.autre0[i];
                        if((+item.montantht)>0)
                        {
                          deccomptab.autre0bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnotenon!=''?user.paramcomptable[0].journalnotenon:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle: deccomptab.contientnumeroother==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptesnaturenotenon.filter(e => e.nature == `${item.nature}`)[0]&&user.paramcomptable[0].comptesnaturenotenon.filter(e => e.nature == `${item.nature}`)[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptesnaturenotenon.filter(e => e.nature == `${item.nature}`)[0].cpte:'46100000':'46100000',
                            sens:'D',
                            montant: +item.montantht,  
                          })
                          deccomptab.autre0bis.push({
                            code:user.clientcode,
                            //@ts-ignore
                            journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnotenon!=''?user.paramcomptable[0].journalnotenon:'',
                            date: item.date,
                            prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                            numeropiece:item.numeropiece,
                            libelle: deccomptab.contientnumeroother==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
                            reflettrage:`${item.numeronote}`,
                            numeroexterne:`${item.numeronote}`,
                            //@ts-ignore
                    compte:user.paramcomptable[0]?user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.client}`)[0]&&user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.client}`)[0].cpte!=''?
                    //@ts-ignore
                    user.paramcomptable[0].comptescoproprietaire.filter(e => e.coproprietaire == `${item.client}`)[0].cpte:'46100000':'46100000',
                            sens:'C',
                            montant: +item.montantht,  
                          })
                        }
/*else   if((+item.montantht)>0&&item.objet!='frais syndic')
{
  deccomptab.autre0bis.push({
    code:user.clientcode,
    //@ts-ignore
    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
    date: item.date,
    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
    numeropiece:item.numeropiece,
    libelle: deccomptab.contientnumeroother==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
    reflettrage:`${item.numeronote}`,
    numeroexterne:`${item.numeronote}`,
    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0]&&user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0].cpte!=''?
//@ts-ignore
user.paramcomptable[0].comptesnaturenote.filter(e => e.nature == `${item.nature}`)[0].cpte:'46100000':'46100000',
    sens:'C',
    montant: +item.montantht,  
  })
  deccomptab.autre0bis.push({
    code:user.clientcode,
    //@ts-ignore
    journal:user.paramcomptable[0]&&user.paramcomptable[0].journalnote!=''?user.paramcomptable[0].journalnote:'',
    date: item.date,
    prefixe:`${deccomptab.annee}${deccomptab.mois}`,
    numeropiece:item.numeropiece,
    libelle: deccomptab.contientnumeroother==true? `Quittance N° ${item.numeronote} ${item.client}/${item.objet}/${item.nature}`:`${item.client}/${item.objet}/${item.nature}`,
    reflettrage:`${item.numeronote}`,
    numeroexterne:`${item.numeronote}`,
    //@ts-ignore
compte:user.paramcomptable[0]?user.paramcomptable[0].comptesobjetnote.filter(e => e.objet == `${item.objet}`)[0]&&user.paramcomptable[0].comptesobjetnote.filter(e => e.objet == `${item.objet}`)[0].cpte!=''?
//@ts-ignore
user.paramcomptable[0].comptesobjetnote.filter(e => e.objet == `${item.objet}`)[0].cpte:'46100000':'46100000',
    sens:'D',
    montant: +item.montantht,  
  })
}*/
                      
  
                    } 
                  }
               
                if (this.autre4.length>0) 
                {
                  for (let i = 0; i < this.autre4.length; i+=1)
                  {
                    const item = this.autre4[i];
                    item.mouvements.forEach((element, _index) => {
                      //@ts-ignore
                      if(element.debit!=null)
                      {
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
                          libelle:item.devise=='tnd'?
                                                  //@ts-ignore
  
                          element.objetdebit!='impayé client'?
                                                  //@ts-ignore
  `${element.designation} ${element.fournisseur}`:`${element.designation} ${element.client}`:element.objetdebit!='impayé client'?`${element.designation} ${element.fournisseur} ${element.debitdevise} ${item.devise}`:
                              //@ts-ignore                   
  `${element.designation} ${element.client} ${element.debitdevise} ${item.devise}`
                          ,
  
                          reflettrage:'',
                          numeroexterne:'',
      //@ts-ignore
      compte:user.paramcomptable[0]?
                          //@ts-ignore
      element.contientauxiliaire!=true?
                          //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetdebit}`||
                          //@ts-ignore
      e.objet == `${element.objetcredit}`)[0]&&user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet 
                            //@ts-ignore
      == `${element.objetdebit}`||e.objet == `${element.objetcredit}`)[0].cpte!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetcredit}`||
                          //@ts-ignore
      e.objet == `${element.objetdebit}`)[0].cpte
      :'46100000'
     //@ts-ignore
      :element.objetdebit!="impayé client"?
                          //@ts-ignore
      user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${element.fournisseur}`)[0]&&
                          //@ts-ignore
      user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${element.fournisseur}`)[0].cptettc!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesttcachat.filter(e => e.fournisseur == `${element.fournisseur}`)[0].cptettc
      :'46100000'
      : 
      //@ts-ignore
     user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0]&&
                           //@ts-ignore
       user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc
      :'46100000'
      :'46100000',
                                sens:'D',
                          //@ts-ignore
                          montant: +element.debit,              
                        })
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
  libelle:item.devise=='tnd'?element.objetdebit!='impayé client'?`${element.designation} ${element.fournisseur}`:`${element.designation} ${element.client}`:element.objetdebit!='impayé client'?
                          //@ts-ignore                        
  `${element.designation} ${element.fournisseur} ${element.debitdevise} ${item.devise}`:`${element.designation} ${element.client} ${element.debitdevise} ${item.devise}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                          compte:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte:'46100000':'46100000',
                                sens:'C',
                          //@ts-ignore
                          montant: +element.debit,              
                        })  
                      }
                          //@ts-ignore
                      else if(element.credit!=null)
                      {
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                          //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
                          libelle:item.devise=='tnd'?`${element.designation} ${element.client}`:`${element.designation} ${element.client} ${element.creditdevise} ${item.devise}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
      compte:
      user.paramcomptable[0]?
                          //@ts-ignore
      element.contientauxiliaire!=true?
                          //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetdebit}`||
                          //@ts-ignore
      e.objet == `${element.objetcredit}`)[0]&&user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet 
                            //@ts-ignore
      == `${element.objetdebit}`||e.objet == `${element.objetcredit}`)[0].cpte!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesmanuelobjets.filter(e => e.objet == `${element.objetcredit}`||
                          //@ts-ignore
      e.objet == `${element.objetdebit}`)[0].cpte
      :'46100000'
                          //@ts-ignore
      :user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0]&&
                          //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc!=''?
      //@ts-ignore
      user.paramcomptable[0].comptesttcnote.filter(e => e.client == `${element.client}`)[0].cptettc
      :'46100000'
      
      :'46100000',
                                sens:'C',
                          //@ts-ignore
                          montant: +element.credit,              
                        })
                        deccomptab.autre4bis.push({
                          code:user.clientcode,
                      //@ts-ignore
                          journal:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].journalmanuel:'':'',
                          //@ts-ignore
                          date: element.date,
                          prefixe:`${deccomptab.annee}${deccomptab.mois}`,
                          //@ts-ignore
                          numeropiece:element.numeropiece,
                          //@ts-ignore
                          libelle:item.devise=='tnd'?`${element.designation} ${element.client}`:`${element.designation} ${element.client} ${element.creditdevise} ${item.devise}`,
                          reflettrage:'',
                          numeroexterne:'',
                          //@ts-ignore
                          compte:user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0]&&user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte!=''?
                          //@ts-ignore
                    user.paramcomptable[0].comptesmanuelcompte.filter(e => e.numerocompte == `${item.numerocompte}`)[0].cpte:'46100000':'46100000',
                                sens:'D',
                          //@ts-ignore
                          montant: +element.credit,              
                        })  
                      }
                    }
                    )              
                  }
                }
                if (this.autre6.length>0) 
                {
                
                }
                deccomptab.autrecopbis.push({
                  code:'####',
                  //@ts-ignore
                  journal:'',
                  date: '',
                  prefixe:'',
                  numeropiece:'',
                  libelle:'',
                  reflettrage:'',
                  numeroexterne:'',
                   //@ts-ignore
                   compte:'',
                   sens:'',
                                    //@ts-ignore
                  montant: '',  
                })
                  this.deccompt.completedeccomptabilitereqById(deccomptab._id,deccomptab).then(
                      (_result:any) => {
                        this.loading = false;
                        const integ=this.integ.concat(deccomptab.autre0bis,deccomptab.autre1bis,deccomptab.autre2bis,deccomptab.autre3bis,deccomptab.autre9bis,deccomptab.autre8bis,deccomptab.autre11bis,deccomptab.autre4bis,deccomptab.autre5bis,deccomptab.autre6bis,deccomptab.autrecopbis)
                        this.excelService.exportAsExcelFile(integ,this.deccomptabilite.autre1,this.deccomptabilite.autre2,this.deccomptabilite.autre3,
                  this.deccomptabilite.autre4,this.deccomptabilite.autre5,this.deccomptabilite.autre6,`Maquette comptable_${this.deccomptabilite.mois}_${this.deccomptabilite.annee}`)
                      },
                      (_error) => {
                        this.loading = false;
                      }
                  )  
              }

            }
          }
          )
       
            
        }
      )
                 
                }
                /*else
                {
                  const integ=this.integ.concat(this.deccomptabilite.autre0bis,this.deccomptabilite.autre1bis,this.deccomptabilite.autre2bis,this.deccomptabilite.autre3bis,this.deccomptabilite.autre4bis,this.deccomptabilite.autre5bis,this.deccomptabilite.autre6bis)
                  this.excelService.exportAsExcelFile(integ,this.deccomptabilite.autre1,this.deccomptabilite.autre2,this.deccomptabilite.autre3,
                    this.deccomptabilite.autre4,this.deccomptabilite.autre5,this.deccomptabilite.autre6,`Maquette comptable_${this.deccomptabilite.mois}_${this.deccomptabilite.annee}`)
                }*/

  }
  getNavigation(link, id){
    this.tokenStorage.saved=true  
    this.router.navigate([]).then((_result) => {
      window.open(link + '/' + id, '_blank');
    });;      
  }
  //decide admin function
  async decideadmin()
  {
    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      async (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              this.commun.getcurrenttime().then(
                async (data:any) => {
               //@ts-ignore
               if(deccomptabilite.statutadmin.length>0)
               {
                 //@ts-ignore
                 if(deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='en cours de supervision')
                 { 
                   Swal.fire({
                     title: 'Veuillez choisir entre les alternatives suivantes!',
                     input: 'text',
                     inputLabel: 'motif(facultatif)',
                     inputValue: '',
                     returnInputValueOnDeny: true,
                     icon: 'info',
                     showDenyButton: true,
                     showCancelButton: true,
                     confirmButtonColor: '#3085d6',
                     cancelButtonColor: '#555',
                     confirmButtonText: 'marquer comme supervisé',
                     cancelButtonText: 'Annuler',
                     denyButtonText: 'à rectifier',
                     
                     }).then((result) => {
                      
                     if (result.isConfirmed) {
                       
                      deccomptabilite.statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'supervisé',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                         (_result:any) => {
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           decfiscmens[0].statutadmin.push
                           //@ts-ignore
                           ({
                             statut:'supervisé',
                             motif:result.value,
                             datefin:data,
                             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                           })
                           this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                             (_result:any) => {
                               this.loading = false;
                               this.tokenStorage.saved=true
                               Swal.fire({
                                 position: 'center',
                                 icon: 'success',
                                 title: 'déclaration modifiée avec succès',
                                 showConfirmButton: false,
                                 timer: 3000
                               });
                               this.router.navigate(['admin-board']);
                             },
                             (_error) => {
                               this.loading = false;        
                             }
                           );  
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       );
                             
                     }
                     else if (result.isDenied)
                     {
                       deccomptabilite.statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'à rectifier',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       deccomptabilite.statutcollab.push
                       //@ts-ignore
                       ({
                         statutcoll:'en cours de traitement',
                         motifcoll:'',
                         datefin:data,
                         duree:'',     
                       })
                       this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                         (_result:any) => {
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           decfiscmens[0].statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'à rectifier',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       decfiscmens[0].statutcollab.push
                       //@ts-ignore
                       ({
                         statutcoll:'en cours de traitement',
                         motifcoll:'',
                         datefin:data,
                         duree:'',     
                       })
                       this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                         (_result:any) => {
                          this.tokenStorage.saved=true
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           this.router.navigate(['admin-board']);
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       );  
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       );
                       
                     }
                     
                     }).catch(() => {
                     Swal.fire('opération non aboutie!');
                     });
                 }
                 //@ts-ignore
                 if(deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='en cours de validation')
                 { 
                   await Swal.fire({
                     title: 'Veuillez choisir entre les alternatives suivantes!',
                     input: 'text',
                     inputLabel: 'motif(facultatif)',
                     inputValue: '',
                     returnInputValueOnDeny: true,
                     icon: 'info',
                     showDenyButton: true,
                     showCancelButton: true,
                     confirmButtonColor: '#3085d6',
                     cancelButtonColor: '#555',
                     confirmButtonText: 'marquer comme validé',
                     cancelButtonText: 'Annuler',
                     denyButtonText: 'à rectifier',
                     
                   }).then((result) => {
                     if (result.isConfirmed) 
                     {
                       deccomptabilite.statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'validé',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                         (_result:any) => {
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           decfiscmens[0].statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'validé',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                         (_result:any) => {
                          this.tokenStorage.saved=true
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           this.router.navigate(['admin-board']);
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       );   
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       );
                              
                     }
                     else if (result.isDenied)
                     {
                       deccomptabilite.statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'à rectifier',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       deccomptabilite.statutcollab.push
                       //@ts-ignore
                       ({
                         statutcoll:'en cours de traitement',
                         motifcoll:'',
                         datefin:data,
                         duree:'',     
                       })
                       this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                         (_result:any) => {
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           decfiscmens[0].statutadmin.push
                           //@ts-ignore
                           ({
                             statut:'à rectifier',
                             motif:result.value,
                             datefin:data,
                             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                           })
                           decfiscmens[0].statutcollab.push
                           //@ts-ignore
                           ({
                             statutcoll:'en cours de traitement',
                             motifcoll:'',
                             datefin:data,
                             duree:'',     
                           })
                           this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                             (_result:any) => {
                              this.tokenStorage.saved=true
                               this.loading = false;
                               Swal.fire({
                                 position: 'center',
                                 icon: 'success',
                                 title: 'déclaration modifiée avec succès',
                                 showConfirmButton: false,
                                 timer: 3000
                               });
                               this.router.navigate(['admin-board']);

                             },
                             (_error) => {
                               this.loading = false;
                               
                                 
                             }
                           ); 
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       ); 
                   
                     }
                     
                     }).catch(() => {
                     Swal.fire('opération non aboutie!');
                     });
                 }
                 //@ts-ignore
                 if(deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='en cours de clôture')
                 { 
                   Swal.fire({
                     title: 'Veuillez choisir entre les alternatives suivantes!',
                     
                     input: 'text',
                     inputLabel: 'motif(facultatif)',
                     inputValue: '',
                     returnInputValueOnDeny: true,
                     icon: 'info',
                     showDenyButton: true,
                     showCancelButton: true,
                     confirmButtonColor: '#3085d6',
                     cancelButtonColor: '#555',
                     confirmButtonText: 'marquer comme clôturé',
                     cancelButtonText: 'Annuler',
                     denyButtonText: 'à rectifier',
                     
                     }).then((result) => {
                     if (result.isConfirmed) {
                       deccomptabilite.statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'clôturé',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                         (_result:any) => {
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
    const copiedeccomptabilite:Deccomptabilite = deccomptabilite;
    let indexcloture=[]
    let allstatuts=[]
    //copiedeccomptabilite.statutadmin=[]
   // copiedeccomptabilite.statutcollab=[]
   // copiedeccomptabilite.statutparam=[]
deccomptabilite.statutadmin.forEach(
  (e,index)=>
  {
    //@ts-ignore
e.statut=='clôturé'?indexcloture.push(e.datefin):''
  }
)
indexcloture.length==1?
(copiedeccomptabilite.statutadmin=deccomptabilite.statutadmin,
copiedeccomptabilite.statutcollab=deccomptabilite.statutcollab,
copiedeccomptabilite.statutparam=deccomptabilite.statutparam)
:
//@ts-ignore
(copiedeccomptabilite.statutadmin=deccomptabilite.statutadmin.filter(e=>e.datefin>indexcloture[indexcloture.length-2]),
//@ts-ignore
copiedeccomptabilite.statutcollab=deccomptabilite.statutcollab.filter(e=>e.datefin>indexcloture[indexcloture.length-2]),
//@ts-ignore
copiedeccomptabilite.statutparam=deccomptabilite.statutparam.filter(e=>e.datefin>indexcloture[indexcloture.length-2]))
    copiedeccomptabilite.source='copie comptabilité'
    copiedeccomptabilite.created=new Date(data)
    copiedeccomptabilite._id=null
    allstatuts=allstatuts.concat(copiedeccomptabilite.statutadmin,copiedeccomptabilite.statutcollab,copiedeccomptabilite.statutparam)
    let totaltime = Math.floor(+allstatuts.reduce((acc,curr)=>{
      acc +=  +(curr.duree || 0);
      return acc;
    },0)/60);
    this.prixminuteindiv?
    (copiedeccomptabilite.totalhonoraire= Math.round((Math.floor(+totaltime) * +this.prixminuteindiv) * 1000)/1000,
    copiedeccomptabilite.totaltime=+totaltime,
    copiedeccomptabilite.tauxapplique=this.prixminuteindiv):
      this.prixminutespecial?
      (copiedeccomptabilite.totalhonoraire= Math.round((Math.floor(+totaltime) * +this.prixminutespecial) * 1000)/1000,
    copiedeccomptabilite.totaltime=+totaltime,
    copiedeccomptabilite.tauxapplique=this.prixminutespecial):
    (copiedeccomptabilite.totalhonoraire= Math.round((Math.floor(+totaltime) * +this.prixminute) * 1000)/1000,
    copiedeccomptabilite.totaltime=+totaltime,
    copiedeccomptabilite.tauxapplique=this.prixminute)
                               this.deccompt.createwithoutfile(copiedeccomptabilite).then(
                            (_result:any) => {
                              this.loading = false;
                              decfiscmens[0].statutadmin.push
                              //@ts-ignore
                              ({
                                statut:'clôturé',
                                motif:result.value,
                                datefin:data,
                                duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                              })
                              this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                                (_result:any) => {
                                  this.loading = false;
                                  Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'déclaration modifiée avec succès',
                                    showConfirmButton: false,
                                    timer: 3000
                                  });
                                  const copiedecfiscmens:Decfiscmens = decfiscmens[0];
                                  let indexcloturefisc=[]
                                  let allstatutsfisc=[]
                                 // copiedecfiscmens.statutadmin=[]
                                 // copiedecfiscmens.statutcollab=[]
                                  //copiedecfiscmens.statutparam=[]
                                 // allstatutsfisc=allstatutsfisc.concat(decfiscmens[0].statutadmin,decfiscmens[0].statutcollab,decfiscmens[0].statutparam)
                                  decfiscmens[0].statutadmin.forEach(
                                (e,index)=>
                                {
                                  //@ts-ignore
                              e.statut=='clôturé'?indexcloturefisc.push(e.datefin):''
                                }
                              )
                              indexcloturefisc.length==1?
                              (copiedecfiscmens.statutadmin=decfiscmens[0].statutadmin,
                                copiedecfiscmens.statutcollab=decfiscmens[0].statutcollab,
                                copiedecfiscmens.statutparam=decfiscmens[0].statutparam)
                              :
                              //@ts-ignore
                             (copiedecfiscmens.statutadmin=decfiscmens[0].statutadmin.filter(e=>e.datefin>indexcloturefisc[indexcloturefisc.length-2]),
                             //@ts-ignore
                             copiedecfiscmens.statutcollab=decfiscmens[0].statutcollab.filter(e=>e.datefin>indexcloturefisc[indexcloturefisc.length-2]),
                             //@ts-ignore
                             copiedecfiscmens.statutparam=decfiscmens[0].statutparam.filter(e=>e.datefin>indexcloturefisc[indexcloturefisc.length-2])) 
                                  copiedecfiscmens.source='copie fiscalité'
                                  copiedecfiscmens._id=null
                                  allstatutsfisc=allstatutsfisc.concat(copiedecfiscmens.statutadmin,copiedecfiscmens.statutcollab,copiedecfiscmens.statutparam)
    let totaltimefisc = Math.floor(+allstatutsfisc.reduce((acc,curr)=>{
      acc +=  +(curr.duree || 0);
      return acc;
    },0)/60);
                                  this.prixminuteindiv?
                                  (copiedecfiscmens.totalhonoraire= Math.round((Math.floor(+totaltimefisc) * +this.prixminuteindiv) * 1000)/1000,
                                  copiedecfiscmens.totaltime=totaltimefisc,
                                  copiedecfiscmens.tauxapplique=this.prixminuteindiv):
                                    this.prixminutespecial?
                                    (copiedecfiscmens.totalhonoraire= Math.round((Math.floor(+totaltimefisc) * +this.prixminutespecial) * 1000)/1000,
                                    copiedecfiscmens.totaltime=totaltimefisc,
                                    copiedecfiscmens.tauxapplique=this.prixminutespecial):
                                    (copiedecfiscmens.totalhonoraire= Math.round((Math.floor(+totaltimefisc) * +this.prixminute) * 1000)/1000,
                                    copiedecfiscmens.totaltime=totaltimefisc,
                                    copiedecfiscmens.tauxapplique=this.prixminute)
                                  copiedecfiscmens.created=new Date(data)
           
                                  this.dec.create(copiedecfiscmens).then(
                                   (_result:any) => {
                                    this.tokenStorage.saved=true
                                     this.loading = false;
                                     this.router.navigate(['admin-board']);
                    
                                   },
                                   (_error) => {
                                     this.loading = false;                       
                                   }
                                  )  
                                },
                                (_error) => {
                                  this.loading = false;
                                  
                                    
                                }
                              ); 
                            },
                            (_error) => {
                              this.loading = false;                       
                            }
                           )  
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       );
                      
                   
                     
                     }
                     else if (result.isDenied)
                     {
                       deccomptabilite.statutadmin.push
                       //@ts-ignore
                       ({
                         statut:'à rectifier',
                         motif:result.value,
                         datefin:data,
                         duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                       })
                       deccomptabilite.statutcollab.push
                       //@ts-ignore
                       ({
                         statutcoll:'en cours de traitement',
                         motifcoll:'',
                         datefin:data,
                         duree:'',     
                       })
                       this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                         (_result:any) => {
                           this.loading = false;
                           Swal.fire({
                             position: 'center',
                             icon: 'success',
                             title: 'déclaration modifiée avec succès',
                             showConfirmButton: false,
                             timer: 3000
                           });
                           decfiscmens[0].statutadmin.push
                           //@ts-ignore
                           ({
                             statut:'à rectifier',
                             motif:result.value,
                             datefin:data,
                             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                           })
                           decfiscmens[0].statutcollab.push
                           //@ts-ignore
                           ({
                             statutcoll:'en cours de traitement',
                             motifcoll:'',
                             datefin:data,
                             duree:'',     
                           })
                           this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                             (_result:any) => {
                              this.tokenStorage.saved=true
                               this.loading = false;
                               Swal.fire({
                                 position: 'center',
                                 icon: 'success',
                                 title: 'déclaration modifiée avec succès',
                                 showConfirmButton: false,
                                 timer: 3000
                               });
                               this.router.navigate(['admin-board']);
                             },
                             (_error) => {
                               this.loading = false;
                               
                                 
                             }
                           ); 
                         },
                         (_error) => {
                           this.loading = false;
                           
                             
                         }
                       ); 
                    
                     }
                     
                     }).catch(() => {
                     Swal.fire('opération non aboutie!');
                     });
                 }
                 
               }
                }
              )
                        }
                        else
                        {
                          this.commun.getcurrenttime().then(
                            async (data:any) => {
                           //@ts-ignore
                           if(deccomptabilite.statutadmin.length>0)
                           {
                             //@ts-ignore
                             if(deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='en cours de supervision')
                             { 
                               Swal.fire({
                                 title: 'Veuillez choisir entre les alternatives suivantes!',
                                 input: 'text',
                                 inputLabel: 'motif(facultatif)',
                                 inputValue: '',
                                 returnInputValueOnDeny: true,
                                 icon: 'info',
                                 showDenyButton: true,
                                 showCancelButton: true,
                                 confirmButtonColor: '#3085d6',
                                 cancelButtonColor: '#555',
                                 confirmButtonText: 'marquer comme supervisé',
                                 cancelButtonText: 'Annuler',
                                 denyButtonText: 'à rectifier',
                                 
                                 }).then((result) => {
                                  
                                 if (result.isConfirmed) {
                                   
                                  deccomptabilite.statutadmin.push
                                   //@ts-ignore
                                   ({
                                     statut:'supervisé',
                                     motif:result.value,
                                     datefin:data,
                                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                   })
                                   this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                     (_result:any) => {
                                      this.tokenStorage.saved=true
                                       this.loading = false;
                                       Swal.fire({
                                         position: 'center',
                                         icon: 'success',
                                         title: 'déclaration modifiée avec succès',
                                         showConfirmButton: false,
                                         timer: 3000
                                       });
                                       this.router.navigate(['admin-board']);
                                     },
                                     (_error) => {
                                       this.loading = false;
                                       
                                         
                                     }
                                   );        
                                 }
                                 else if (result.isDenied)
                                 {
                                   deccomptabilite.statutadmin.push
                                   //@ts-ignore
                                   ({
                                     statut:'à rectifier',
                                     motif:result.value,
                                     datefin:data,
                                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                   })
                                   deccomptabilite.statutcollab.push
                                   //@ts-ignore
                                   ({
                                     statutcoll:'en cours de traitement',
                                     motifcoll:'',
                                     datefin:data,
                                     duree:'',     
                                   })
                                   this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                     (_result:any) => {
                                      this.tokenStorage.saved=true
                                       this.loading = false;
                                       Swal.fire({
                                         position: 'center',
                                         icon: 'success',
                                         title: 'déclaration modifiée avec succès',
                                         showConfirmButton: false,
                                         timer: 3000
                                       });
                                       this.router.navigate(['admin-board']);
                                     },
                                     (_error) => {
                                       this.loading = false;
                                       
                                         
                                     }
                                   ); 
                                 }
                                 
                                 }).catch(() => {
                                 Swal.fire('opération non aboutie!');
                                 });
                             }
                             //@ts-ignore
                             if(deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='en cours de validation')
                             { 
                               await Swal.fire({
                                 title: 'Veuillez choisir entre les alternatives suivantes!',
                                 input: 'text',
                                 inputLabel: 'motif(facultatif)',
                                 inputValue: '',
                                 returnInputValueOnDeny: true,
                                 icon: 'info',
                                 showDenyButton: true,
                                 showCancelButton: true,
                                 confirmButtonColor: '#3085d6',
                                 cancelButtonColor: '#555',
                                 confirmButtonText: 'marquer comme validé',
                                 cancelButtonText: 'Annuler',
                                 denyButtonText: 'à rectifier',
                                 
                               }).then((result) => {
                                 if (result.isConfirmed) 
                                 {
                                   deccomptabilite.statutadmin.push
                                   //@ts-ignore
                                   ({
                                     statut:'validé',
                                     motif:result.value,
                                     datefin:data,
                                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                   })
                                   this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                     (_result:any) => {
                                      this.tokenStorage.saved=true
                                       this.loading = false;
                                       Swal.fire({
                                         position: 'center',
                                         icon: 'success',
                                         title: 'déclaration modifiée avec succès',
                                         showConfirmButton: false,
                                         timer: 3000
                                       });
                                       this.router.navigate(['admin-board']);
                                     },
                                     (_error) => {
                                       this.loading = false;
                                       
                                         
                                     }
                                   );        
                                 }
                                 else if (result.isDenied)
                                 {
                                   deccomptabilite.statutadmin.push
                                   //@ts-ignore
                                   ({
                                     statut:'à rectifier',
                                     motif:result.value,
                                     datefin:data,
                                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                   })
                                   deccomptabilite.statutcollab.push
                                   //@ts-ignore
                                   ({
                                     statutcoll:'en cours de traitement',
                                     motifcoll:'',
                                     datefin:data,
                                     duree:'',     
                                   })
                                   this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                     (_result:any) => {
                                      this.tokenStorage.saved=true
                                       this.loading = false;
                                       Swal.fire({
                                         position: 'center',
                                         icon: 'success',
                                         title: 'déclaration modifiée avec succès',
                                         showConfirmButton: false,
                                         timer: 3000
                                       });
                                       this.router.navigate(['admin-board']);
                                     },
                                     (_error) => {
                                       this.loading = false;
                                       
                                         
                                     }
                                   ); 
                                 }
                                 
                                 }).catch(() => {
                                 Swal.fire('opération non aboutie!');
                                 });
                             }
                             //@ts-ignore
                             if(deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='en cours de clôture')
                             { 
                               Swal.fire({
                                 title: 'Veuillez choisir entre les alternatives suivantes!',
                                 
                                 input: 'text',
                                 inputLabel: 'motif(facultatif)',
                                 inputValue: '',
                                 returnInputValueOnDeny: true,
                                 icon: 'info',
                                 showDenyButton: true,
                                 showCancelButton: true,
                                 confirmButtonColor: '#3085d6',
                                 cancelButtonColor: '#555',
                                 confirmButtonText: 'marquer comme clôturé',
                                 cancelButtonText: 'Annuler',
                                 denyButtonText: 'à rectifier',
                                 
                                 }).then((result) => {
                                 if (result.isConfirmed) {
                                   deccomptabilite.statutadmin.push
                                   //@ts-ignore
                                   ({
                                     statut:'clôturé',
                                     motif:result.value,
                                     datefin:data,
                                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                   })
                                   this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                     (_result:any) => {
                                       this.loading = false;
                                       Swal.fire({
                                         position: 'center',
                                         icon: 'success',
                                         title: 'déclaration modifiée avec succès',
                                         showConfirmButton: false,
                                         timer: 3000
                                       });
                                       const copiedeccomptabilite:Deccomptabilite = deccomptabilite;
                                       let indexcloture=[]
                                       let allstatuts=[]
                                     //  copiedeccomptabilite.statutadmin=[]
                                      // copiedeccomptabilite.statutcollab=[]
                                      // copiedeccomptabilite.statutparam=[]
                                     //  allstatuts=allstatuts.concat(deccomptabilite.statutadmin,deccomptabilite.statutcollab,deccomptabilite.statutparam)
                                   deccomptabilite.statutadmin.forEach(
                                     (e,index)=>
                                     {
                                      //@ts-ignore
                                   e.statut=='clôturé'?indexcloture.push(e.datefin):''
                                     }
                                   )
                                   indexcloture.length==1?
                                   (copiedeccomptabilite.statutadmin=deccomptabilite.statutadmin,
                                   copiedeccomptabilite.statutcollab=deccomptabilite.statutcollab,
                                   copiedeccomptabilite.statutparam=deccomptabilite.statutparam)
                                   :
                                   //@ts-ignore
                                   (copiedeccomptabilite.statutadmin=deccomptabilite.statutadmin.filter(e=>e.datefin>indexcloture[indexcloture.length-2]),
                                   //@ts-ignore
                                   copiedeccomptabilite.statutcollab=deccomptabilite.statutcollab.filter(e=>e.datefin>indexcloture[indexcloture.length-2]),
                                   //@ts-ignore
                                   copiedeccomptabilite.statutparam=deccomptabilite.statutparam.filter(e=>e.datefin>indexcloture[indexcloture.length-2]))
                                       copiedeccomptabilite.source='copie comptabilité'
                                       copiedeccomptabilite._id=null
                                       allstatuts=allstatuts.concat(copiedeccomptabilite.statutadmin,copiedeccomptabilite.statutcollab,copiedeccomptabilite.statutparam)
                                       let totaltime = Math.floor(+allstatuts.reduce((acc,curr)=>{
                                         acc +=  +(curr.duree || 0);
                                         return acc;
                                       },0)/60);
                                       this.prixminuteindiv?
                                       (copiedeccomptabilite.totalhonoraire= Math.round((Math.floor(+totaltime) * +this.prixminuteindiv) * 1000)/1000,
                                       copiedeccomptabilite.totaltime=+totaltime,
                                       copiedeccomptabilite.tauxapplique=this.prixminuteindiv):
                                         this.prixminutespecial?
                                         (copiedeccomptabilite.totalhonoraire= Math.round((Math.floor(+totaltime) * +this.prixminutespecial) * 1000)/1000,
                                       copiedeccomptabilite.totaltime=+totaltime,
                                       copiedeccomptabilite.tauxapplique=this.prixminutespecial):
                                       (copiedeccomptabilite.totalhonoraire= Math.round((Math.floor(+totaltime) * +this.prixminute) * 1000)/1000,
                                       copiedeccomptabilite.totaltime=+totaltime,
                                       copiedeccomptabilite.tauxapplique=this.prixminute)
                                       this.deccompt.createwithoutfile(copiedeccomptabilite).then(
                                        (_result:any) => {
                                          this.loading = false;                    
                                        },
                                        (_error) => {
                                          this.loading = false;                       
                                        }
                                       )  
                                       this.tokenStorage.saved=true
                                       this.router.navigate(['admin-board']);
                                     },
                                     (_error) => {
                                       this.loading = false;
                                       
                                         
                                     }
                                   );     
                                     
                                 }
                                 else if (result.isDenied)
                                 {
                                   deccomptabilite.statutadmin.push
                                   //@ts-ignore
                                   ({
                                     statut:'à rectifier',
                                     motif:result.value,
                                     datefin:data,
                                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                   })
                                   deccomptabilite.statutcollab.push
                                   //@ts-ignore
                                   ({
                                     statutcoll:'en cours de traitement',
                                     motifcoll:'',
                                     datefin:data,
                                     duree:'',     
                                   })
                                   this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                     (_result:any) => {
                                      this.tokenStorage.saved=true
                                       this.loading = false;
                                       Swal.fire({
                                         position: 'center',
                                         icon: 'success',
                                         title: 'déclaration modifiée avec succès',
                                         showConfirmButton: false,
                                         timer: 3000
                                       });
                                       this.router.navigate(['admin-board']);
                                     },
                                     (_error) => {
                                       this.loading = false;
                                       
                                         
                                     }
                                   ); 
                                 }
                                 
                                 }).catch(() => {
                                 Swal.fire('opération non aboutie!');
                                 });
                             }
                             
                           }
                            }
                          )
                        }
          }
        )


      }
    )
  }
  async traite()
  {

    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      async (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
                     //@ts-ignore
                     if(deccomptabilite.statutcollab.length>0)
                     {
                       //@ts-ignore
                       if(deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutcoll!='traité')
                       { 
                         await Swal.fire({
                           title: 'Veuillez choisir entre les alternatives suivantes!',
                           input: 'text',
                           inputLabel: 'motif(facultatif)',
                           inputValue: '',
                           returnInputValueOnDeny: true,
                           icon: 'info',
                           showCancelButton: true,
                           confirmButtonColor: '#3085d6',
                           cancelButtonColor: '#555',
                           confirmButtonText: 'marquer comme traité',
                           cancelButtonText: 'Annuler',
                           
                         }).then((result) => {
                           this.commun.getcurrenttime().then(
                             (data:any) => {
                               if (result.isConfirmed) 
                               {
                                deccomptabilite.statutcollab.push
                                 //@ts-ignore
                                 ({
                                   statutcoll:'traité',
                                   motifcoll:result.value,
                                   datefin:data,
                                   duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                 })
                       
                        
                                 this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                   (_result:any) => {
                                     this.loading = false;
                                     Swal.fire({
                                       position: 'center',
                                       icon: 'success',
                                       title: 'déclaration traité avec succès',
                                       showConfirmButton: false,
                                       timer: 3000
                                     });
                                     decfiscmens[0].statutcollab.push
                                     //@ts-ignore
                                     ({
                                       statutcoll:'traité',
                                       motifcoll:result.value,
                                       datefin:data,
                                       duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                     })
                           
                            
                                     this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                                       (_result:any) => {
                                        this.tokenStorage.saved=true
                                         this.loading = false;
                                         Swal.fire({
                                           position: 'center',
                                           icon: 'success',
                                           title: 'déclaration traité avec succès',
                                           showConfirmButton: false,
                                           timer: 3000
                                         });
                                         this.router.navigate(['collab-board']);
                                       },
                                       (_error) => {
                                         this.loading = false;
                                         
                                           
                                       }
                                     );
                                   },
                                   (_error) => {
                                     this.loading = false;
                                     
                                       
                                   }
                                 );
                              
                               }
                             }
                           )
                     
                        
                         
                       }
                         )
                     }
                       else
                       {
                        this.tokenStorage.saved=true
                         this.router.navigate(['collab-board']);
                       }
                     }
            }
            else
            {
                                  //@ts-ignore
                                  if(deccomptabilite.statutcollab.length>0)
                                  {
                                    //@ts-ignore
                                    if(deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutcoll!='traité')
                                    { 
                                      await Swal.fire({
                                        title: 'Veuillez choisir entre les alternatives suivantes!',
                                        input: 'text',
                                        inputLabel: 'motif(facultatif)',
                                        inputValue: '',
                                        returnInputValueOnDeny: true,
                                        icon: 'info',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#555',
                                        confirmButtonText: 'marquer comme traité',
                                        cancelButtonText: 'Annuler',
                                        
                                      }).then((result) => {
                                        this.commun.getcurrenttime().then(
                                          (data:any) => {
                                            if (result.isConfirmed) 
                                            {
                                             deccomptabilite.statutcollab.push
                                              //@ts-ignore
                                              ({
                                                statutcoll:'traité',
                                                motifcoll:result.value,
                                                datefin:data,
                                                duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                                              })
                                    
                                     
                                              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                                                (_result:any) => {
                                                  this.tokenStorage.saved=true
                                                  this.loading = false;
                                                  Swal.fire({
                                                    position: 'center',
                                                    icon: 'success',
                                                    title: 'déclaration traité avec succès',
                                                    showConfirmButton: false,
                                                    timer: 3000
                                                  });
                                                  this.router.navigate(['collab-board']);
                                                },
                                                (_error) => {
                                                  this.loading = false;
                                                  
                                                    
                                                }
                                              );
                                            }
                                          }
                                        )
                                  
                                     
                                      
                                    }
                                      )
                                  }
                                    else
                                    {
                                      this.tokenStorage.saved=true
                                      this.router.navigate(['collab-board']);
                                    }
                                  }
            }
          }
        )  
      }
    )
  }
  update(e){
    this.selected = e.target.value   
    if(this.selected=='supprimer le dernier statut collaborateur')
    {
      this.deletestatutcollab()
    }
    else if(this.selected=='supprimer le dernier statut paramêtrage')
    {
      this.deletestatutparam()
    }
    else
    {
      this.deletestatutadmin()
    }
  }
  deletestatutcollab()
  {
    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              deccomptabilite.statutcollab.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (_result:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut collaborateur supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  decfiscmens[0].statutcollab.pop()          
                  this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                    (_result:any) => {
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'statut collaborateur supprimé avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      this.reloadPage()
                    },
                    (_error) => {
                      this.loading = false;
                      
                        
                    }
                  );
                },
                (_error) => {
                  this.loading = false;
                  
                    
                }
              );
      
            }
            else
            {
              deccomptabilite.statutcollab.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (_result:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut collaborateur supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  this.reloadPage()
                },
                (_error) => {
                  this.loading = false;
                  
                    
                }
              );
            }
          }
        )
       
      }
    )
  }
  deletestatutparam()
  {
    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              deccomptabilite.statutparam.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (_result:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut parametrage supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  decfiscmens[0].statutparam.pop()          
                  this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                    (_result:any) => {
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'statut parametrage supprimé avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      this.reloadPage()
                    },
                    (_error) => {
                      this.loading = false;
                      
                        
                    }
                  );
                },
                (_error) => {
                  this.loading = false;
                  
                    
                }
              );
            
            }
            else
            {
              deccomptabilite.statutparam.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (_result:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut parametrage supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  this.reloadPage()
                },
                (_error) => {
                  this.loading = false;
                  
                    
                }
              );
            }
          }
        )
       
      }
    )
  }
  deletestatutadmin()
  {
    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              deccomptabilite.statutadmin.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (_result:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut admin supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  decfiscmens[0].statutadmin.pop()          
                  this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                    (_result:any) => {
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'statut admin supprimé avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      this.reloadPage()
                    },
                    (_error) => {
                      this.loading = false;
                      
                        
                    }
                  );
                },
                (_error) => {
                  this.loading = false;
                  
                    
                }
              );
          
            }
            else
            {
              deccomptabilite.statutadmin.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (_result:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut admin supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  this.reloadPage()
                },
                (_error) => {
                  this.loading = false;
                  
                    
                }
              );
            }
          }
        )
       
      }
    )
  }
  affect()
  {
    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              deccomptabilite.affecte =this.optionValue;
              this.filterusers(this.optionValue)
              this.commun.getcurrenttime().then(
                (data:any) => {
                  deccomptabilite.statutadmin.push
                  //@ts-ignore
                  ({
                    statut:'affecté',
                    motif:'',
                    datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),
                    collaborateur:this.prenomcollab+' '+this.nomcollab     
                  })
                  deccomptabilite.statutcollab.push
                  //@ts-ignore
                  ({
                    statutcoll:'en cours de traitement',
                    motifcoll:'',
                    datefin:data,
                    duree:'',     
                  })
                 
                 
                  this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                    (_result:any) => {
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'déclaration affectée avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      decfiscmens[0].affecte =this.optionValue;
              this.filterusers(this.optionValue)
              this.commun.getcurrenttime().then(
                (data:any) => {
                  decfiscmens[0].statutadmin.push
                  //@ts-ignore
                  ({
                    statut:'affecté',
                    motif:'',
                    datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),
                    collaborateur:this.prenomcollab+' '+this.nomcollab     
                  })
                  decfiscmens[0].statutcollab.push
                  //@ts-ignore
                  ({
                    statutcoll:'en cours de traitement',
                    motifcoll:'',
                    datefin:data,
                    duree:'',     
                  })
                 
                 
                  this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                    (_result:any) => {
                      this.tokenStorage.saved=true
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'déclaration affectée avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      this.router.navigate(['admin-board']);
                    },
                    (_error) => {
                      this.loading = false;
                      
                      
                      
                    
                      
                    }
                  );
                }
              )
                    },
                    (_error) => {
                      this.loading = false;
                      
                      
                      
                    
                      
                    }
                  );
                }
              )
              
            }
            else
            {
              deccomptabilite.affecte =this.optionValue;
              this.filterusers(this.optionValue)
              this.commun.getcurrenttime().then(
                (data:any) => {
                  deccomptabilite.statutadmin.push
                  //@ts-ignore
                  ({
                    statut:'affecté',
                    motif:'',
                    datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),
                    collaborateur:this.prenomcollab+' '+this.nomcollab     
                  })
                  deccomptabilite.statutcollab.push
                  //@ts-ignore
                  ({
                    statutcoll:'en cours de traitement',
                    motifcoll:'',
                    datefin:data,
                    duree:'',     
                  })
                 
                 
                  this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                    (_result:any) => {
                      this.tokenStorage.saved=true
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'déclaration affectée avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      this.router.navigate(['admin-board']);
                    },
                    (_error) => {
                      this.loading = false;
                      
                      
                      
                    
                      
                    }
                  );
                }
              )
            }
          }
        )
 
      
      }
    )
  
  }
  onDelete() {
    this.loading = true;


    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              this.loading = false;
              Swal.fire({
                title: 'Veuillez confirmer la suppression!',
                
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmer',
                
              }).then((result) => {
                if (result.value) {
                  this.deccompt.deletedeccomptabiliteById(deccomptabilite._id);
                  this.dec.deletedecfiscmensById(decfiscmens[0]._id)
                  this.tokenStorage.saved=true
                  this.router.navigate(['admin-board']);
                }
    
              }).catch(() => {
                Swal.fire('opération non aboutie!');
              });
            }
            else
            {
              this.loading = false;
              Swal.fire({
                title: 'Veuillez confirmer la suppression!',
                
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmer',
                
              }).then((result) => {
                if (result.value) {
                  this.tokenStorage.saved=true
                  this.deccompt.deletedeccomptabiliteById(deccomptabilite._id);
                  this.router.navigate(['admin-board']);
                }
    
              }).catch(() => {
                Swal.fire('opération non aboutie!');
              });
            }
          }
        )
      }
    )
  }
  reloadPage(): void {
  
    window.location.reload();
    
  }
 
  ngOnDestroy(){
    this.intervalSub?this.intervalSub.unsubscribe():'';
    this.usersSub?this.usersSub.unsubscribe():'';
    this.settingsSub?this.settingsSub.unsubscribe():'';
    this.usedres.getUsedressourcedataById(this.deccomptabilite._id).then(
      (usedres:Usedressourcemodel)=>
            {
              if(usedres)
              {
                if(usedres.connecteduser==this.currentUser._id)
                {
                  this.usedres.deleteusedressourcedataById(this.deccomptabilite._id).then(
                    (usedres:Usedressourcemodel)=>
                          {
                            
                          }
                  )
                }
              }
            }
    )

  }
}