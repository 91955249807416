import { Component,OnInit,OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { devismodel } from '../../models/facture.model';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { FactureproformaService } from '../../services/factureproforma.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { UserService } from '../../services/user.service';
import { User } from 'src/app/models/user.model';
import { ActivatedRoute } from '@angular/router';
import { FactureService } from 'src/app/services/facture.devis';
import { DevisService } from 'src/app/services/devis.service';
import Swal from 'sweetalert2';


/*interface InvoiceItem {
  description: string;
  quantity: number;
  unitPrice: number;
}

interface Contact {
  name: string;
  address: string;
  phone: string;
  email: string;
}

interface Invoice {
  invoiceNumber: string;
  date: string;  // Ajouté pour la date de création
  userId: string;
  currency: string;
  clientInfo: Contact[];
  invoiceDetails: InvoiceItem[],
  terms: string,  
  validityDate:string,
    notes: string
}*/
@Component({
  selector: 'app-facture-proforma',
  templateUrl: './facture-proforma.component.html',
  styleUrls: ['./facture-proforma.component.scss']
})
export class FactureProformaComponent implements OnInit,OnDestroy {
  documentType: string;
  currentlang = "fr";
  Facturepro: devismodel
  Facturepros: devismodel[] = [];
  totalHT: number = 0;
  tva: number = 0;
  tva2: number = 0;
  totalTTC: number = 0;
  selectedFile: File | null = null;
  fileInputInvalid: boolean = false;
  invoiceForm: FormGroup;
currency='DT'
  newinvoicenumber: number;
  newinvoiceref: number;
  newinvoiceprefixe: number;
  userId: any;
  private currentId: number = 1;
  pdfSrc: string | ArrayBuffer | null = null;
  uploadFiles=[]
  errorMessage: string;
  imageSrc: any;
  subscriptions: any=[];
  constructor(private translate: TranslateService,private route: ActivatedRoute,private UserService: UserService,private Auth: TokenStorageService,private fb: FormBuilder,private factproserv: FactureproformaService
    ,private factserv: FactureService,private devserv: DevisService) {
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.uploadFiles=[]
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 512) {
        this.errorMessage = 'File size should be less than 512 KB';
        return;
      }
      this.invoiceForm.patchValue({ image: file });  
      this.invoiceForm.updateValueAndValidity();         
  this.uploadFiles.push(this.invoiceForm.get('image').value);      
  const fileType = file.type;
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (fileType === 'application/pdf') {
          this.pdfSrc = e.target.result;
          this.imageSrc = null;
        } else if (fileType === 'image/png' || fileType === 'image/jpeg') {
          this.imageSrc = e.target.result;
          this.pdfSrc = null;
        } else {
          this.errorMessage = 'Invalid file type. Please select a PDF, PNG, or JPG file.';
        }
      };

      reader.readAsDataURL(file);
    }
  }
  ngOnInit(): void {
    const routeSub =this.route.paramMap.subscribe(params => {
      this.documentType = params.get('documentType');
      console.log(this.documentType);  // Use this.documentType as needed
      this.userId=this.Auth.getUser().userId
      console.log(this.userId)
      this.UserService.getUserById(this.userId).then((user:User) => {
        this.imageSrc = user.ficheUrl5; // Initialize the logo as the user logo
      });
      if(this.documentType=='facture-proforma')
      {
        this.factproserv.getfactureproformauserwithoutsubscription(this.userId).then(
          (res:any)=>{
            console.log(res)
            res.length==0?(this.newinvoicenumber=1,this.newinvoiceref=1,this.newinvoiceprefixe=new Date().getFullYear()):
            (this.newinvoicenumber=res[res.length-1].number+1,this.newinvoiceref=+res[res.length-1].ref+1,this.newinvoiceprefixe=new Date().getFullYear())
          }
        )
      }
      else if(this.documentType=='facture')
      {
        this.factserv.getfactureuserwithoutsubscription(this.userId).then(
          (res:any)=>{
            console.log(res)
            res.length==0?(this.newinvoicenumber=1,this.newinvoiceref=1,this.newinvoiceprefixe=new Date().getFullYear()):
            (this.newinvoicenumber=res[res.length-1].number+1,this.newinvoiceref=+res[res.length-1].ref+1,this.newinvoiceprefixe=new Date().getFullYear())
          }
        )
      }
      else if(this.documentType=='devis')
      {
        this.devserv.getdevisuserwithoutsubscription(this.userId).then(
          (res:any)=>{
            console.log(res)
            res.length==0?(this.newinvoicenumber=1,this.newinvoiceref=1,this.newinvoiceprefixe=new Date().getFullYear()):
            (this.newinvoicenumber=res[res.length-1].number+1,this.newinvoiceref=+res[res.length-1].ref+1,this.newinvoiceprefixe=new Date().getFullYear())
          }
        )
      }
    });
    this.subscriptions.push(routeSub);
    this.invoiceForm = this.fb.group({
      itemsdetails: this.fb.array([
        this.createItem()
      ]),
      clientdetails: this.fb.array([
        this.createdetails()
      ]),
      fournisseurdetails: this.fb.array([
        this.createdetails()
      ]),
      status: ['', Validators.required],
      currency: [{ value: this.currency, disabled: true }, Validators.required],
      terms: [''],
      validityDate: [''],
      notes: [''],
      image:[null]
    });

  }
  get itemsdetails(): FormArray {
    return this.invoiceForm.get('itemsdetails') as FormArray;
  }

  get clientdetails(): FormArray {
    return this.invoiceForm.get('clientdetails') as FormArray;
  }
  get fournisseurdetails(): FormArray {
    return this.invoiceForm.get('fournisseurdetails') as FormArray;
  }
  createItem(): FormGroup {
    const item = this.fb.group({
      id: [{ value: this.currentId, disabled: true }, Validators.required],
      libelle: ['', Validators.required],
      description: ['', Validators.required],
      quantity: [0, Validators.required],
      unitPrice: [0, Validators.required]
    });
    this.currentId++;
    return item;
  }
  createdetails(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  addItem(): void {
    const item = this.fb.group({
      id: [{ value: this.currentId, disabled: true }, Validators.required],
      libelle: ['', Validators.required],
      description: ['', Validators.required],
      quantity: ['', [Validators.required, Validators.min(1)]],
      unitPrice: ['', [Validators.required, Validators.min(0)]],
    });

    this.itemsdetails.push(item);
    this.currentId++;
  }

 /* addClientDetails(): void {
    this.clientdetails.push(
      this.fb.group({
        name: ['', Validators.required],
        address: ['', Validators.required],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      })
    );
  }
  addfournisseurDetails(): void {
    this.fournisseurdetails.push(
      this.fb.group({
        name: ['', Validators.required],
        address: ['', Validators.required],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      })
    );
  }*/
  removeItem(index: number): void {
    this.itemsdetails.removeAt(index);
    this.updateTotal();
  }
  generatePDF() {
    const data = document.getElementById('invoice')!;
  
    // Temporarily hide buttons using CSS class
    data.classList.add('hide-buttons');
    
    html2canvas(data, {
      allowTaint: true,
      useCORS: true
    }).then(canvas => {
      // Remove the CSS class after generating PDF
      data.classList.remove('hide-buttons');
  
      const imgWidth = 190;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
  
      const pdf = new jsPDF('p', 'mm', 'a4');
      let position = 10; // One inch margin
  
      pdf.addImage(canvas, 'PNG', 10, position, imgWidth, imgHeight);
      if(this.documentType=='facture-proforma')
      {
        pdf.save(`facture-proforma/_${this.newinvoicenumber}`);
      }
     else if(this.documentType=='facture')
      {
        pdf.save(`facture/_${this.newinvoicenumber}`);
      }
     else if(this.documentType=='devis')
      {
        pdf.save(`devis/_${this.newinvoicenumber}`);
      }
    }).catch(error => {
      console.error('Error generating PDF:', error);
      // Remove the CSS class in case of an error
      data.classList.remove('hide-buttons');
    });
  }
  
  
  onSubmit() {
    //this.previwing = true;
   const Factureclient:devismodel = new devismodel();
    //let ammounts3=this.otherdsform.get('ammounts3') as FormArray;
    let itemdetails = this.invoiceForm.get('itemsdetails') as FormArray;

    for (let i = 0; i < itemdetails.length; i++)
    {        
const item = itemdetails.getRawValue()[i];
Factureclient.itemsdetails.push({
    id:item.id,
    libelle:item.libelle,
    description: item.description,
    quantity: item.quantity,
    unitPrice: item.unitPrice,
})
} 
Factureclient.clientdetails = this.invoiceForm.getRawValue().clientdetails[0]
Factureclient.fournisseurdetails = this.invoiceForm.getRawValue().fournisseurdetails[0]
Factureclient.status= this.invoiceForm.get('status').value
Factureclient.currency= this.invoiceForm.get('currency').value
Factureclient.terms= this.invoiceForm.get('terms').value 
Factureclient.validityDate= this.invoiceForm.get('validityDate').value
Factureclient.notes= this.invoiceForm.get('notes').value
Factureclient.userId= this.userId
Factureclient.tva= this.tva
Factureclient.logo= this.imageSrc
if(this.documentType=='facture-proforma')
{
  this.factproserv.create(Factureclient,this.uploadFiles).then(
    (data:any) => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document sauvegardé avec succès! vous pouvez toujours le consulter à travers votre tableau de bord',
        showConfirmButton: false,
        timer: 6000 
      });
   //   this.router.navigate(['modify-deccomptabilite/'+data.data._id])
    },
    (error) => {    
    }
  )
}
else if(this.documentType=='facture')
{
  this.factserv.create(Factureclient,this.uploadFiles).then(
    (data:any) => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document sauvegardé avec succès! vous pouvez toujours le consulter à travers votre tableau de bord',
        showConfirmButton: false,
        timer: 6000 
      });
   //   this.router.navigate(['modify-deccomptabilite/'+data.data._id])
    },
    (error) => {    
    }
  )
}
else if(this.documentType=='devis')
{
  this.devserv.create(Factureclient,this.uploadFiles).then(
    (data:any) => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document sauvegardé avec succès! vous pouvez toujours le consulter à travers votre tableau de bord',
        showConfirmButton: false,
        timer: 6000 
      });
   //   this.router.navigate(['modify-deccomptabilite/'+data.data._id])
    },
    (error) => {    
    }
  )
}
        }
  updateTotal(): void {
    this.totalHT = this.itemsdetails.controls.reduce((total, control) => {
      const quantity = control.get('quantity').value;
      const unitPrice = control.get('unitPrice').value;
      return total + (quantity * unitPrice);
    }, 0);

    this.tva2 = this.totalHT * this.tva;
    this.totalTTC = Math.trunc(this.totalHT + this.tva2+1) ;
  }
  switchLanguage(language: string) {
    this.translate.use(language);
    this.currentlang = language;
  }
switchcurrency():void {
  this.invoiceForm.get('currency').setValue(this.currency)
}
}
