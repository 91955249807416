import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { Decfiscmens } from '../models/dec-fisc-mens';
const API_URL_test = 'http://localhost:3002/api/decfiscmens/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/decfiscmens/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class DecfiscmensService {
  dsdecfiscmenssuser: Decfiscmens[] = [];
  dsdecfiscmenss: Decfiscmens[] = []; 
  public dsdecfiscmenss$ = new Subject<Decfiscmens[]>();
  public decfiscmensspasencorevalide$ = new Subject<Decfiscmens[]>();
  public decfiscmenssnonaffecte$ = new Subject<Decfiscmens[]>();
  public decfiscmenssencours$ = new Subject<Decfiscmens[]>();
  public decfiscmenssactif$ = new Subject<Decfiscmens[]>();
  public decfiscmensscloture$ = new Subject<Decfiscmens[]>();
  public decfiscmenssnonpaye$ = new Subject<Decfiscmens[]>();
  public decfiscmensspaye$ = new Subject<Decfiscmens[]>();
  public decfiscmenssmarquepaye$ = new Subject<Decfiscmens[]>();
  decfiscmensspasencorevalide: Decfiscmens[];
  decfiscmensspasencoreaffecte: Decfiscmens[];
  decfiscmenssencours: Decfiscmens[];
  decfiscmenssactif: Decfiscmens[];
  decfiscmensscloture: Decfiscmens[];
  decfiscmenssnonpaye: Decfiscmens[];
  decfiscmensspaye: Decfiscmens[];
  decfiscmenssmarquepaye: Decfiscmens[];

    constructor(private http: HttpClient) { }
    public decfiscmenss: Decfiscmens[] = [
    
    ];
    public decfiscmenss$ = new Subject<Decfiscmens[]>();
 

    getdecfiscmenss() {
        this.http.get(API_URL_cloud).subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
              this.decfiscmenss = decfiscmenss;
              this.emitdecfiscmenss();

            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    getDecfiscmensreqById(id: string) {
        return new Promise((resolve, reject) => {
          

          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
    getdecfiscmens(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud  ,{userId}).subscribe(
            (decfiscmenss: Decfiscmens[]) => {
              if (decfiscmenss) {
                this.decfiscmenss = decfiscmenss;
                this.emitdecfiscmenss();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        });
      }
    getdecfiscmenspasencorevalide() {
        this.http.get(API_URL_cloud+'/filter/'+'nonvalide/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmensspasencorevalide = decfiscmenss;
              this.emitdecfiscmensnonvalide();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdecfiscmenspasencoreaffecte() {
        this.http.get(API_URL_cloud+'/filter/'+'nonaffecte/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmensspasencoreaffecte = decfiscmenss;
              this.emitdecfiscmensnonaffecte();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }  
  getdecfiscmensencours() {
        this.http.get(API_URL_cloud+'/filter/'+'encours/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmenssencours = decfiscmenss;
              this.emitdecfiscmensencours();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdecfiscmensactif() {
        this.http.get(API_URL_cloud+'/filter/'+'actif/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmenssactif = decfiscmenss;
              this.emitdecfiscmensactif();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdecfiscmenscloture() {
        this.http.get(API_URL_cloud+'/filter/'+'cloture/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmensscloture = decfiscmenss;
              this.emitdecfiscmenscloture();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdecfiscmensnonpaye() {
        this.http.get(API_URL_cloud+'/filter/'+'nonpaye/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmenssnonpaye = decfiscmenss;
              this.emitdecfiscmensnonpaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdecfiscmenspaye() {
        this.http.get(API_URL_cloud+'/filter/'+'paye/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmensspaye = decfiscmenss;
              this.emitdecfiscmenspaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdecfiscmensmarquepaye() {
        this.http.get(API_URL_cloud+'/filter/'+'marquepaye/').subscribe(
          (decfiscmenss: Decfiscmens[]) => {
            if (decfiscmenss) {
             this.decfiscmenssmarquepaye = decfiscmenss;
              this.emitdecfiscmensmarquepaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    emitdecfiscmenss() {
        this.decfiscmenss$.next(this.decfiscmenss);
      }
    emitdsdecfiscmenss() {

        this.dsdecfiscmenss$.next(this.dsdecfiscmenss);
      }
    emitdsdecfiscmenssuser() {

        this.dsdecfiscmenss$.next(this.dsdecfiscmenssuser);
      }
      emitdecfiscmensnonvalide() {
        this.decfiscmensspasencorevalide$.next(this.decfiscmensspasencorevalide);
      }
  emitdecfiscmensnonaffecte() {
        this.decfiscmenssnonaffecte$.next(this.decfiscmensspasencoreaffecte);
      }
  emitdecfiscmensencours() {
        this.decfiscmenssencours$.next(this.decfiscmenssencours);
      }
  emitdecfiscmensnonpaye() {
        this.decfiscmenssnonpaye$.next(this.decfiscmenssnonpaye);
      }
  emitdecfiscmenspaye() {
        this.decfiscmensspaye$.next(this.decfiscmensspaye);
      }
  emitdecfiscmensmarquepaye() {
        this.decfiscmenssmarquepaye$.next(this.decfiscmenssmarquepaye);
      }
  emitdecfiscmensactif() {
        this.decfiscmenssactif$.next(this.decfiscmenssactif);
      }
  emitdecfiscmenscloture() {
        this.decfiscmensscloture$.next(this.decfiscmensscloture);
      }   
    geexistenttdecfiscmens(userId: string,annee:string,mois:string,source:string) 
    {
return new Promise((resolve, reject) => {
        this.http.post(API_URL_cloud+'/anneemois/',{userId,annee,mois,source}).subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error); 
          }
        );
      });

      }
      
      create(decfiscmens: Decfiscmens) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud+'createdecfiscmens', decfiscmens).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
    
     
    
      modify(id: string, decfiscmensreq: Decfiscmens, image: File | string) {
        return new Promise((resolve, reject) => {
          let decfiscmensreqData: Decfiscmens | FormData;
          if (typeof image === 'string') {
            
            decfiscmensreqData = decfiscmensreq;
          } else {
            decfiscmensreqData = new FormData();
            decfiscmensreqData.append('decfiscmensreq', JSON.stringify(decfiscmensreq));
            decfiscmensreqData.append('image', image, decfiscmensreq.email);
          }
          this.http.put(API_URL_cloud + id, decfiscmensreqData).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      modifydecfiscmensreqById(id: string, decfiscmens: Decfiscmens) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, decfiscmens).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        }); 
      }
      getdecfiscmensqByfactureuser(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'factures/'+ id).subscribe(
            (decfismcenss:Decfiscmens[]) => {
//              resolve(response);
              if (decfismcenss) {
                this.dsdecfiscmenssuser = decfismcenss;
                this.emitdsdecfiscmenssuser(); 
              }
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getdecfiscmensqByfactureuserwithoutsubscription(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'factures/'+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        }); 
      }
      getdecfiscmensreqByfacture() {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'facture/all/').subscribe(
            (decfiscmenss:Decfiscmens[]) => {
//            resolve(response);
              if (decfiscmenss) {
                this.dsdecfiscmenss = decfiscmenss;
                this.emitdsdecfiscmenss();  
              }
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getdecfiscmensqByfacturewithoutsubscription() {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'facture/all/').subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        }); 
      }
      completedecfiscmensreqById(id: string, decfiscmens: Decfiscmens) {
        return new Promise((resolve, reject) => {
          
            
          
          this.http.put(API_URL_cloud+'modify/'+ id, decfiscmens).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    
      deletedecfiscmensById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }  
      deletedecfiscmenss() {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      calculatetfpreportoncreation(month:string,year:string,array:any[]):any
      {
        //verify report TFP mois précédent
let mois=['01','02','03','04','05','06','07','08','09','10','11','12'] 
let mois1:any
let desiredmois1:any
let verifymois1:any
let verifyannee1:any
let reporttfp:any
let avancetfp:any
let selected = (element) => element ===month;
if(array.length>0)
{
  array=array.filter(e=>!e.source)
  if(month!='01')
  {
    mois1=mois.findIndex(selected)
    desiredmois1=mois[+(mois1-1)]
    array.find(e => verifymois1=(e.mois === desiredmois1&&e.annee === year));
    console.log(verifymois1)
    if(verifymois1)
  { 
  reporttfp=+(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype3.tfpreporter
  avancetfp=+(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype3.montantavance
  /*this.standardtfpform.patchValue({
    avanceammount:avancetfp,
    tfpammountreportmoisprecedent:reporttfp
  })*/
  }
  else{
    reporttfp=avancetfp=0
  }
  }
  else if(month==='01')
  {
    array.find(e => verifymois1=(e.mois === '12'&&+e.annee === +year-1));
    if(verifymois1)
  { 
  reporttfp=+(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype3.tfpreporter
  avancetfp=+(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype3.montantavance
  /*this.standardtfpform.patchValue({
    tfpammountreportmoisprecedent:reporttfp
  })*/
  }
  else{
    reporttfp=avancetfp=0
  }
  }
}
else{
  reporttfp=avancetfp=0
}

return [reporttfp,avancetfp]
      }
      reporttvaoncreation(month:string,year:string,array:any[]):any
      {
//verify report tva mois précédent
let mois=['01','02','03','04','05','06','07','08','09','10','11','12'] 
let mois1:any
let desiredmois1:any
let verifymois1:any
let verifyannee1:any
let reporttva:any
let selected = (element) => element ===month;
if(array.length>0)
{
  array=array.filter(e=>!e.source)
  if(month!='01')
  {
    mois1=mois.findIndex(selected)
    desiredmois1=mois[+(mois1-1)]
    array.find(e => verifymois1=(e.mois === desiredmois1&&e.annee === year));
  
    console.log(verifymois1)
    if(verifymois1)
  { 
    let tvarecuperable=+(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvarecuperableautreachat.achatlocauxtva+ +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvarecuperableautreachat.achatimportetva+ 
    +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvarecuperableequipement.achatlocauxtva+ +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvarecuperableequipement.achatimportetva+ 
    +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvarecuperableimmobilier.achatlocauxtva+
    +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.reporttvamoisprecedent
    let tvacollecte=+(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvacollecter19.tvaammount+ 
    ((array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvacollecter13?+(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvacollecter13.tvaammount:0)+ 
    +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.tvacollecter.tvaammount+ Math.trunc(((+(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.locationhabitationmeuble.htammount+ +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.locationusagecommercial.htammount
    + +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.operationlotissement.htammount+ +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.interetpercue.htammount+ +(array.filter(p => p.mois===desiredmois1&&p.annee===year))[0].impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
     
    tvarecuperable-tvacollecte>=0?reporttva=(tvarecuperable-tvacollecte).toFixed(3):reporttva=0
  console.log(tvarecuperable,tvacollecte)
  /*if(reporttva>0)
  {
    calculatedreport=reporttva
  }*/
  }
  else
  {
    reporttva=0
  }
  }
  else if(month==='01')
  {
    array.find(e => verifymois1=(e.mois === '12'&&+e.annee === +year-1));
    if(verifymois1)
  { 
    let tvarecuperable=+(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvarecuperableautreachat.achatlocauxtva+ +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvarecuperableautreachat.achatimportetva+ 
    +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvarecuperableequipement.achatlocauxtva+ +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvarecuperableequipement.achatimportetva+ 
    +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvarecuperableimmobilier.achatlocauxtva+
    +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.reporttvamoisprecedent
    let tvacollecte=+(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvacollecter19.tvaammount+
    +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvacollecter13?+(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvacollecter13.tvaammount:0+
    +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.tvacollecter.tvaammount+ Math.trunc(((+(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.locationhabitationmeuble.htammount+ +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.locationusagecommercial.htammount
    + +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.operationlotissement.htammount+ +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.interetpercue.htammount+ +(array.filter(p => p.mois==='12'&&+p.annee===+year-1))[0].impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
     
  tvarecuperable-tvacollecte>=0?reporttva=(tvarecuperable-tvacollecte).toFixed(3):reporttva=0
  console.log(tvarecuperable,tvacollecte)
  /*if(reporttva>0)
  {
    calculatedreport=reporttva
  }*/
  }
  else
  {
    reporttva=0
  }
  }
}
else{
  reporttva=0
}
return reporttva
      }
      reporttfptvaonmodify(month:string,year:string,array:any[],object:any):any{
       //verify report tva mois précédent
       let mois=['01','02','03','04','05','06','07','08','09','10','11','12'] 
       let mois1:any
       let desiredmois1:any
       let verifymois1:any
       let verifyannee1:any
       let reporttva:any
       let reporttfp:any
       let avancetfp:any
       let calculatedreport:any
       let tfpapayer:any
       let tfpareporter:any
       let selected = (element) => element ===object.mois;
       if(array.length>0)
       {
        array=array.filter(e=>!e.source)
        if(object.mois!='01')
        {
          mois1=mois.findIndex(selected)
          desiredmois1=mois[+(mois1-1)]
          console.log(array)
          array.find(e => verifymois1=(e.mois === desiredmois1&&e.annee === year));
        
          console.log(verifymois1)
          if(verifymois1)
        { 
          let tvarecuperable=+(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvarecuperableautreachat.achatlocauxtva+ +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvarecuperableautreachat.achatimportetva+ 
          +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvarecuperableequipement.achatlocauxtva+ +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvarecuperableequipement.achatimportetva+ 
          +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvarecuperableimmobilier.achatlocauxtva+
          +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.reporttvamoisprecedent
          let tvacollecte=+(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvacollecter.tvaammount+ 
          +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvacollecter19.tvaammount+ 
          ((array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvacollecter13?+(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.tvacollecter13.tvaammount:0)+
           Math.trunc(((+(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.locationhabitationmeuble.htammount+ +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.locationusagecommercial.htammount
          + +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.operationlotissement.htammount+ +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.interetpercue.htammount+ +(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
           
        reporttva=(tvarecuperable-tvacollecte).toFixed(3)
        reporttfp=+(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype3.tfpreporter
        avancetfp=+(array.filter(p => p.mois===desiredmois1&&p.annee===object.annee))[0].impottype3.montantavance
        calculatedreport=0
        if(reporttva>0&&!object.impottype2.reporttvamoisprecedent)
        {
          calculatedreport=reporttva
        }
        if(object.impottype2.reporttvamoisprecedent)
        {
          calculatedreport=object.impottype2.reporttvamoisprecedent
        }
        if(+object.impottype3.montanttfpmois-reporttfp>0)
          {
              tfpapayer=+object.impottype3.montanttfpmois-reporttfp
          }
        if(+object.impottype3.montanttfpmois-reporttfp<0)
          {
              tfpareporter=reporttfp - +object.impottype3.montanttfpmois
          }
        }
        else{
          reporttfp=avancetfp=calculatedreport=0
         }
        }
        else if(object.mois==='01')
        {
          array.find(e => verifymois1=(e.mois === '12'&&+e.annee === +year-1));
          if(verifymois1)
        {
          let tvarecuperable=+(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvarecuperableautreachat.achatlocauxtva+ +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvarecuperableautreachat.achatimportetva+ 
          +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvarecuperableequipement.achatlocauxtva+ +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvarecuperableequipement.achatimportetva+ 
          +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvarecuperableimmobilier.achatlocauxtva+
          +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.reporttvamoisprecedent
          let tvacollecte=+(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvacollecter.tvaammount+ 
          +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvacollecter19.tvaammount+  
          +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvacollecter13?+(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.tvacollecter13.tvaammount:0+
           Math.trunc(((+(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.locationhabitationmeuble.htammount+ +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.locationusagecommercial.htammount
          + +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.operationlotissement.htammount+ +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.interetpercue.htammount+ +(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
           
        reporttva=(tvarecuperable-tvacollecte).toFixed(3)
        reporttfp=+(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype3.tfpreporter
        avancetfp=+(array.filter(p => p.mois==='12'&&+p.annee===+object.annee-1))[0].impottype3.montantavance
 calculatedreport=0
       /* this.standardtfpform.patchValue({
         avanceammount:avancetfp,
          tfpammountreportmoisprecedent:reporttfp
        })*/
        if(reporttva>0&&!object.impottype2.reporttvamoisprecedent)
        {
          calculatedreport=reporttva
        }
        if(object.impottype2.reporttvamoisprecedent)
        {
          calculatedreport=object.impottype2.reporttvamoisprecedent
        }
        }
        else{
          reporttfp=avancetfp=calculatedreport=0
         }
        }
       }
       else{
        reporttfp=avancetfp=calculatedreport=0
       }
 return[reporttfp,avancetfp,calculatedreport]
      //     console.log(this.tvacollecte2,this.tvacollecte3,this.tvacollecte4,this.tvacollecte5,this.tvacollecte6)
            /*   this.option71Value=+(this.tvacollecte2+this.tvacollecte3+this.tvacollecte4+this.tvacollecte5+this.tvacollecte6)
                 this.option72Value=+(this.option71Value *0.19)*/
      }
    }
